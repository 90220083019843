import React from 'react';
import { DesktopActionButton, ActionButton, HeaderButton } from './GlobalStyles';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector, useDispatch } from 'react-redux';
import { buttonDisabled } from './actions';
import { companyName, shopWebsite } from './ShopData';

//Breva = pk_live_0CmB77ZADiPJYS5fa9FZDvDy00vFCge9CO
//Breva Test = pk_test_NBQ0dDDqbIwT5Zq3IDfqtHE500YFXkgk3m
//Griso = pk_live_qJcbfNJGl4TfjuDdbFa7R2A200WXNhITCi
//Griso Test = pk_test_m3LIW6GzVeSaNRUqXwBRnCAs00aIkjOqmT


const stripePromise = loadStripe(companyName === "Griso srl" ? 'pk_live_qJcbfNJGl4TfjuDdbFa7R2A200WXNhITCi' : 'pk_live_0CmB77ZADiPJYS5fa9FZDvDy00vFCge9CO');

function StripeCheckout({ page, customer }) {

    const currentWidth = useSelector(state => state.width);
    const currentPrice = useSelector(state => state.price);
    const currentEmail = useSelector(state => state.emailAction);
    const currentRecipientEmail = useSelector(state => state.recipientEmail);
    const currentRecipientName = useSelector(state => state.recipientName);
    const currentGiftMessage = useSelector(state => state.giftMessage);
    const currentGiftCardDate = useSelector(state => state.giftCardDate);
    const currentButtonDisabled = useSelector(state => state.buttonDisabled);
    const currentShopName = useSelector(state => state.shopName);
    const currentBookShopEmail = useSelector(state => state.bookShopEmail);
    const currentBookShopAddress = useSelector(state => state.bookShopAddress);
    const currentBookShopPhoneNumber = useSelector(state => state.bookShopPhoneNumber);
    const currentDateChanged = useSelector(state => state.dateChanged);
    const dispatch = useDispatch();

    function makeId(length) {
        dispatch(buttonDisabled(true));
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return stripe(result);
    }

    function stripe(result) {
        firebase.firestore().collection("Stripe").doc(result).set({
            price: page === 'gift' ? 
                    currentPrice * 100 
                    : 
                    customer.delivery === false ? 
                    customer.price * 100 
                    : 
                    customer.deliveryFee !== undefined ? 
                    (customer.deliveryFee + customer.price) * 100 
                    : 
                    customer.price < 19 ? ( customer.price + 1.5 ) * 100 
                    : 
                    customer.price * 100,
            image: ["https://firebasestorage.googleapis.com/v0/b/mondadori-c11d4.appspot.com/o/Logo%2FMondadori_New_Logo.jpg?alt=media&token=befe1556-c753-4246-b953-50a97b668011"],
            name: "Libreria Mondadori Crema",
            code: result,
            brevaTrue: companyName === "Breva srl" ? true : false,
            shopName: currentShopName,
            shopWebsite: shopWebsite
          })
      
          firebase.firestore().collection("Stripe").doc(result)
          .onSnapshot(snapshot => {
            if (!snapshot.exists) {
              console.log('No such document!');
            }
            else {
              const data = snapshot.data();
              if ( data.id !== undefined ) {
                const id = data.id;
                getSession(id, result);
              }
            }
          })
    }

    async function getSession (id, document) {
        // Get Stripe.js instance
        firebase.firestore().collection("Stripe").doc(document).delete();

            if ( page === 'gift' ) {
              firebase.firestore().collection("Gift Card").doc(document).set({
                checkoutId: id,
                price: currentPrice,
                email: currentEmail,
                recipientEmail: currentRecipientEmail,
                recipientName: currentRecipientName,
                giftMessage: currentGiftMessage,
                giftCardDate: currentGiftCardDate,
                code: document,
                shopName: currentShopName,
                companyName: companyName,
                bookShopAddress: currentBookShopAddress,
                bookShopEmail: currentBookShopEmail,
                bookShopPhoneNumber: currentBookShopPhoneNumber,
                currentDateChanged: currentDateChanged
              })
              .then(async () => {
                const stripe = await stripePromise;
                dispatch(buttonDisabled(false));
                const result = await stripe.redirectToCheckout({
                  sessionId: id,
                })
    
                if (result.error) {
                  alert(`Error...${result.error.message}`);
                  // If `redirectToCheckout` fails due to a browser or network
                  // error, display the localized error message to your customer
                  // using `result.error.message`.
                }
              })
            }
            else {
              firebase.firestore().collection("Book Purchase").doc(document).set({
                checkoutId: id,
                price: customer.price,
                email: customer.email,
                code: customer.code,
                shopName: currentShopName
              })
              .then(async () => {
                const stripe = await stripePromise;
                dispatch(buttonDisabled(false));
                const result = await stripe.redirectToCheckout({
                  sessionId: id,
                })
    
                if (result.error) {
                  alert(`Error...${result.error.message}`);
                  // If `redirectToCheckout` fails due to a browser or network
                  // error, display the localized error message to your customer
                  // using `result.error.message`.
                }
              })
          }
    }

    return (
        currentWidth < 900 ?
        <ActionButton
          disabled={
                    currentButtonDisabled === true ?
                    true
                    :
                    page === 'gift' && currentEmail === "" ? 
                    true 
                    :
                    page === 'gift' && currentPrice === 0 ?
                    true
                    :
                    page !== 'gift' && customer.price === 0 && customer.deliveryFee !== 0 ?
                    false
                    :
                    page !== 'gift' && customer.price === 0 ?
                    true
                    :
                    false
                  }
          onClick={() => makeId(15)}
        >
            Paga con Carta
        </ActionButton>
        :
        page === 'gift' ?
        <DesktopActionButton
          disabled={
                    currentButtonDisabled === true ?
                    true
                    :
                    page === 'gift' && currentEmail === "" ? 
                    true 
                    :
                    page === 'gift' && currentPrice === 0 ?
                    true
                    :
                    page !== 'gift' && customer.price === 0 ?
                    true
                    :
                    false
                  }
          onClick={() => page === 'gift' ? makeId(8) : makeId(15)}
        >
            Paga con Carta
        </DesktopActionButton>
        :
        <HeaderButton
          disabled={
                    currentButtonDisabled === true ?
                    true
                    :
                    page === 'gift' && currentEmail === "" ? 
                    true 
                    :
                    page === 'gift' && currentPrice === 0 ?
                    true
                    :
                    page !== 'gift' && customer.price === 0 && customer.deliveryFee !== 0 ?
                    false
                    :
                    page !== 'gift' && customer.price === 0 ?
                    true
                    :
                    false
                  }
          onClick={() => page === 'gift' ? makeId(8) : makeId(15)}
        >
            Paga con Carta
        </HeaderButton>
    )
}

export default StripeCheckout;