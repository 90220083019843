import React from 'react';
import { fontFamily, colour, ModalButton, ModalButtonMobile } from './GlobalStyles';
import { cashModal, buttonDisabled, cashPayment } from './actions';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import './CashModal.css';
import { useHistory } from 'react-router-dom';
import * as firebase from 'firebase/app';
import '@firebase/firestore';

function CashModal({ customer }) {

    const currentCashModal = useSelector(state => state.cashModal);
    const currentWidth = useSelector(state => state.width);
    const currentShopName = useSelector(state => state.shopName);
    const currentButtonDisabled = useSelector(state => state.buttonDisabled);
    const dispatch = useDispatch();
    let history = useHistory();

    function makeId(length) {
        dispatch(buttonDisabled(true));
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return payCash(result);
    }


    function payCash(result) {
        const db = firebase.firestore()

        const customerDataRef = db.collection("Test Customer Data").doc(currentShopName);

        db.collection("Customer Orders").doc(customer.code).update({
            payment: true,
            cash: true
        })

        db.runTransaction((transaction) => {
            return transaction.get(customerDataRef).then((doc) => {
                if (!doc.exists) {
                    return
                }
                else {
                const data = doc.data();
                const customerData = data.customerData;
                const dataIndex = customerData.findIndex(e => e.code === customer.code);
                customerData[dataIndex].payment = true;
                customerData[dataIndex].awaitingPayment = false;
                customerData[dataIndex].awaitingCompletion = true;
                customerData[dataIndex].cash = true;

                transaction.update(customerDataRef, 
                    { 
                        customerData
                    });
                    return customerData;
            }
            })
        })
        .then(() => {
            dispatch(cashPayment(true));
            history.push('../pagamentoCompleto');
            dispatch(buttonDisabled(false));
        })
    }

    const styles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: 0,
            zIndex: 10,
            position: 'absolute',
            backgroundColor: '#FFFFFF'
        },
        headerText: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontSize: '1.375rem',
            fontWeight: 'bold',
            textAlign: 'center'
        },
        description: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontSize: '1rem',
            fontWeight: 'normal',
            textAlign: 'center'
        }
    }

    return (
        <Modal
            isOpen={currentCashModal}
            style={styles}
        >
            <div className={currentWidth < 900 ? "mobileCashModal" : "cashModal"}>
                <div className={currentWidth < 900 ? "mobileExitContainer" : "exitContainer"}>
                    <div />
                    <img 
                        src={require('./exit.svg')}
                        className="exitSvg"
                        alt="Chiudi"
                        onClick={() => dispatch(cashModal(false))}
                    />
                </div>
                <div className={currentWidth < 900 ? "mobileModalWidth" : "modalWidth"}>
                    <h1 style={styles.headerText}>
                        Sei sicuro di voler pagare in contanti?
                    </h1>
                    <div style={{ height: '5vh' }} />
                    <p style={styles.description}>
                        {
                            customer.delivery === true ?
                            "Il pagamento sarà effettuato nel momento in cui i tuoi volumi verranno consegnati"
                            :
                            "Il pagamento sarà effettuato nel momento in cui ritiri i tuoi volumi dalla libreria"
                        }
                    </p>
                    <div style={{ height: '5vh' }} />
                    {
                        currentWidth < 900 ?
                        <ModalButtonMobile
                            disabled={currentButtonDisabled}
                            onClick={() => makeId(15)}
                        >
                        Conferma
                            
                        </ModalButtonMobile>
                        :
                        <ModalButton
                            disabled={currentButtonDisabled}
                            onClick={() => makeId(15)}
                        >
                            Conferma
                        </ModalButton>
                    }
                </div>
            </div>
        </Modal>
    )
}

export default CashModal;