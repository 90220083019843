import React from 'react';
import { fontFamily, colour, Input, DesktopInput, ActionButton, DesktopActionButton } from './GlobalStyles';
import './BookPage.css';
import { isMobile } from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';
import { phoneAction, emailAction, doorbellAction, nameAction, surnameAction, addressAction } from './actions';

const BookPage = React.forwardRef((props, ref) => {

    const currentBookPage = useSelector(state => state.bookPage);
    const currentName = useSelector(state => state.nameAction);
    const currentSurname = useSelector(state => state.surnameAction);
    const currentEmail = useSelector(state => state.emailAction);
    const currentPhone = useSelector(state => state.phoneAction);
    const currentAddress = useSelector(state => state.addressAction);
    const currentDoorbell = useSelector(state => state.doorbellAction);
    const currentButtonDisabled = useSelector(state => state.buttonDisabled);
    const currentDelivery = useSelector(state => state.delivery);
    const dispatch = useDispatch();

    const styles = {
        headerText: {
            fontFamily: fontFamily, 
            color: colour.dark,
            fontWeight: 'normal',
            fontSize: '1.375em',
            textAlign: 'center'
        }
    }

    return (
        <div className="bookPageOuterMostContainer">
            <div className="bookPageContainer">
                <h1 style={styles.headerText}>
                    {
                        currentBookPage === 6 ?
                        "Inserisci il tuo nome"
                        :
                        currentBookPage === 8 ?
                        "Inserisci il tuo cognome"
                        :
                        currentBookPage === 10 ?
                        "Inserisci la tua mail"
                        :
                        currentBookPage === 12 ?
                        "Inserisci il tuo numero di telefono"
                        :
                        currentBookPage === 14 ?
                        "Inserisci il tuo indirizzo"
                        :
                        "Inserisci il tuo citofono"
                    }
                </h1>
                <div style={{ height: '5vh' }} />
                {
                    isMobile === true ?
                        <div className="centre">
                            <Input
                                value={
                                        currentBookPage === 6 ? currentName 
                                        :
                                        currentBookPage === 8 ? currentSurname
                                        :
                                        currentBookPage === 10 ? currentEmail
                                        :
                                        currentBookPage === 12 ? currentPhone
                                        :
                                        currentBookPage === 14 ? currentAddress
                                        :
                                        currentBookPage === 16 ? currentDoorbell
                                        :
                                        ""
                                    }
                                id="outlined"
                                label={
                                    currentBookPage === 6 ?
                                    "Nome"
                                    :
                                    currentBookPage === 8 ?
                                    "Cognome"
                                    :
                                    currentBookPage === 10 ?
                                    "Email"
                                    :
                                    currentBookPage === 12 ?
                                    "Telefono"
                                    :
                                    currentBookPage === 14 ?
                                    "Indirizzo"
                                    :
                                    currentBookPage === 16 ?
                                    "Citofono"
                                    :
                                    ""
                                }
                                inputProps={{
                                    autoCapitalize: 'none',
                                }}
                                variant="outlined"
                                onChange={(text) => 
                                                    currentBookPage === 6 ?
                                                    dispatch(nameAction(text.target.value))
                                                    :
                                                    currentBookPage === 8 ?
                                                    dispatch(surnameAction(text.target.value))
                                                    :
                                                    currentBookPage === 10 ?
                                                    dispatch(emailAction(text.target.value))
                                                    :
                                                    currentBookPage === 12 ?
                                                    dispatch(phoneAction(text.target.value))
                                                    :
                                                    currentBookPage === 14 ?
                                                    dispatch(addressAction(text.target.value))
                                                    :
                                                    currentBookPage === 16 ?
                                                    dispatch(doorbellAction(text.target.value))
                                                    :
                                                    null
                                                }
                            />
                            {
                                currentDelivery === true ?
                                <ActionButton
                                    ref={ref} {...props}
                                    disabled={
                                            currentBookPage === 4 && currentName === "" ?
                                                true
                                                :
                                                currentBookPage === 6 && currentSurname === "" ?
                                                true
                                                :
                                                currentBookPage === 8 && currentEmail === "" ?
                                                true
                                                :
                                                currentBookPage === 10 && currentPhone === "" ?
                                                true
                                                :
                                                currentBookPage === 12 && currentAddress === "" ?
                                                true
                                                :
                                                currentBookPage === 14 && currentDoorbell === "" ?
                                                true
                                                :
                                                currentButtonDisabled === true ? 
                                                true
                                                :
                                                false
                                    }
                                >
                                    Continua
                                </ActionButton>
                                :
                                currentDelivery === false && currentBookPage === 12 ?
                                <ActionButton
                                    onClick={() => props.flip()}
                                    disabled={
                                            currentBookPage === 4 && currentName === "" ?
                                                true
                                                :
                                                currentBookPage === 6 && currentSurname === "" ?
                                                true
                                                :
                                                currentBookPage === 8 && currentEmail === "" ?
                                                true
                                                :
                                                currentBookPage === 10 && currentPhone === "" ?
                                                true
                                                :
                                                currentBookPage === 12 && currentAddress === "" ?
                                                true
                                                :
                                                currentBookPage === 14 && currentDoorbell === "" ?
                                                true
                                                :
                                                currentButtonDisabled === true ? 
                                                true
                                                :
                                                false
                                    }
                                >
                                    Continua
                                </ActionButton>
                                :
                                <ActionButton
                                    ref={ref} {...props}
                                    disabled={
                                            currentBookPage === 4 && currentName === "" ?
                                                true
                                                :
                                                currentBookPage === 6 && currentSurname === "" ?
                                                true
                                                :
                                                currentBookPage === 8 && currentEmail === "" ?
                                                true
                                                :
                                                currentBookPage === 10 && currentPhone === "" ?
                                                true
                                                :
                                                currentBookPage === 12 && currentAddress === "" ?
                                                true
                                                :
                                                currentBookPage === 14 && currentDoorbell === "" ?
                                                true
                                                :
                                                currentButtonDisabled === true ? 
                                                true
                                                :
                                                false
                                    }
                                >
                                    Continua
                                </ActionButton>
                            }
                        </div>
                        :
                        <div className="centre">
                            <DesktopInput
                                value={
                                    currentBookPage === 6 ? currentName 
                                    :
                                    currentBookPage === 8 ? currentSurname
                                    :
                                    currentBookPage === 10 ? currentEmail
                                    :
                                    currentBookPage === 12 ? currentPhone
                                    :
                                    currentBookPage === 14 ? currentAddress
                                    :
                                    currentBookPage === 16 ? currentDoorbell
                                    :
                                    ""
                                }
                                id="outlined"
                                label={
                                        currentBookPage === 6 ?
                                        "Nome"
                                        :
                                        currentBookPage === 8 ?
                                        "Cognome"
                                        :
                                        currentBookPage === 10 ?
                                        "Email"
                                        :
                                        currentBookPage === 12 ?
                                        "Telefono"
                                        :
                                        currentBookPage === 14 ?
                                        "Indirizzo"
                                        :
                                        currentBookPage === 16 ?
                                        "Citofono"
                                        :
                                        ""
                                    }
                                inputProps={{
                                    autoCapitalize: 'none',
                                }}
                                variant="outlined"
                                ref={ref} 
                                onChange={(text) => 
                                                    currentBookPage === 6 ?
                                                    dispatch(nameAction(text.target.value))
                                                    :
                                                    currentBookPage === 8 ?
                                                    dispatch(surnameAction(text.target.value))
                                                    :
                                                    currentBookPage === 10 ?
                                                    dispatch(emailAction(text.target.value))
                                                    :
                                                    currentBookPage === 12 ?
                                                    dispatch(phoneAction(text.target.value))
                                                    :
                                                    currentBookPage === 14 ?
                                                    dispatch(addressAction(text.target.value))
                                                    :
                                                    currentBookPage === 16 ?
                                                    dispatch(doorbellAction(text.target.value))
                                                    :
                                                    null
                                                }
                            />
                            {
                                currentDelivery === true ?
                                <DesktopActionButton
                                    ref={ref} {...props}
                                    disabled={
                                                currentBookPage === 6 && currentName === "" ?
                                                true
                                                :
                                                currentBookPage === 8 && currentSurname === "" ?
                                                true
                                                :
                                                currentBookPage === 10 && currentEmail === "" ?
                                                true
                                                :
                                                currentBookPage === 12 && currentPhone === "" ?
                                                true
                                                :
                                                currentBookPage === 14 && currentAddress === "" ?
                                                true
                                                :
                                                currentBookPage === 16 && currentDoorbell === "" ?
                                                true
                                                :
                                                currentButtonDisabled === true ? 
                                                true
                                                :
                                                false
                                            }
                                >
                                    Continua
                                </DesktopActionButton>
                                :
                                currentDelivery === false && currentBookPage === 12 ?
                                <DesktopActionButton
                                    onClick={() => props.flip()}
                                    ref={ref}
                                    disabled={
                                                currentBookPage === 6 && currentName === "" ?
                                                true
                                                :
                                                currentBookPage === 8 && currentSurname === "" ?
                                                true
                                                :
                                                currentBookPage === 10 && currentEmail === "" ?
                                                true
                                                :
                                                currentBookPage === 12 && currentPhone === "" ?
                                                true
                                                :
                                                currentBookPage === 14 && currentAddress === "" ?
                                                true
                                                :
                                                currentBookPage === 16 && currentDoorbell === "" ?
                                                true
                                                :
                                                currentButtonDisabled === true ? 
                                                true
                                                :
                                                false
                                            }
                                >
                                    Continua
                                </DesktopActionButton>
                                :
                                <DesktopActionButton
                                    ref={ref} {...props}
                                    disabled={
                                                currentBookPage === 6 && currentName === "" ?
                                                true
                                                :
                                                currentBookPage === 8 && currentSurname === "" ?
                                                true
                                                :
                                                currentBookPage === 10 && currentEmail === "" ?
                                                true
                                                :
                                                currentBookPage === 12 && currentPhone === "" ?
                                                true
                                                :
                                                currentBookPage === 14 && currentAddress === "" ?
                                                true
                                                :
                                                currentBookPage === 16 && currentDoorbell === "" ?
                                                true
                                                :
                                                currentButtonDisabled === true ? 
                                                true
                                                :
                                                false
                                            }
                                >
                                    Continua
                                </DesktopActionButton>
                            }
                        </div>
                }    
            </div>
        </div>
    )
})

export default BookPage;