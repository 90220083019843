import React from 'react';
import { recipientEmail, recipientName, giftMessage } from './actions';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Input, DesktopInput, colour, fontFamily, MultilineInput, DesktopMultilineInput, ActionButton, DesktopActionButton } from './GlobalStyles';

const GiftCardInfo = React.forwardRef((props, ref) => {

    const styles = {
        subheader: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontSize: '1.125em',
            fontWeight: 'bold'
        }
    }

    const currentWidth = useSelector(state => state.width);
    const currentRecipientEmail = useSelector(state => state.recipientEmail);
    const currentRecipientName = useSelector(state => state.recipientName);
    const currentGiftMessage = useSelector(state => state.giftMessage);
    const dispatch = useDispatch();

    function enter() {
        const newText = currentGiftMessage + '\n';
        dispatch(giftMessage(newText));
    }

    return (
        <div className={isMobile === true ? "mobileGiftCardInfoOuterContainer" : "giftCardInfoOuterContainer"}>
            <div className="giftCardInfoInnerContainer">
                <div>
                    <div className="subheader">
                        <h2 style={styles.subheader}>
                            Inserisci la mail del{' '}
                            <span style={{ fontStyle: 'italic', fontWeight: 'normal' }}>
                                destinatario
                            </span>
                        </h2>
                    </div>
                    <div>
                        {
                            currentWidth < 900 ?
                            <Input 
                                value={currentRecipientEmail}
                                id="outlined"
                                label="La mail del destinatario"
                                inputProps={{
                                    autoCapitalize: 'none',
                                }}
                                variant="outlined"
                                onChange={(text) => dispatch(recipientEmail(text.target.value))}
                            />
                            :
                            <DesktopInput 
                                value={currentRecipientEmail}
                                id="outlined"
                                label="La mail del destinatario"
                                inputProps={{
                                    autoCapitalize: 'none',
                                }}
                                variant="outlined"
                                onChange={(text) => dispatch(recipientEmail(text.target.value))}
                            />
                        }
                    </div>
                    <div className="subheader">
                        <h2 style={styles.subheader}>
                            Inserisci il nome del{' '}
                            <span style={{ fontStyle: 'italic', fontWeight: 'normal' }}>
                                destinatario
                            </span>
                        </h2>
                    </div>
                    <div>
                        {
                            currentWidth < 900 ?
                            <Input 
                                value={currentRecipientName}
                                id="outlined"
                                label="Il nome del destinatario"
                                inputProps={{
                                    autoCapitalize: 'none',
                                }}
                                variant="outlined"
                                onChange={(text) => dispatch(recipientName(text.target.value))}
                            />
                            :
                            <DesktopInput 
                                value={currentRecipientName}
                                id="outlined"
                                label="Il nome del destinatario"
                                inputProps={{
                                    autoCapitalize: 'none',
                                }}
                                variant="outlined"
                                onChange={(text) => dispatch(recipientName(text.target.value))}
                            />
                        }
                    </div>
                    <div className="subheader">
                        <h2 style={styles.subheader}>
                            Inserisci un messaggio
                        </h2>
                    </div>
                    <div>
                        {
                            currentWidth < 900 ?
                            <MultilineInput 
                                value={currentGiftMessage}
                                id="outlined"
                                label="Inserisci un messaggio per il destinatario"
                                rows={isMobile === true ? "6" : "11"}
                                inputProps={{
                                    autoCapitalize: 'none',
                                    maxLength: 180
                                }}
                                variant="outlined"
                                onChange={(text) => dispatch(giftMessage(text.target.value))}
                                multiline
                            />
                            :
                            <DesktopMultilineInput 
                                value={currentGiftMessage}
                                id="outlined"
                                label="Inserisci un messaggio per il destinatario"
                                rows="7"
                                inputProps={{
                                    autoCapitalize: 'none',
                                    maxLength: 180
                                }}
                                variant="outlined"
                                onKeyDown={(e) => e.key === 'Enter' ? enter() : null}
                                onChange={(text) => dispatch(giftMessage(text.target.value))}
                                multiline
                            />
                        }
                    </div>
                    <div style={{ height: '2vh' }} />
                    <div>
                        {
                            currentWidth < 900 ?
                            <ActionButton
                                disabled={
                                            currentRecipientName === "" ?
                                            true
                                            :
                                            currentRecipientEmail === "" ?
                                            true
                                            :
                                            false
                                        }
                                onClick={() => props.onClick()}
                            >
                                Continua
                            </ActionButton>
                            :
                            <DesktopActionButton
                                disabled={
                                            currentRecipientName === "" ?
                                            true
                                            :
                                            currentRecipientEmail === "" ?
                                            true
                                            :
                                            false
                                        }
                                onClick={() => props.onClick()}
                            >
                                Continua
                            </DesktopActionButton>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
})

export default GiftCardInfo;