import React from 'react';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import './InternalSiteModal.css';
import { fontFamily, colour, ModalButton, ModalDeleteButton } from './GlobalStyles';
import { deleteModalItem, modalIndex, modalOpen, internalSiteData } from './actions';
import * as firebase from 'firebase/app';
import '@firebase/firestore';

function InternalSiteModal() {

    const currentModalOpen = useSelector(state => state.modalOpen);
    const currentModalIndex = useSelector(state => state.modalIndex);
    const currentDeleteModalItem = useSelector(state => state.deleteModalItem);
    const currentShopName = useSelector(state => state.shopName);
    const currentInternalSiteData = useSelector(state => state.internalSiteData);
    const currentDelivery = useSelector(state => state.internalSiteDelivery);
    const dispatch = useDispatch();

    function deleteItem() {
        const db = firebase.firestore();
        const customerDataRef = db.collection("Test Customer Data").doc(currentShopName);
    
        db.runTransaction((transaction) => {
            return transaction.get(customerDataRef).then((doc) => {
                if (!doc.exists) {
                    return
                }
                else {
                    const data = doc.data();
                    const customerData = data.customerData;
                    const dataIndex = customerData.findIndex(e => e.code === currentDeleteModalItem.code);
                    customerData.splice(dataIndex, 1);

                    const deleteClone = currentInternalSiteData.slice();
                    const memoryIndex = deleteClone.findIndex(e => e.code === currentDeleteModalItem.code);
                    deleteClone.splice(memoryIndex, 1);
                    dispatch(internalSiteData(deleteClone));

                    transaction.update(customerDataRef, 
                        { 
                            customerData
                        });
                        return customerData;
                }
            })
        })
        .then(() => {
            console.log("Promise compiled with success");

            firebase.firestore().collection("Deleted Entries").doc(currentDeleteModalItem.code).set({
                currentDeleteModalItem: currentDeleteModalItem,
                time: new Date(),
                shopName: currentShopName
            })
            .then(() => {
                console.log("Deleted entry logged");
                dispatch(deleteModalItem(""));
                dispatch(modalOpen(false));
                dispatch(modalIndex(0));
            })
            .catch((err) => {
                console.log("Error with deleted entry =", err);
            })
        })
        .catch((err) => {
            console.log("Error =", err);
        })
    }
    
    const styles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: 0
        },
        headerText: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontWeight: 'bold',
            fontSize: 30,
            textAlign: 'center'
        },
        description: {
            fontFamily: fontFamily,
            color: colour.med,
            fontWeight: 'normal',
            fontSize: '1.375em',
            textAlign: 'center',
            marginBottom: '0em'
        },
        italic: {
            fontFamily: fontFamily,
            color: colour.med,
            fontWeight: 'normal',
            fontSize: '1.375em',
            fontStyle: 'italic',
            textAlign: 'center',
            marginTop: '0.2em'
        }
    }

    return (
        <Modal
            isOpen={currentModalOpen}
            style={styles}
        >
            <div className="modal">
                <div className="exitContainer">
                    <div />
                    <img 
                        src={require('./exit.svg')}
                        className="exitSvg"
                        alt="Chiudi"
                        onClick={() => dispatch(modalOpen(false))}
                    />
                </div>
                <div className="modalWidth">
                    <h1 style={styles.headerText}>
                        {
                            currentModalIndex === 0 ?
                            "Richiesta di Pagamento Inviata"
                            :
                            currentModalIndex === 1 ?
                            "In Lavorazione"
                            :
                            currentModalIndex === 2 ?
                            "Pronto da spedire"
                            :
                            currentModalIndex === 3 ?
                            "Ordine Completo"
                            :
                            currentModalIndex === 999 ?
                            "Sei sicuro di voler cancellare"
                            :
                            currentModalIndex === 25 ?
                            "Pagamento 18App Confermato"
                            :
                            currentModalIndex === 35 ?
                            "Pagamento Carta Del Docente Confermato"
                            :
                            null
                        }
                    </h1>
                    <p style={styles.description}>
                        {
                            currentModalIndex === 25 ?
                            ""
                            :
                            currentModalIndex === 35 ?
                            ""
                            :
                            currentModalIndex === 999 ?
                            currentDeleteModalItem.name + ' ' + currentDeleteModalItem.surname
                            :
                            currentModalIndex === 3 ?
                            "Questo ordine è stato completato e sarà spostato nel archivio."
                            :
                            "Questo ordine è stato spostato a"
                        }
                    </p>
                    <p style={styles.italic}>
                        {
                            currentModalIndex === 25 ?
                            ""
                            :
                            currentModalIndex === 35 ?
                            ""
                            :
                            currentModalIndex === 0 ?
                            "Ordine in Attesa di Pagamento"
                            :
                            currentModalIndex === 1 ?
                            "In Lavorazione"
                            :
                            currentModalIndex === 2 && currentDelivery === true ?
                            "Ordini Completi da Spedire"
                            :
                            currentModalIndex === 2 && currentDelivery === false ?
                            "Ordini Completi da Ritirare"
                            :
                            ""
                        }
                    </p>
                    <div style={{ height: '3vh' }} />
                    {
                        currentModalIndex === 999 ?
                        <ModalDeleteButton
                            onClick={() => deleteItem()}
                        >
                            Cancella
                        </ModalDeleteButton>
                        :
                        <ModalButton
                            onClick={() => dispatch(modalOpen(false))}
                        >
                            OK
                        </ModalButton>
                    }
                </div>
            </div>
        </Modal>
    )
}

export default InternalSiteModal;