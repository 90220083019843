import React from 'react';
import { fontFamily, colour, Input, VoucherInput } from './GlobalStyles';
import { voucherCode, buttonDisabled, voucherCodeCorrect, deliveryFee, balanceRemaining } from './actions';
import { useSelector, useDispatch } from 'react-redux';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import './VoucherPayment.css';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

function VoucherPayment({ id }) {

    const currentVoucherCode = useSelector(state => state.voucherCode);
    const currentShopName = useSelector(state => state.shopName);
    const currentPaymentOrder = useSelector(state => state.paymentOrder);
    const currentVoucherCodeCorrect = useSelector(state => state.voucherCodeCorrect);
    const currentBalanceRemaining = useSelector(state => state.balanceRemaining);
    const currentCashModal = useSelector(state => state.cashModal);
    const currentWidth = useSelector(state => state.width);
    const dispatch = useDispatch();

    function submit() {

        if ( currentVoucherCodeCorrect === true )
        return;
        else if ( currentPaymentOrder.giftCard === true )
        return;

        dispatch(buttonDisabled(true));

        const db = firebase.firestore();
        const giftCardHolderRef = db.collection("Gift Card Holders").doc(currentShopName);
    
        db.runTransaction((transaction) => {
            return transaction.get(giftCardHolderRef).then((doc) => {
                if (!doc.exists) {
                    dispatch(buttonDisabled(false));
                    return dispatch(voucherCodeCorrect(false));
                }
                else {
                    const data = doc.data();
                    const holders = data.holders;
                    const dataIndex = holders.findIndex(e => e.code === currentVoucherCode);

                    if ( dataIndex === -1 ) {
                        dispatch(buttonDisabled(false));
                        dispatch(voucherCodeCorrect(false));
                    }
                    else if ( holders[dataIndex].price === 0 ) {
                        dispatch(buttonDisabled(false));
                        dispatch(voucherCodeCorrect(false));
                    }
                    else {
                        dispatch(voucherCodeCorrect(true));
                        const balance = holders[dataIndex].price;
                        const price = currentPaymentOrder.price;

                        const bookDeliveryFee = currentPaymentOrder.delivery === false ? 0 : price < 19 ? 1.5 : 0;
    
                        if ( price > balance ) {

                            dispatch(deliveryFee(bookDeliveryFee));
                            dispatch(balanceRemaining(0));

                            db.collection("Customer Orders").doc(id).update({
                                price: price - balance,
                                deliveryFee: bookDeliveryFee,
                                giftCard: true
                            })

                            holders[dataIndex].price = 0;
    
                            transaction.update(giftCardHolderRef, 
                                { 
                                  holders
                                });
                                return holders;
                        }
                        else if ( (price + bookDeliveryFee) > balance ) {
                            dispatch(deliveryFee((price + bookDeliveryFee) - balance));
                            dispatch(balanceRemaining(0));

                            db.collection("Customer Orders").doc(id).update({
                                price: 0,
                                deliveryFee: (price + bookDeliveryFee) - balance,
                                giftCard: true
                            })

                            holders[dataIndex].price = 0;
    
                            transaction.update(giftCardHolderRef, 
                                { 
                                  holders
                                });
                                return holders;
                        }
                        else {

                            db.collection("Customer Orders").doc(id).update({
                                price: 0,
                                deliveryFee: 0,
                                giftCard: true
                            })

                            holders[dataIndex].price = balance - price - bookDeliveryFee;
                            dispatch(deliveryFee(0));

                            dispatch(balanceRemaining(balance - price - bookDeliveryFee));
    
                            transaction.update(giftCardHolderRef, 
                                { 
                                  holders
                                });
                                return holders;
                        }
                    }
                }
            })
            .then(() => {
                console.log('Promise compiled with success');
                dispatch(buttonDisabled(false));
            })
        })
    }

    const styles = {
        voucherMessage: {
            fontFamily: fontFamily,
            color: currentVoucherCodeCorrect === true ? colour.green : colour.primary,
            fontSize: '1em',
            fontWeight: 'normal',
            textAlign: 'left'
        }
    }

    return (
        currentCashModal === true ? 
        null
        :
        currentWidth < 900 ?
            <div className="voucherPaymentCentre">
                <p style={styles.voucherMessage}>
                    {
                        currentVoucherCodeCorrect === true ?
                        "Codice Gift Card applicato con successo :)"
                        :
                        currentVoucherCodeCorrect === false ?
                        "Ooppss..hai inserito un codice inesistente :("
                        :
                        ""
                    }
                </p>
                <div className="voucherPaymentRow">
                    <Input 
                        value={currentVoucherCode}
                        id="outlined"
                        label="Codice Gift Card"
                        inputProps={{
                            autoCapitalize: 'none',
                        }}
                        variant="outlined"
                        onChange={(text) => dispatch(voucherCode(text.target.value))}
                    />
                    <CheckCircleIcon
                        style={{
                                fontSize: 50,
                                color: currentVoucherCode === "" ? colour.med : colour.primary,
                                cursor: currentVoucherCode === "" ? 'auto' : 'pointer'
                            }}
                        className={currentVoucherCode === "" ? "" : "voucherCodeCircle" }
                        onClick={() => currentVoucherCode === "" ? null : submit()}
                    />
                </div>
            </div>
            :
            <div className="voucherPaymentCentre">
                <p style={styles.voucherMessage}>
                    {
                        currentVoucherCodeCorrect === true ?
                        `Codice Gift Card applicato con successo :) Rimangono € ${currentBalanceRemaining.toFixed(2)} sulla tua gift card.`
                        :
                        currentVoucherCodeCorrect === false ?
                        "Ooppss..hai inserito un codice inesistente :("
                        :
                        ""
                    }
                </p>
                <div className="voucherPaymentRow">
                    <VoucherInput
                        value={currentVoucherCode}
                        id="outlined"
                        label="Codice Gift Card"
                        inputProps={{
                            autoCapitalize: 'none',
                        }}
                        variant="outlined"
                        onChange={(text) => dispatch(voucherCode(text.target.value))}
                    />
                    <CheckCircleIcon
                        style={{
                                fontSize: 50,
                                color: currentVoucherCode === "" ? colour.med : colour.primary,
                                cursor: currentVoucherCode === "" ? 'auto' : 'pointer'
                            }}
                        className={currentVoucherCode === "" ? "" : "voucherCodeCircle" }
                        onClick={() => currentVoucherCode === "" ? null : submit()}
                    />
                </div>
            </div>
    )
}

export default VoucherPayment;