import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import { fontFamily, colour, Input, DesktopInput, ActionButton, DesktopActionButton } from './GlobalStyles';
import { isMobile } from 'react-device-detect';
import './Login.css';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { buttonDisabled, userName, password } from './actions';
import Loading from './Loading';

function LogIn() {

    const currentUserName = useSelector(state => state.userName);
    const currentPassword = useSelector(state => state.password);
    const currentButtonDisabled = useSelector(state => state.buttonDisabled);
    const dispatch = useDispatch();
    let history = useHistory();

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                dispatch(buttonDisabled(false));
                history.push('./sitoInterno');
            }
        })
    }, [dispatch, history])

    function login() {

        dispatch(buttonDisabled(true));
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          return firebase.auth().signInWithEmailAndPassword(currentUserName, currentPassword);
        })
        .catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            if ( errorCode === "auth/invalid-email" ) {
                alert("Oopps..Hai inserito una mail sbagliata!");
                dispatch(buttonDisabled(false));
              }
              else if ( errorCode === "auth/user-not-found" ) {
                alert("Oopps..non abbiamo trovato il tuo account nella nostra database!");
                dispatch(buttonDisabled(false));
              }
              else {
                alert("Oopps...c'è stato un errore!", errorMessage);
                dispatch(buttonDisabled(false));
              }
        });

        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                dispatch(buttonDisabled(false));
                history.push('./sitoInterno');
            }
        })
    }

    const styles = {
        headerText: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontSize: 30,
            fontWeight: 'bold'
        }
    }

    return (
        <div>
            <Header />
            <Loading />
            <div className="loginCentre">
                <h1 style={styles.headerText}>
                    Accedi
                </h1>
                <div style={{ height: '5vh' }} />
                {
                    isMobile === true ?
                    <div>
                        <Input 
                            value={currentUserName}
                            id="outlined"
                            label="La mail del destinatario"
                            inputProps={{
                                autoCapitalize: 'none',
                            }}
                            variant="outlined"
                            onChange={(text) => dispatch(userName(text.target.value))}
                        />
                        <div style={{ height: '2vh' }} />
                        <Input
                            value={currentPassword}
                            id="outlined"
                            label="Password"
                            type="password"
                            inputProps={{
                                autoCapitalize: 'none',
                            }}
                            variant="outlined"
                            onChange={(text) => dispatch(password(text.target.value))}
                        />
                        <div style={{ height: '2vh' }} />
                        <ActionButton
                            disabled={currentButtonDisabled}
                            onClick={() => login()}
                        >
                            Accedi
                        </ActionButton>
                    </div>
                    :
                    <div>
                        <DesktopInput 
                            value={currentUserName}
                            id="outlined"
                            label="Email"
                            inputProps={{
                                autoCapitalize: 'none',
                            }}
                            variant="outlined"
                            onChange={(text) => dispatch(userName(text.target.value))}
                        />
                        <div style={{ height: '2vh' }} />
                        <DesktopInput
                            value={currentPassword}
                            id="outlined"
                            label="Password"
                            type="password"
                            inputProps={{
                                autoCapitalize: 'none',
                            }}
                            onKeyDown={(e) => e.key === 'Enter' ? login() : null}
                            variant="outlined"
                            onChange={(text) => dispatch(password(text.target.value))}
                        />
                        <div style={{ height: '2vh' }} />
                        <DesktopActionButton
                            disabled={currentButtonDisabled}
                            onClick={() => login()}
                        >
                            Accedi
                        </DesktopActionButton>
                    </div>
                }
            </div>
            <Footer />
        </div>
    )
}

export default LogIn;