import React, { useEffect } from 'react';
import { fontFamily, colour } from './GlobalStyles';
import './InternalSiteBody.css';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import { internalSiteData, internalSiteExpanded, modalOpen, deleteModalItem, modalIndex, width, height } from './actions';
import moment from 'moment';
import InternalSiteBooks from './InternalSiteBooks';
import InternalSiteModal from './InternalSiteModal';
import { companyName, shopWebsite } from './ShopData';

function InternalSiteBody() {

    const currentWidth = useSelector(state => state.width);
    const currentShopName = useSelector(state => state.shopName);
    const currentShopTitle = useSelector(state => state.shopTitle);
    const currentInternalSiteData = useSelector(state => state.internalSiteData);
    const currentDelivery = useSelector(state => state.internalSiteDelivery);
    const currentInternalSiteIndex = useSelector(state => state.internalSiteIndex);
    const currentInternalSiteExpanded = useSelector(state => state.internalSiteExpanded);
    const currentBookShopEmail = useSelector(state => state.bookShopEmail);
    const currentBookShopAddress = useSelector(state => state.bookShopAddress);
    const currentBookShopPhoneNumber = useSelector(state => state.bookShopPhoneNumber);
    const dispatch = useDispatch();

    useEffect(() => {

        if ( currentShopName === "" )
        return

        const customerQuery = firebase.firestore().collection("Test Customer Data").doc(currentShopName);
        customerQuery.get()
        .then(doc => {
            if (!doc.exists) {
                console.log('No such document!');
            }
            else {
                const data = doc.data();
                const customerData = data.customerData;
                dispatch(internalSiteData(customerData));
            }
        })
        .catch((err) => {
            console.log("error =", err)
        })

        function resizeWindow () {
            console.log("RESIZE STAFF")
            const newWidth = window.innerWidth;
            const newHeight = window.innerHeight;
            dispatch(width(newWidth));
            dispatch(height(newHeight));
      };
      window.addEventListener("resize", resizeWindow);
      return () => window.removeEventListener("resize", resizeWindow);

    }, [currentShopName, dispatch])

    function openEmail(email) {
        window.location.href = `mailto:${email}`;
    }

    function expand(item, index) {
        const findIndex = currentInternalSiteExpanded.indexOf(index);

        if ( findIndex === -1 ) {
            const additionArray = [index];
            const newArray = currentInternalSiteExpanded.concat(additionArray);
            dispatch(internalSiteExpanded(newArray));
        }
        else {
            const cloneArray = currentInternalSiteExpanded.slice();
            cloneArray.splice(findIndex, 1);   
            dispatch(internalSiteExpanded(cloneArray));
        }
    }

    function checkBookStock(item, index) {
        const books = item.books;
        const booksLength = books.length;

        if ( booksLength === 1 && books[0].inStock === true ) {
            return true;
        }
        else  {
            const emptyArray = [];
            for (let i = 0; i < books.length; i++) {
                books[i].inStock === null ?
                emptyArray.push(null)
                :
                books[i].inStock === true && books[i].price === 0 ?
                emptyArray.push(null)
                :
                books[i].inStock === true && books[i].price === "" ?
                emptyArray.push(null)
                :
                books[i].inStock === true ?
                emptyArray.push(true)
                :
                emptyArray.push(false);
            }
            if ( emptyArray.indexOf(null) !== -1 )
            return false;

            else if ( emptyArray.indexOf(true) !== -1 )
            return true;
            
            else 
            return false
        }
    }

    function checkBookPrice(item, index) {
        const books = item.books;
        const emptyArray = [];
        for (let i = 0; i < books.length; i++) {
            books[i].price !== 0 &&  books[i].price !== "" ?
            emptyArray.push(true)
            :
            emptyArray.push(false);
        }
        if ( emptyArray.indexOf(true) !== -1 )
        return true;
        
        else
        return false
    }

    function checkBookChange(item, index) {
        const books = item.books;
        const emptyArray = [];
        for (let i = 0; i < books.length; i++) {
            books[i].change === true ?
            emptyArray.push(true)
            :
            emptyArray.push(false);
        }
        if ( emptyArray.indexOf(true) === -1 )
        return true;
        
        else
        return false
    }

    function deleteItem(item, index) {
        dispatch(modalOpen(true));
        dispatch(modalIndex(999));
        dispatch(deleteModalItem(item));
    }

    function sendPaymentEmail(item, index) {
        dispatch(modalIndex(currentInternalSiteIndex));
        dispatch(modalOpen(true));

        const db = firebase.firestore();
        const customerDataRef = db.collection("Test Customer Data").doc(currentShopName);
    
        db.runTransaction((transaction) => {
            return transaction.get(customerDataRef).then((doc) => {
                if (!doc.exists) {
                    return
                }
                else {
                    const data = doc.data();
                    const customerData = data.customerData;
                    const dataIndex = customerData.findIndex(e => e.code === item.code);
                    customerData[dataIndex].newOrder = false;
                    customerData[dataIndex].awaitingPayment = true;

                    const sendPaymentEmailClone = currentInternalSiteData.slice();
                    sendPaymentEmailClone[index].newOrder = false;
                    sendPaymentEmailClone[index].awaitingPayment = true;
                    dispatch(internalSiteData(sendPaymentEmailClone));

                    transaction.update(customerDataRef, 
                        { 
                            customerData
                        });
                        return customerData;
                }
            })
        }).then(() => {
            console.log("Promise compiled with success");

            firebase.firestore().collection("Payment Email").doc(item.code).set({
                receiverEmail: item.email,
                senderEmail: currentBookShopEmail,
                receiverName: item.name,
                additionalInfo: "",
                shopName: currentShopTitle,
                shopAddress: currentBookShopAddress,
                phoneNumber: currentBookShopPhoneNumber,
                companyName: companyName,
                link: `${shopWebsite}/ordine/${item.code}`
            })
        })
        .catch((err) => {
            console.log("Transaction Error =", err);
        })
    }

    function moveToInProgress(item, index) {
        dispatch(modalIndex(currentInternalSiteIndex));
        dispatch(modalOpen(true));

        const db = firebase.firestore();
        const customerDataRef = db.collection("Test Customer Data").doc(currentShopName);
    
        db.runTransaction((transaction) => {
            return transaction.get(customerDataRef).then((doc) => {
                if (!doc.exists) {
                    return
                }
                else {
                    const data = doc.data();
                    const customerData = data.customerData;
                    const dataIndex = customerData.findIndex(e => e.code === item.code);
                    customerData[dataIndex].awaitingPayment = false;
                    customerData[dataIndex].awaitingCompletion = true;

                    const sendPaymentEmailClone = currentInternalSiteData.slice();
                    sendPaymentEmailClone[index].awaitingPayment = false;
                    sendPaymentEmailClone[index].awaitingCompletion = true;
                    dispatch(internalSiteData(sendPaymentEmailClone));

                    transaction.update(customerDataRef, 
                        { 
                            customerData
                        });
                        return customerData;
                }
            })
        }).then(() => {
            console.log("Promise compiled with success");
        })
        .catch((err) => {
            console.log("Transaction Error =", err);
        })
    }

    function moveToShip(item, index) {
        dispatch(modalIndex(currentInternalSiteIndex));
        dispatch(modalOpen(true));

        const db = firebase.firestore();
        const customerDataRef = db.collection("Test Customer Data").doc(currentShopName);
    
        db.runTransaction((transaction) => {
            return transaction.get(customerDataRef).then((doc) => {
                if (!doc.exists) {
                    return
                }
                else {
                    const data = doc.data();
                    const customerData = data.customerData;
                    const dataIndex = customerData.findIndex(e => e.code === item.code);
                    customerData[dataIndex].awaitingCompletion = false;
                    customerData[dataIndex].ready = true;

                    const sendPaymentEmailClone = currentInternalSiteData.slice();
                    sendPaymentEmailClone[index].awaitingCompletion = false;
                    sendPaymentEmailClone[index].ready = true;
                    dispatch(internalSiteData(sendPaymentEmailClone));

                    transaction.update(customerDataRef, 
                        { 
                            customerData
                        });
                        return customerData;
                }
            })
        }).then(() => {
            console.log("Promise compiled with success");
        })
        .catch((err) => {
            console.log("Transaction Error =", err);
        })
    }

    function archive(item, index) {
        dispatch(modalIndex(currentInternalSiteIndex));
        dispatch(modalOpen(true));

        const db = firebase.firestore();
        const customerDataRef = db.collection("Test Customer Data").doc(currentShopName);
    
        db.runTransaction((transaction) => {
            return transaction.get(customerDataRef).then((doc) => {
                if (!doc.exists) {
                    return
                }
                else {
                    const data = doc.data();
                    const customerData = data.customerData;
                    const dataIndex = customerData.findIndex(e => e.code === item.code);
                    customerData[dataIndex].ready = false;
                    customerData[dataIndex].complete = true;

                    const sendPaymentEmailClone = currentInternalSiteData.slice();
                    sendPaymentEmailClone[index].ready = false;
                    sendPaymentEmailClone[index].complete = true;
                    dispatch(internalSiteData(sendPaymentEmailClone));

                    transaction.update(customerDataRef, 
                        { 
                            customerData
                        });
                        return customerData;
                }
            })
        }).then(() => {
            console.log("Promise compiled with success");
        })
        .catch((err) => {
            console.log("Transaction Error =", err);
        })
    }

    function confirmPublicSectorPayment(item, index, type) {
        dispatch(modalIndex(type));
        dispatch(modalOpen(true));

        const db = firebase.firestore();
        const customerDataRef = db.collection("Test Customer Data").doc(currentShopName);
    
        db.runTransaction((transaction) => {
            return transaction.get(customerDataRef).then((doc) => {
                if (!doc.exists) {
                    return
                }
                else {
                    const data = doc.data();
                    const customerData = data.customerData;
                    const dataIndex = customerData.findIndex(e => e.code === item.code);
                    customerData[dataIndex].eighteenAppPayment = false;
                    customerData[dataIndex].cartaDocentePayment = false;

                    const otherClone = currentInternalSiteData.slice();
                    otherClone[index].eighteenAppPayment = false;
                    otherClone[index].cartaDocentePayment = false;
                    dispatch(internalSiteData(otherClone));

                    transaction.update(customerDataRef, 
                        { 
                            customerData
                        });
                        return customerData;
                }
            })
        }).then(() => {
            console.log("Promise compiled with success");
        })
        .catch((err) => {
            console.log("Transaction Error =", err);
        })
    }

    function openLink(code) {
        window.open(`${shopWebsite}/ordine/${code}`);
    }

    const styles = {
        requestedBooks: {
            fontFamily: fontFamily,
            color: colour.med,
            fontWeight: '500',
            fontSize: '1.125em'
        },
        darkHeader: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontWeight: '500',
            fontSize: '1.125em'
        },
        medHeader: {
            fontFamily: fontFamily,
            color: colour.med,
            fontWeight: '500',
            fontSize: '1.125em',
            fontStyle: 'italic',
            textAlign: 'center'
        },
        email: {
            fontFamily: fontFamily,
            color: colour.blue,
            fontWeight: '500',
            fontSize: '1.125em',
            textDecorationLine: 'underline',
            cursor: 'pointer'
        },
        personalInfo: {
            color: colour.border,
            fontFamily: fontFamily,
            fontWweight: '500',
            fontSize: '1.125em'
        },
        payment: {
            color: currentInternalSiteIndex < 2 ? colour.border : colour.green,
            fontFamily: fontFamily,
            fontWweight: '500',
            fontSize: '1.125em',
            textAlign: 'center'
        },
        cash: {
            color: "#F8DB02",
            fontFamily: fontFamily,
            fontWweight: '500',
            fontSize: '1.125em',
            textAlign: 'center'
        },
        rectangularButton: {
            width: currentWidth < 1200 ? '16vw' : currentWidth < 1700 ? '12vw' : '10vw',
            backgroundColor: currentInternalSiteIndex === 0 || currentInternalSiteIndex === 2 ? colour.green : colour.blue,
            height: '5vh',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            borderRadius: '5px',
            cursor: 'pointer'
        },
        noStock: {
            width: currentWidth < 1200 ? '16vw' : currentWidth < 1700 ? '12vw' : '10vw',
            backgroundColor: colour.med,
            height: '5vh',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            borderRadius: '5px',
            cursor: 'auto'
        },
        whiteButtonText: {
            color: colour.light,
            fontFamily: fontFamily,
            fontSize: '0.875em',
            fontWeight: '500'
        },
        squareButtonContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: currentWidth < 1200 ? '16vw' : currentWidth < 1700 ? '12vw' : '10vw',
        },
        archiveButton: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: colour.lightestGray,
            width: currentWidth < 1200 ? '7.5vw' : currentWidth < 1700 ? '5vw' : '4.5vw',
            height: '5vh',
            borderRadius: '5px',
            cursor: 'pointer'
        },
        deleteButton: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: colour.primary,
            width: currentWidth < 1200 ? '16vw' : currentWidth < 1700 ? '12vw' : '10vw',
            height: '5vh',
            borderRadius: '5px',
            cursor: 'pointer'
        },
        archiveText: {
            fontFamily: fontFamily,
            color: colour.med,
            fontSize: '0.875em',
            fontWeight: '600',
            textAlign: 'center',
            margin: '0 auto'
        },
        deleteText: {
            fontFamily: fontFamily,
            color: colour.light,
            fontSize: '0.875em',
            fontWeight: '600',
            textAlign: 'center',
            margin: '0 auto'
        },
        code: {
            color: colour.primary,
            fontFamily: fontFamily,
            fontWweight: '500',
            fontSize: '1.125em',
            textAlign: 'center',
            wordBreak: 'break-all'
        },
        publicSectorButton: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: colour.blue,
            width: currentWidth < 1200 ? '16vw' : currentWidth < 1700 ? '12vw' : '10vw',
            height: '5vh',
            borderRadius: '5px',
            cursor: 'pointer'
        }
    }

    return (
        <div className="internalSiteBodyOuterContainer">
            <InternalSiteModal />
            {
                currentInternalSiteData.map((item, index) =>
                    currentInternalSiteIndex === 0 && item.newOrder === false ?
                    null
                    :
                    currentInternalSiteIndex === 1 && item.awaitingPayment === false ?
                    null
                    :
                    currentInternalSiteIndex === 2 && item.awaitingCompletion === false ?
                    null
                    :
                    currentInternalSiteIndex === 3 && item.ready === false ?
                    null
                    :
                    currentInternalSiteIndex === 4 && item.complete === false ?
                    null
                    :
                    item.delivery === currentDelivery ?
                    <div className="internalSiteElementContainer">
                        <div className="internalSiteElementText">
                            <div>
                                <p style={styles.requestedBooks}>
                                    Libri Richiesti
                                </p>
                                <img
                                    src={currentInternalSiteExpanded.indexOf(index) === -1 ? require('./dropup.svg') : require('./dropdown.svg')}
                                    className="dropdownIcon"
                                    alt="Freccia"
                                    onClick={() => expand(item, index)}
                                />
                            </div>
                            <div>
                                <p style={styles.darkHeader}>
                                    {moment.unix(item.date.seconds).format("DD/MM/YYYY")}
                                </p>
                            </div>
                            <div>
                                <p style={styles.darkHeader}>
                                    {item.name} {item.surname}
                                </p>
                                <p 
                                    onClick={() => openEmail(item.email)}
                                    style={styles.email}
                                >
                                    {item.email}
                                </p>
                                <p style={styles.personalInfo}>
                                    {item.phoneNumber}
                                </p>
                                {
                                    currentInternalSiteIndex !== 0 ?
                                    <p 
                                        onClick={() => openLink(item.code)}
                                        style={styles.email}
                                    >
                                       {shopWebsite}/ordine/{item.code}
                                    </p>
                                    :
                                    null
                                }
                                {
                                    currentDelivery === true ?
                                    <div>
                                        <p style={styles.personalInfo}>
                                            {item.address}
                                        </p>
                                        <p style={styles.personalInfo}>
                                            Citofono: {item.doorbell}
                                        </p>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            <div className="internalSitePaymentContainer">
                                <p style={styles.medHeader}>
                                    Pagamento?
                                </p>
                                <div style={{ height: currentDelivery === true ? '2.5vh' : 0 }} />
                                <p style={item.eighteenAppPayment === true ? styles.cash : item.cartaDocentePayment === true ? styles.cash : item.cash === true ? styles.cash : styles.payment}>
                                    {
                                        currentInternalSiteIndex === 0 ?
                                        "Non Inviato"
                                        :
                                        currentInternalSiteIndex === 1 ?
                                        "In attesa di pagamento"
                                        :
                                        item.eighteenAppPayment === true ?
                                        "18App"
                                        :
                                        item.cartaDocentePayment === true ?
                                        "Carta del Docente"
                                        :
                                        item.cash === true ?
                                        "Pagamento in contanti"
                                        :
                                        item.payment === true ?
                                        "Pagamento Ricevuto"
                                        :
                                        "In attesa di pagamento"
                                    }
                                </p>
                                {
                                    item.eighteenAppPayment === true ?
                                    <p style={styles.code}>
                                        {item.publicSectorCode}
                                    </p>
                                    :
                                    item.cartaDocentePayment === true ?
                                    <p style={styles.code}>
                                        {item.publicSectorCode}
                                    </p>
                                    :
                                    null
                                }
                            </div>
                            <div className="internalSitePriceContainer">
                                <p style={styles.medHeader}>
                                    Dare
                                </p>
                                <div style={{ height: currentDelivery === true ? '2.5vh' : 0 }} />
                                <p style={styles.payment}>
                                    {
                                        item.cash === true && item.delivery === true && item.price < 19 ?
                                        "€ " + (item.price + 1.5).toFixed(2)
                                        :
                                        item.eighteenAppPayment === true && item.delivery === true && item.price < 19 ?
                                        "€ " + (item.price + 1.5).toFixed(2)
                                        :
                                        item.cartaDocentePayment === true && item.delivery === true && item.price < 19 ?
                                        "€ " + (item.price + 1.5).toFixed(2)
                                        :
                                        item.price !== undefined ?
                                        "€ " + item.price.toFixed(2)
                                        :
                                        "€ 0,00"
                                    }
                                </p>
                            </div>
                            <div className="internalSiteButtonContainer">
                                {
                                    currentInternalSiteIndex === 4 ?
                                    null
                                    :
                                    <div
                                        className={checkBookStock(item, index) === true && checkBookPrice(item, index) === true && checkBookChange(item, index) === true ? "internalSiteActionButton" : ""} 
                                        style={checkBookStock(item, index) === true && checkBookPrice(item, index) === true && checkBookChange(item, index) === true ? styles.rectangularButton : styles.noStock}
                                        onClick={() => checkBookStock(item, index) === true && checkBookPrice(item, index) === true && checkBookChange(item, index) === true && currentInternalSiteIndex === 0 ? sendPaymentEmail(item, index) : currentInternalSiteIndex === 0 ? null : currentInternalSiteIndex === 1 ? moveToInProgress(item, index) : currentInternalSiteIndex === 2 ? moveToShip(item, index) : archive(item, index)}
                                    >
                                        <p style={styles.whiteButtonText}>
                                            {
                                                currentInternalSiteIndex === 0 ?
                                                "Richiedi Pagamento"
                                                :
                                                currentInternalSiteIndex === 1 ?
                                                "Sposta in Lavorazione"
                                                :
                                                currentInternalSiteIndex === 2 && currentDelivery === true ?
                                                "Da Spedire"
                                                :
                                                currentInternalSiteIndex === 2 && currentDelivery === false ?
                                                "Da Ritirare"
                                                :
                                                currentInternalSiteIndex === 3 && currentDelivery === true ?
                                                "Ordine Spedito"
                                                :
                                                "Ordine Ritirato"
                                            }
                                        </p>
                                        {
                                            currentInternalSiteIndex === 3 ?
                                            null
                                            :
                                            <img
                                                src={currentInternalSiteIndex === 2 ? require('./tick.svg') : require('./arrow-forward.svg')}
                                                className="arrowForward"
                                                alt="Freccia Avanti"
                                            />
                                        }
                                    </div>
                                }
                                <div style={{ height: '1vh' }} />
                                <div style={styles.squareButtonContainer}>
                                    {/* <div
                                        className="internalSiteActionButton"  
                                        style={styles.archiveButton}
                                    >
                                        <img
                                            src={require('./archive.svg')}
                                            className="archiveIcon"
                                            alt="Archivia"
                                        />
                                        <p style={styles.archiveText}>
                                            Archivia
                                        </p>
                                    </div> */}
                                    <div
                                        className="internalSiteActionButton"  
                                        style={styles.deleteButton}
                                        onClick={() => deleteItem(item, index)}
                                    >
                                        <img
                                            src={require('./delete.svg')}
                                            className="deleteIcon"
                                            alt="Cancella"
                                        />
                                        <p style={styles.deleteText}>
                                            Cancella
                                        </p>
                                    </div>
                                </div>
                                <div style={{ height: '1vh' }} />
                                {
                                    item.eighteenAppPayment === true ?
                                    <div 
                                        onClick={() => confirmPublicSectorPayment(item, index, 25)}
                                        style={styles.publicSectorButton} className="internalPublicSectorButton"
                                    >
                                        <p style={styles.whiteButtonText}>
                                            18App Pagato
                                        </p>
                                    </div>
                                    :
                                    item.cartaDocentePayment === true ?
                                    <div style={styles.publicSectorButton} className="internalPublicSectorButton">
                                        <p
                                            onClick={() => confirmPublicSectorPayment(item, index, 35)} 
                                            style={styles.whiteButtonText}
                                        >
                                            Carta del Docente Pagato
                                        </p>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <InternalSiteBooks 
                            customerItem={item}
                            customerIndex={index}
                            currentInternalSiteExpanded={currentInternalSiteExpanded}
                        />
                    </div>
                    :
                    null
                )
            }
        </div>
    )
}

export default InternalSiteBody;