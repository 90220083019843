import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import InternalSite from './InternalSite';
import Pay from './Pay';
import CartaDocente from './CartaDocente';
import FurtherInfo from './FurtherInfo';
import GiftCard from './GiftCard';
import Success from './Success';
import Cancel from './Cancel';
// import Testing from './Testing';
import OrderConfirmed from './OrderConfirmed';
import * as serviceWorker from './serviceWorker';
import * as firebase from 'firebase';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Login from './Login';
import { createStore } from 'redux';
import allReducer from './reducers';
import { Provider } from 'react-redux';
import ScrollToTop from './ScrollToTop';

const store = createStore(
    allReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )

const firebaseConfig = {
    apiKey: "AIzaSyAwE4K9yVY-BaZ0U3suE2tD26R2g05OgOo",
    authDomain: "mondadori-c11d4.firebaseapp.com",
    databaseURL: "https://mondadori-c11d4.firebaseio.com",
    projectId: "mondadori-c11d4",
    storageBucket: "mondadori-c11d4.appspot.com",
    messagingSenderId: "96371848747",
    appId: "1:96371848747:web:dc975ade51d2317d979c6e",
    measurementId: "G-GK79HJPZD7"
  };
firebase.initializeApp(firebaseConfig);

const routing = (
    <Provider
        store={store}
    >
        <Router>
            <ScrollToTop />
            <Switch>
                <Route exact path="/" component={App} />
                <Route exact path="/conferma" component={OrderConfirmed} />
                <Route exact path="/sitoInterno" component={InternalSite} />
                <Route exact path="/pagamentoCompleto" component={Success} />
                <Route exact path="/pagamentoAnnullato" component={Cancel} />
                <Route exact path="/accedi" component={Login} />
                {/* <Route exact path="/testing" component={Testing} /> */}
                <Route 
                    path="/ordine/:id" 
                    render={({ match }) => <Pay match={match} />}
                />
                <Route exact path="/18App" component={CartaDocente} />
                <Route exact path="/maggioriInformazioni" component={FurtherInfo} />
                <Route exact path="/giftCard" component={GiftCard} />
                <Route path="/:id" component={App} />
            </Switch>
        </Router>
    </Provider>
)

ReactDOM.render(
    routing, document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
