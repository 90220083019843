import React from 'react';
import Lottie from 'react-lottie-player';
import lottieJson from './book-loading.json';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

function Loading() {

    const currentButtonDisabled = useSelector(state => state.buttonDisabled);

    const styles = {
        animation: {
            position: 'absolute', 
            zIndex: 100, 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'center', 
            width: '100vw'
        }
    }

    return (
        currentButtonDisabled === true ?
        <div style={styles.animation}>
            <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: isMobile === true ? '40vw' : '20vw' }}
            />
        </div>
        :
        null     
    )
}

export default Loading;