import React from 'react';
import { fontFamily, colour, ActionButton } from './GlobalStyles';
import { useSelector, useDispatch } from 'react-redux';
import './OrderReviewMobile.css';
import { buttonDisabled } from './actions';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { useHistory } from 'react-router-dom';
import { companyName } from './ShopData';
import Loading from './Loading';

const OrderReviewMobile = React.forwardRef((props, ref) => {

    const currentName = useSelector(state => state.nameAction);
    const currentSurname = useSelector(state => state.surnameAction);
    const currentEmail = useSelector(state => state.emailAction);
    const currentPhone = useSelector(state => state.phoneAction);
    const currentAddress = useSelector(state => state.addressAction);
    const currentDoorbell = useSelector(state => state.doorbellAction);
    const currentDelivery = useSelector(state => state.delivery);
    const currentBookshopAddress = useSelector(state => state.bookShopAddress);
    const currentShopName = useSelector(state => state.shopName);
    const currentBooks = useSelector(state => state.books);
    const currentShopTitle = useSelector(state => state.shopTitle);
    const currentBookShopAddress = useSelector(state => state.bookShopAddress);
    const currentBookShopPhoneNumber = useSelector(state => state.bookShopPhoneNumber);
    const currentBookShopEmail = useSelector(state => state.bookShopEmail);
    const dispatch = useDispatch();
    let history = useHistory();

     function makeId(length) {
        dispatch(buttonDisabled(true));
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return submit(result);
    }

    function submit(id) {

        const db = firebase.firestore();
        const customerDataRef = db.collection("Test Customer Data").doc(currentShopName);
    
        db.runTransaction((transaction) => {
            return transaction.get(customerDataRef).then((doc) => {
                if (!doc.exists) {
                    console.log("Document does not exist!");
                    const customerData = [];

                    const newCustomer = {
                        address: currentAddress,
                        name: currentName,
                        surname: currentSurname,
                        email: currentEmail,
                        phoneNumber: currentPhone,
                        payment: false,
                        date: new Date(),
                        expand: false,
                        books: currentBooks,
                        code: id,
                        newOrder: true,
                        awaitingPayment: false,
                        awaitingCompletion: false,
                        complete: false,
                        ready: false,
                        doorbell: currentDoorbell,
                        delivery: currentDelivery,
                        price: 0
                    };

                    customerData.push(newCustomer);
                    
                    firebase.firestore().collection("Test Customer Data").doc(currentShopName).set({
                        customerData
                    })
                    return customerData;
                }
                else {
                    const customerData = doc.data().customerData;

                    const newCustomer = {
                        address: currentAddress,
                        name: currentName,
                        surname: currentSurname,
                        email: currentEmail,
                        phoneNumber: currentPhone,
                        payment: false,
                        date: new Date(),
                        expand: false,
                        books: currentBooks,
                        code: id,
                        newOrder: true,
                        awaitingPayment: false,
                        awaitingCompletion: false,
                        complete: false,
                        ready: false,
                        doorbell: currentDoorbell,
                        delivery: currentDelivery,
                        price: 0
                    };

                    customerData.push(newCustomer);
                
                    transaction.update(customerDataRef, 
                    { 
                        customerData
                    });
                    return customerData;
                }
            });
        }).then(() => {
            console.log("Promise compiled with success");

            firebase.firestore().collection("Customer Orders").doc(id).set({
                address: currentAddress,
                name: currentName,
                surname: currentSurname,
                email: currentEmail,
                phoneNumber: currentPhone,
                payment: false,
                date: new Date(),
                expand: false,
                books: currentBooks,
                code: id,
                newOrder: true,
                awaitingPayment: false,
                awaitingCompletion: false,
                complete: false,
                ready: false,
                doorbell: currentDoorbell,
                delivery: currentDelivery,
                price: 0,
                shopAddress: currentBookShopAddress
            })
            .then(() => {
                firebase.firestore().collection("Order Confirmed").doc(id).set({
                    receiverEmail: currentEmail,
                    senderEmail: currentBookShopEmail,
                    receiverName: currentName,
                    books: currentBooks,
                    shopName: currentShopTitle,
                    shopAddress: currentBookShopAddress,
                    phoneNumber: currentBookShopPhoneNumber,
                    companyName: companyName
                })
                .then(() => {
                    history.push('./conferma');
                    dispatch(buttonDisabled(false));
                })
            });
        })
        .catch((err) => {
            dispatch(buttonDisabled(false));
            console.error(err);
            firebase.firestore("Customer Submission Error").doc("Data").set({
                error: err
            })
        })
    }

    const styles = {
        headerText: {
            color: colour.dark,
            fontFamily: fontFamily,
            fontWeight: 'normal',
            fontSize: '1.375em',
            textAlign: 'center'
        },
        subheader: {
            color: colour.med,
            fontFamily: fontFamily,
            fontWeight: 'normal',
            fontSize: '1em'
        },
        name: {
            color: colour.dark,
            fontFamily: fontFamily,
            fontWeight: 'bold',
            fontSize: '1em'
        },
        information: {
            color: colour.dark,
            fontFamily: fontFamily,
            fontWeight: 'normal',
            fontSize: '1em'
        },
        back: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontSize: '0.875em',
            fontWeight: 'normal',
            textAlign: 'center',
            cursor: 'pointer'
        }
    }

    return (
        <div>
            <h1 style={styles.headerText}>
                Conferma
            </h1>
            <Loading />
            <div className="orderReviewMobileText">
                <h2 style={styles.subheader}>
                    Le tue informazioni:
                </h2>
                <p style={styles.name}>
                    {currentName} {currentSurname}
                </p>
                <p style={styles.information}>
                    {currentEmail}
                </p>
                <p style={styles.information}>
                    {currentPhone}
                </p>
                <div style={{ height: '5vh' }} />
                {
                    currentDelivery === true ?
                    <div>
                        <h2 style={styles.subheader}>
                            Indirizzo di Consegna:
                        </h2>
                        <p style={styles.information}>
                            {currentDoorbell}
                        </p>
                        <p style={styles.information}>
                            {currentAddress}
                        </p>
                    </div>
                    :
                    <div>
                        <h2 style={styles.subheader}>
                            Indirizzo di Ritiro:
                        </h2>
                        <p style={styles.information}>
                            {currentBookshopAddress}
                        </p>
                    </div>
                }
            </div>
            <div className="centreOrderReview">
                <ActionButton
                    onClick={() => makeId(15)}
                >
                    Conferma Ordine
                </ActionButton>
                <div style={{ height: '2vh' }} />
                <p
                    style={styles.back}
                    onClick={() => props.onBack()}
                >
                    Indietro
                </p>
            </div>
        </div>
    )

})

export default OrderReviewMobile;