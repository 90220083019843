import width from './width';
import height from './height';
import bookPage from './bookPage';
import nameAction from './nameAction';
import surnameAction from './surnameAction';
import emailAction from './emailAction';
import phoneAction from './phoneAction';
import addressAction from './addressAction';
import doorbellAction from './doorbellAction';
import giftCardIndex from './giftCardIndex';
import price from './price';
import bookTitle from './bookTitle';
import author from './author';
import editor from './editor';
import notes from './notes';
import books from './books';
import shopName from './shopName';
import buttonDisabled from './buttonDisabled';
import delivery from './delivery';
import recipientEmail from './recipientEmail';
import customGift from './customGift';
import bookShopAddress from './bookShopAddress';
import recipientName from './recipientName';
import giftMessage from './giftMessage';
import giftCardDate from './giftCardDate';
import paymentOrder from './paymentOrder';
import navActive from './navActive';
import shopTitle from './shopTitle';
import internalSiteDelivery from './internalSiteDelivery';
import internalSiteIndex from './internalSiteIndex';
import internalSiteData from './internalSiteData';
import internalSitePrice from './internalSitePrice';
import internalSiteExpanded from './internalSiteExpanded';
import internalSiteStock from './internalSiteStock';
import modalOpen from './modalOpen';
import modalIndex from './modalIndex';
import deleteModalItem from './deleteModalItem';
import focus from './focus';
import bookShopPhoneNumber from './bookShopPhoneNumber';
import bookShopEmail from './bookShopEmail';
import userName from './userName';
import password from './password';
import internalSiteGiftCard from './internalSiteGiftCard';
import giftCardHolders from './giftCardHolders';
import voucherCode from './voucherCode';
import voucherCodeCorrect from './voucherCodeCorrect';
import deliveryFee from './deliveryFee';
import balanceRemaining from './balanceRemaining';
import dateChanged from './dateChanged';
import cashModal from './cashModal';
import cashPayment from './cashPayment';
import eighteenAppPayment from './eighteenAppPayment';
import cartaDocentePayment from './cartaDocentePayment';
import publicSectorCode from './publicSectorCode';
import quantity from './quantity';
import { combineReducers } from 'redux';

const allReducers = combineReducers({
    width: width,
    height: height,
    bookPage: bookPage,
    nameAction: nameAction,
    surnameAction: surnameAction,
    emailAction: emailAction,
    phoneAction: phoneAction,
    addressAction: addressAction,
    doorbellAction: doorbellAction,
    giftCardIndex: giftCardIndex,
    price: price,
    bookTitle: bookTitle,
    author: author,
    editor: editor,
    notes: notes,
    books: books,
    shopName: shopName,
    buttonDisabled: buttonDisabled,
    delivery: delivery,
    recipientEmail: recipientEmail,
    customGift: customGift,
    bookShopAddress: bookShopAddress,
    recipientName: recipientName,
    giftMessage: giftMessage,
    giftCardDate: giftCardDate,
    paymentOrder: paymentOrder,
    navActive: navActive,
    shopTitle: shopTitle,
    internalSiteDelivery: internalSiteDelivery,
    internalSiteIndex: internalSiteIndex,
    internalSiteData: internalSiteData,
    internalSitePrice: internalSitePrice,
    internalSiteExpanded: internalSiteExpanded,
    internalSiteStock: internalSiteStock,
    modalOpen: modalOpen,
    modalIndex: modalIndex,
    deleteModalItem: deleteModalItem,
    focus: focus,
    bookShopPhoneNumber: bookShopPhoneNumber,
    bookShopEmail: bookShopEmail,
    userName: userName,
    password: password,
    internalSiteGiftCard: internalSiteGiftCard,
    giftCardHolders: giftCardHolders,
    voucherCode: voucherCode,
    voucherCodeCorrect: voucherCodeCorrect,
    deliveryFee: deliveryFee,
    balanceRemaining: balanceRemaining,
    dateChanged: dateChanged,
    cashModal: cashModal,
    cashPayment: cashPayment,
    eighteenAppPayment: eighteenAppPayment,
    cartaDocentePayment: cartaDocentePayment,
    publicSectorCode: publicSectorCode,
    quantity: quantity
});

export default allReducers;