import React from 'react';
import { fontFamily, colour, ActionButton, Input } from './GlobalStyles';
import { useSelector, useDispatch } from 'react-redux';
import { nameAction, surnameAction } from './actions';
import './MobileBookPage.css';

const NameMobile = React.forwardRef((props, ref) => {

    const currentName = useSelector(state => state.nameAction);
    const currentSurname = useSelector(state => state.surnameAction);
    const dispatch = useDispatch();

    const styles = {
        headerText: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontWeight: 'normal',
            fontSize: '1.375em',
            textAlign: 'center'
        },
        back: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontSize: '0.875em',
            fontWeight: 'normal',
            textAlign: 'center',
            cursor: 'pointer'
        }
    }

    return (
        <div className="mobileBookPageOuterContainer">
            <h1 style={styles.headerText}>
                Inserisci il tuo Nome
            </h1>
            <div>
                <Input
                    value={currentName}
                    id="outlined"
                    label="Nome"
                    inputProps={{
                        autoCapitalize: 'none',
                    }}
                    variant="outlined"
                    onChange={(text) => dispatch(nameAction(text.target.value))}
                />
                <div style={{ height: '2vh' }} />
                <Input 
                    value={currentSurname}
                    id="outlined"
                    label="Cognome"
                    inputProps={{
                        autoCapitalize: 'none',
                    }}
                    variant="outlined"
                    onChange={(text) => dispatch(surnameAction(text.target.value))}
                />
                <div style={{ height: '2vh' }} />
                <ActionButton
                    ref={ref} {...props}
                    disabled={
                                currentName === "" ?
                                true
                                :
                                currentSurname === "" ?
                                true
                                :
                                false
                            }
                >
                    Continua
                </ActionButton>
                <div style={{ height: '2vh' }} />
                <p
                    onClick={() => props.onBack()} 
                    style={styles.back}
                >
                    Indietro
                </p>
            </div>
        </div>
    )
})

export default NameMobile;