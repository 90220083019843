import React from 'react';
import './LandingComponentMobile.css';
import { fontFamily, colour } from './GlobalStyles';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import ChooseBookMobile from './ChooseBookMobile';
import BookListMobile from './BookListMobile';
import DeliveryMobile from './DeliveryMobile';
import NameMobile from './NameMobile';
import EmailMobile from './EmailMobile';
import PhoneMobile from './PhoneMobile';
import AddressMobile from './AddressMobile';
import DoorbellMobile from './DoorbellMobile';
import OrderReviewMobile from './OrderReviewMobile';
import { useSelector } from 'react-redux';

function LandingComponentMobile() {

    const currentDelivery = useSelector(state => state.delivery);

    function slideForward() {
        const mySwiper = document.querySelector('.swiper-container').swiper;
        mySwiper.slideNext();
    }

    function slideBack() {
        const mySwiper = document.querySelector('.swiper-container').swiper;
        mySwiper.slidePrev();
    }

    const styles = {
        headerText: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontSize: '1.375em',
            fontWeight: '500',
            textAlign: 'center'
        }
    }

    return (
        <div className="landingComponentMobileOuterContainer">
             <h1 style={styles.headerText}>
                Ordina il tuo libro preferito a domicilio!
                <br />
                o
                <br />
                Prenota il tuo libro preferito e ritiralo in libreria
            </h1>
            <div className="landingComponentMobileWidth">
                <div>
                    <Swiper
                        spaceBetween={0}
                        slidesPerView={1}
                        allowTouchMove={false}
                        pagination={{ clickable: false, draggable: false }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                    >
                        <SwiperSlide>
                            <ChooseBookMobile 
                                onClick={slideForward}
                                onBack={slideBack}
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <BookListMobile 
                                onClick={slideForward}
                                onBack={slideBack}
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <DeliveryMobile 
                                onClick={slideForward}
                                onBack={slideBack}
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <NameMobile
                                onClick={slideForward}
                                onBack={slideBack}
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <EmailMobile
                                onClick={slideForward}
                                onBack={slideBack}
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <PhoneMobile
                                onClick={slideForward}
                                onBack={slideBack}
                            />
                        </SwiperSlide>
                    {
                        currentDelivery === true ?
                        <SwiperSlide>
                            <AddressMobile
                                onClick={slideForward}
                                onBack={slideBack}
                            />
                        </SwiperSlide>
                        :
                        null
                    }
                    {
                        currentDelivery === true ?
                        <SwiperSlide>
                            <DoorbellMobile
                                onClick={slideForward}
                                onBack={slideBack}
                            />
                        </SwiperSlide>
                        :
                        null
                    }
                        <SwiperSlide>
                            <OrderReviewMobile
                                onClick={slideForward}
                                onBack={slideBack}
                            />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    )
}

export default LandingComponentMobile;