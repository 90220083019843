import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { fontFamily, colour, ActionButton, DesktopActionButton } from './GlobalStyles';
import './Success.css';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

function Cancel() {

    let history = useHistory();

    const styles = {
        headerText: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontSize: 30,
            fontWeight: '500',
            textAlign: 'center'
        }
    }

    return (
        <div>
            <Header />
            <div className="successContainer">
                <h1 style={styles.headerText}>
                    Il Pagamento è stato annullato
                </h1>
                <div style={{ height: '10vh' }} />
                {
                    isMobile === true ?
                    <ActionButton
                        onClick={() => history.push('../')}
                    >
                        Torna alla Home
                    </ActionButton>
                    :
                    <DesktopActionButton
                        onClick={() => history.push('../')}
                    >
                        Torna alla Home
                    </DesktopActionButton>
                }
            </div>
            <Footer />
        </div>
    )
}

export default Cancel;