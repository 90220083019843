import React from 'react';
import Header from './Header';
import LandingComponent from './LandingComponent';
import Footer from './Footer';
import { isMobile } from 'react-device-detect';
import LandingComponentMobile from './LandingComponentMobile';

function App() {

  const styles = {
    header: {
      position: 'fixed',
      zIndex: 10
    }
  }

  return (
    <div className="App">
      <div style={styles.header}>
        <Header />
      </div>
      {
        isMobile === false ?
          <LandingComponent />
          :
          <LandingComponentMobile />
        }
      <Footer />
    </div>
  )
}

export default App;