import React from 'react';
import { fontFamily, colour, DesktopActionButton } from './GlobalStyles';
import { useSelector, useDispatch } from 'react-redux';
import { delivery } from './actions';
import './Delivery.css';

const Delivery = React.forwardRef((props, ref) => {

    const currentDelivery = useSelector(state => state.delivery);
    const dispatch = useDispatch();

    function setDeliveryOption(item) {
        item.type === 'deliver' ? dispatch(delivery(true)) : dispatch(delivery(false));
    }

    const deliveryOptions = [
        {
            title: "Consegna a Domicilio",
            type: "deliver"
        },
        {
            title: "Ritiro in Libreria",
            type: "pickup"
        }
    ]

    const styles = {
        headerText: {
            fontFamily: fontFamily, 
            color: colour.dark,
            fontWeight: 'normal',
            fontSize: '1.375em',
            textAlign: 'center'
        }
    }
    
    return (
        <div className="bookPageOuterMostContainer">
            <div className="bookPageContainer">
                <div>
                    <h1 style={styles.headerText}>
                        Consegna a Domicilio o Ritiro in Libreria?
                    </h1>
                </div>
                <div style={{ height: '5vh' }} />
                {
                    deliveryOptions.map((item, index) =>
                        <div 
                            onClick={() => setDeliveryOption(item)}
                            className={currentDelivery === true && item.type === 'deliver' ? "deliverySelectedRadioContainer" : currentDelivery === false && item.type === 'pickup' ? "deliverySelectedRadioContainer" : "deliveryRadioContainer"}
                        >
                            <div className="radioTextContainer">
                                <p style={{ fontFamily: fontFamily, color: currentDelivery === true && item.type === 'deliver' ? colour.primary : currentDelivery === false && item.type === 'pickup' ? colour.primary : colour.med, fontSize: '1em', fontWeight: currentDelivery === true && item.type === 'deliver' ? 'bold' : currentDelivery === false && item.type === 'pickup' ? 'bold' : 'normal', textAlign: 'center' }}>
                                    {item.title}
                                </p>
                            </div>
                        </div>
                    )   
                }
                <div className="centre">
                    <DesktopActionButton
                        disabled={currentDelivery === null ? true : false}
                        ref={ref} {...props}
                    >
                        Continua
                    </DesktopActionButton>
                </div>
            </div>
        </div>
    )
})

export default Delivery;