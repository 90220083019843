import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import { giftCardIndex, customGift, price } from './actions';
import { isMobile } from 'react-device-detect';
import { fontFamily, colour, Input, DesktopInput, ActionButton, DesktopActionButton } from './GlobalStyles';


const GiftCardPrice = React.forwardRef((props, ref) => {

    const currentWidth = useSelector(state => state.width);
    const currentCustomGift = useSelector(state => state.customGift);
    const currentGiftCardIndex = useSelector(state => state.giftCardIndex);
    const currentPrice = useSelector(state => state.price);
    const dispatch = useDispatch();

    const giftCardList = [
        {
            value: 15
        },
        {
            value: 25
        },
        {
            value: 50
        },
        {
            value: 75
        },
        {
            value: 100
        },
        {
            value: 150
        },
    ]

    function setPrice(index) {
        dispatch(giftCardIndex(index));
        dispatch(customGift(""));
        dispatch(price(giftCardList[index].value));
    }

    function setCustomPrice(text) {
        dispatch(customGift(text.target.value));
        dispatch(giftCardIndex(null));
        dispatch(price(Number(text.target.value).toFixed(2)));
    }

    const styles = {
        subheader: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontSize: '1.125em',
            fontWeight: 'bold'
        },
        back: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontSize: '0.875em',
            fontWeight: 'normal',
            textAlign: 'center',
            cursor: 'pointer'
        }
    }

    return (
        <div className="giftCardInfoOuterContainer">
            <div className="giftCardInfoInnerContainer">
                <div>
                    <div className={isMobile === true ? "mobilePriceSubheader" : "priceSubheader"}>
                        <h2 style={styles.subheader}>
                            Scegli l'importo
                        </h2>
                    </div>
                    <div className={
                                    isMobile === true ? "mobileGiftCardList"
                                    :
                                    currentWidth < 900 ?
                                    "mobileGiftCardList"
                                    :
                                    "giftCardList"
                                }
                    >
                        {
                            giftCardList.map((item, index) =>
                                <div 
                                    onClick={() => setPrice(index)}
                                    className={
                                                currentGiftCardIndex === index && isMobile === true  ? "mobileSelectedRadioContainer" : isMobile === true ? "mobileRadioContainer"
                                                :
                                                currentGiftCardIndex === index && currentWidth < 900  ? "mobileSelectedRadioContainer" : currentWidth < 900 ? "mobileRadioContainer"
                                                :
                                                currentGiftCardIndex === index ? "selectedRadioContainer" : "radioContainer"
                                            }
                                >
                                    <div className="radioTextContainer">
                                        <p style={{ fontFamily: fontFamily, color: currentGiftCardIndex === index ? colour.primary : colour.med, fontSize: '1em', fontWeight: currentGiftCardIndex === index ? 'bold' : 'normal', textAlign: 'center' }}>
                                            €{item.value}
                                        </p>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div style={{ height: currentWidth < 900 ? '2vh' : 0 }} />
                    <div className="giftCardCentre">
                        {
                            currentWidth < 900 ?
                            <Input 
                                value={currentCustomGift}
                                id="outlined"
                                label="Personalizza l'Importo"
                                inputProps={{
                                    autoCapitalize: 'none',
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">€</InputAdornment>
                                }}
                                variant="outlined"
                                onChange={(text) => isNaN(Number(text.target.value)) ? null : setCustomPrice(text)}
                            />
                            :
                            <DesktopInput 
                                value={currentCustomGift}
                                id="outlined"
                                label="Personalizza l'Importo"
                                inputProps={{
                                    autoCapitalize: 'none',
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">€</InputAdornment>
                                }}
                                variant="outlined"
                                onChange={(text) => isNaN(Number(text.target.value)) ? null : setCustomPrice(text)}
                            />
                        }
                    </div>
                    <div style={{ height: '2vh' }} />
                    <div className="giftCardCentre">
                        {
                            isMobile === true ?
                            <ActionButton
                                onClick={() => props.onClick()}
                                disabled={currentPrice === 0 || currentPrice === "0.00" ? true : false}
                            >
                                Continua
                            </ActionButton>
                            :
                            currentWidth < 900 ?
                            <ActionButton
                                disabled={currentPrice === 0 || currentPrice === "0.00" ? true : false}
                                onClick={() => props.onClick()}
                            >
                                Continua
                            </ActionButton>
                            :
                            <DesktopActionButton
                                onClick={() => props.onClick()}
                                disabled={currentPrice === 0 || currentPrice === "0.00" ? true : false}
                            >
                                Continua
                            </DesktopActionButton>
                        }
                        <div style={{ height: '2vh' }} />
                        <p
                            style={styles.back}
                            onClick={() => props.onBack()}
                        >
                            Indietro
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default GiftCardPrice;