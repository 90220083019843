import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { fontFamily, colour } from './GlobalStyles';
import './CartaDocente.css';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

function CartaDocente() {

    const currentShopEmail = useSelector(state => state.bookShopEmail);

    const styles = {
        headerText: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontWeight: 'bold',
            fontSize: 30,
            textAlign: 'center'
        },
        description: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontWeight: 'normal',
            fontSize: '1em',
            textAlign: 'center'
        },
        email: {
            textDecorationLine: 'underline',
            color: colour.blue,
            cursor: 'pointer'
        }
    }

    return (
        <div>
            <Header />
            <div className="cartaDocenteCentre">
                <h1 style={styles.headerText}>
                    18App | Carte del Docente
                </h1>
                <div style={{ height: '10vh' }} />
                <img 
                    src="https://firebasestorage.googleapis.com/v0/b/mondadori-c11d4.appspot.com/o/logo-18app-cartadocente%402x.png?alt=media&token=00734cec-580e-4e40-aef6-5ff23146df58"
                    className={isMobile === true ? "cartaDocenteImageMobile" : "cartaDocenteImage"}
                    alt="Carta del Docente | 18App"
                />
                <div style={{ height: '2vh' }} />
                <div className={isMobile === true ? "cartaDocenteDescriptionMobile" : "cartaDocenteDescription"}>
                    <p style={styles.description}>
                        Per pagamenti con 18App o Carta
                        del Docente, vi preghiamo di scrivere
                        a{' '}
                        <a 
                            href={`mailto:${currentShopEmail}`}
                            style={styles.email}>
                            {currentShopEmail}
                        </a>
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CartaDocente;