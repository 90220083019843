import React from 'react';
import { fontFamily, colour } from './GlobalStyles';
import './InternalSiteSkeleton.css';
import { useSelector, useDispatch } from 'react-redux';
import { internalSiteIndex } from './actions';
import InternalSiteBody from './InternalSiteBody';
import InternalSiteGiftCard from './InternalSiteGiftCard';

function InternalSiteSkeleton() {

    const currenWidth = useSelector(state => state.width);
    const currentInternalSiteIndex = useSelector(state => state.internalSiteIndex);
    const currentDelivery = useSelector(state => state.internalSiteDelivery);
    const currentGiftCard = useSelector(state => state.internalSiteGiftCard);
    const dispatch = useDispatch();

    const styles = {
        headerText: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontWeight: '500',
            fontSize: currenWidth < 1500 ? '1.125em' : '1.375em',
            cursor: 'pointer',
            textAlign: 'center'
        },
        headerTextUnselected: {
            fontFamily: fontFamily,
            color: colour.med,
            fontWeight: '500',
            fontSize: currenWidth < 1500 ? '1.125em' : '1.375em',
            cursor: 'pointer',
            textAlign: 'center'
        }
    }

    const headers = [
        {
            title: "Nuovo Ordine da Processare"
        },
        {
            title: "Ordine in Attesa di Pagamento"
        },
        {
            title: "In Lavorazione"
        },
        {
            title: currentDelivery === true ? "Ordini Completi da Spedire" : "Ordini Completi da Ritirare"
        },
        {
            title: "Ordini Archiviati"
        }
    ]

    return (
        <div className="internalSiteSkeleton">
            {
                currentGiftCard === true ?
                <InternalSiteGiftCard />
                :
                <div>
                    <div className="internalSiteSkeletonWidth">
                        {
                            headers.map((item, index) =>
                                <div
                                    className={currentInternalSiteIndex === index ? "internalSiteSkeletonTitleContainerSelected" : "internalSiteSkeletonTitleContainer"}
                                    onClick={() => dispatch(internalSiteIndex(index))}
                                >
                                    <h1
                                        title="Premi Le Freccette"
                                        className="internalSiteSkeletonHover" 
                                        style={currentInternalSiteIndex === index ? styles.headerText : styles.headerTextUnselected}
                                    >
                                        {item.title}
                                    </h1>
                                </div>
                            )
                        }
                    </div>
                    <InternalSiteBody />
                </div>
            }
        </div>
    )
}

export default InternalSiteSkeleton;