import React from 'react';
import { colour, fontFamily } from './GlobalStyles';
import { useSelector, useDispatch } from 'react-redux';
import { internalSiteDelivery, internalSiteGiftCard } from './actions';
import './InternalSite.css';

function InternalSiteSubHeader() {

    const currentDelivery = useSelector(state => state.internalSiteDelivery);
    const currentGiftCard = useSelector(state => state.internalSiteGiftCard);
    const currentWidth = useSelector(state => state.width);
    const dispatch = useDispatch();

    const styles = {
        activeText: {
            fontFamily: fontFamily,
            color: colour.primary,
            fontSize: '1.375em',
            fontWeight: '500',
            cursor: 'pointer'
        },
        unactiveText: {
            fontFamily: fontFamily,
            color: colour.med,
            fontSize: '1.375em',
            fontWeight: '500',
            cursor: 'pointer'
        }
    }

    function delivery() {
        dispatch(internalSiteDelivery(true));
        dispatch(internalSiteGiftCard(false));
    }

    function pickup() {
        dispatch(internalSiteDelivery(false));
        dispatch(internalSiteGiftCard(false));
    }

    function giftCard() {
        dispatch(internalSiteDelivery(false));
        dispatch(internalSiteGiftCard(true));
    }

    return (
        <div className="internalSiteSubHeaderOuterContainer">
            <div className="internalSiteSubHeaderWidth">
                <div className={currentWidth < 1500 ? "internalSiteSubHeaderInnerContainerSmall" : "internalSiteSubHeaderInnerContainer"}>
                    <p
                        title="Premi Tasto 1"
                        className={currentDelivery === true ? "internalSiteSubHeaderSelectedText" : "internalSiteSubHeaderText"}
                        onClick={() => delivery()}
                        style={currentDelivery === true ? styles.activeText : styles.unactiveText} 
                    >
                        Consegne a domicilio
                    </p>
                    <p
                        title="Premi Tasto 2"
                        className={currentDelivery === false && currentGiftCard === false ? "internalSiteSubHeaderSelectedText" : "internalSiteSubHeaderText"}
                        onClick={() => pickup()}
                        style={currentDelivery === false && currentGiftCard === false ? styles.activeText : styles.unactiveText}
                    >
                        Ritiro in Negozio
                    </p>
                    <p
                        title="Premi Tasto 3"
                        className={currentGiftCard === true ? "internalSiteSubHeaderSelectedText" : "internalSiteSubHeaderText"}
                        onClick={() => giftCard()}
                        style={currentGiftCard === true ? styles.activeText : styles.unactiveText}
                    >
                        Gift Card
                    </p>
                </div>
                {/* <InternalSiteSaveButton
                    onClick={() => saveChanges()}
                >
                    Salva Modifiche
                </InternalSiteSaveButton> */}
            </div>
        </div>
    )
}

export default InternalSiteSubHeader;