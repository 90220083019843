import React from 'react';
import { Input, DesktopInput, fontFamily, colour } from './GlobalStyles';
import { useSelector, useDispatch } from 'react-redux';
import { emailAction } from './actions';
import StripeCheckout from './StripeCheckout';
import { isMobile } from 'react-device-detect';
import PayPalPayment from './PayPalPayment';


const GiftCardEmail = React.forwardRef((props, ref) => {

    const currentWidth = useSelector(state => state.width);
    const currentEmail = useSelector(state => state.emailAction);
    const dispatch = useDispatch();

    const styles = {
        subheader: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontSize: '1.125em',
            fontWeight: 'bold'
        },
        back: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontSize: '0.875em',
            fontWeight: 'normal',
            textAlign: 'center',
            cursor: 'pointer'
        }
    }

    return (
        <div className="giftCardInfoOuterContainer">
            <div className="giftCardInfoInnerContainer">
                <div>
                    <div className={isMobile === true ? "mobilePriceSubheader" : "subheader"}>
                        <h2 style={styles.subheader}>
                            Inserisci la tua mail
                        </h2>
                    </div>
                    <div>
                        {
                            currentWidth < 900 ?
                            <Input 
                                value={currentEmail}
                                id="outlined"
                                label="La tua Mail"
                                inputProps={{
                                    autoCapitalize: 'none'
                                }}
                                variant="outlined"
                                onChange={(text) => dispatch(emailAction(text.target.value))}
                            />
                            :
                            <DesktopInput 
                                value={currentEmail}
                                id="outlined"
                                label="La tua Mail"
                                inputProps={{
                                    autoCapitalize: 'none',
                                    
                                }}
                                variant="outlined"
                                onChange={(text) => dispatch(emailAction(text.target.value))}
                            />
                        }
                    </div>
                    <div style={{ height: '2vh' }} />
                    <div className={currentWidth < 900 ? "paymentContainerMobile" : "paymentContainerGift"}>
                        <StripeCheckout 
                            page="gift"
                        />
                        <div style={{ height: '2vh' }} />
                        <PayPalPayment 
                            page="gift"
                        />
                    </div>
                    <div style={{ height: '2vh' }} />
                    <p
                        style={styles.back}
                        onClick={() => props.onBack()}
                    >
                        Indietro
                    </p>
                </div>
            </div>
        </div>
    )
})

export default GiftCardEmail;