import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { giftCardHolders, focus, recipientEmail, recipientName, price, buttonDisabled } from './actions';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import './InternalSiteGiftCard.css';
import { fontFamily, colour, DesktopFeeInput, DesktopShortInput, DesktopLongFeeInput, HeaderButton } from './GlobalStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import moment from 'moment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Loading from './Loading'; 
import { companyName } from './ShopData';

function InternalSiteGiftCard() {

    const currentGiftCardHolders = useSelector(state => state.giftCardHolders);
    const currentShopName = useSelector(state => state.shopName);
    const currentWidth = useSelector(state => state.width);
    const currentRecipientEmail = useSelector(state => state.recipientEmail);
    const currentRecipientName = useSelector(state => state.recipientName);
    const currentPrice = useSelector(state => state.price);
    const currentButtonDisabled = useSelector(state => state.buttonDisabled);
    const currentShopTitle = useSelector(state => state.shopTitle);
    const currentBookShopAddress = useSelector(state => state.bookShopAddress);
    const currentBookShopPhoneNumber = useSelector(state => state.bookShopPhoneNumber);
    const currentBookShopEmail = useSelector(state => state.bookShopEmail);
    const dispatch = useDispatch();

    useEffect(() => {

        firebase.firestore().collection("Gift Card Holders").doc(currentShopName)
        .onSnapshot(snapshot => {
            if (!snapshot.exists) {
                console.log('No such document!');
            }
            else {
                const data = snapshot.data();
                const holders = data.holders;
                dispatch(giftCardHolders(holders));
            }
        })

    }, [currentShopName, dispatch])

    function setCustomPrice(text, item, index) {
        console.log("new price =", text.target.value);
        const cloneArray = currentGiftCardHolders.slice();
        cloneArray[index].changedPrice = text.target.value;
        cloneArray[index].change = true;
        dispatch(giftCardHolders(cloneArray));
    }

    function submitPrice(item, index) {
        if ( item.changedPrice === undefined )
        return;
        else if ( item.changedPrice === "" )
        return;
        else if ( item.changedPrice === 0 )
        return;
 
        const db = firebase.firestore();
        const customerDataRef = db.collection("Gift Card Holders").doc(currentShopName);
    
        db.runTransaction((transaction) => {
            return transaction.get(customerDataRef).then((doc) => {
                if (!doc.exists) {
                    return
                }
                else {
                    const data = doc.data();
                    const holders = data.holders;
                    const dataIndex = holders.findIndex(e => e.code === item.code);
                    holders[dataIndex].price = Number(item.changedPrice);

                    transaction.update(customerDataRef, 
                        { 
                            holders
                        });
                        return holders;
                }
            })
        }).then(() => {
            console.log("Promise compiled with success");
        })
        .catch((err) => {
            console.log("Transaction Error =", err);
        })
    }

    function makeId(length) {
        dispatch(buttonDisabled(true));
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return newGiftcard(result);
    }

    function newGiftcard(result) {

        const db = firebase.firestore();

        db.collection("Gift Card Email").doc(result).set({
            recipientEmail: currentRecipientEmail,
            recipientName: currentRecipientName,
            giftMessage: "",
            giftCardDate: new Date(),
            senderEmail: currentBookShopEmail,
            price: Number(currentPrice),
            shopName: currentShopTitle,
            shopAddress: currentBookShopAddress,
            bookShopPhoneNumber: currentBookShopPhoneNumber,
            companyName: companyName,
            bookShopEmail: currentBookShopEmail,
            code: result
          })
          .then(() => {
              console.log("New gift card made");
            const customerDataRef = db.collection("Gift Card Holders").doc(currentShopName);
    
            db.runTransaction((transaction) => {
                return transaction.get(customerDataRef).then((doc) => {
                    if (!doc.exists) {
    
                        const holders = [];
                        
                        const newHolder = {
                            price: Number(currentPrice),
                            recipientEmail: currentRecipientEmail,
                            recipientName: currentRecipientName,
                            date: new Date(),
                            code: result
                        }
    
                        holders.push(newHolder);
    
                        db.collection("Gift Card Holders").doc(currentShopName).set({
                            holders
                        })
    
                        return holders;
                    }
                    else {
                        const data = doc.data();
                        const holders = data.holders;

                        const dataIndex = holders.findIndex(e => e.code === result);

                        if ( dataIndex !== -1 ) {
                            dispatch(buttonDisabled(false));
                            dispatch(recipientEmail(""));
                            dispatch(recipientName(""));
                            dispatch(price(0));
                            return;
                        }
                        else {
                            const newHolder = {
                                price: Number(currentPrice),
                                recipientEmail: currentRecipientEmail,
                                recipientName: currentRecipientName,
                                date: new Date(),
                                code: result
                            }
        
                            holders.push(newHolder);
        
                            transaction.update(customerDataRef, 
                                { 
                                    holders
                                });
                                return holders;
                        }
                    }
                })
            }).then(() => {
                console.log("Promise compiled with success");
                dispatch(buttonDisabled(false));
                dispatch(recipientEmail(""));
                dispatch(recipientName(""));
                dispatch(price(0));
            })
            .catch((err) => {
                console.log("Transaction Error =", err);
            })
          })
    }

    const styles = {
        subheaders: {
            fontFamily: fontFamily,
            color: colour.med,
            fontWeight: 'normal',
            fontSize: currentWidth < 1500 ? '1.125em' : '1.375em',
            textAlign: 'center'
        },
        data: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontWeight: 'normal',
            fontSize: currentWidth < 1500 ? '1em' : '1.125em',
            textAlign: 'center'
        },
        email: {
            fontFamily: fontFamily,
            color: colour.blue,
            fontWeight: 'center',
            fontSize: currentWidth < 1500 ? '1em' : '1.125em',
            textAlign: 'center',
            textDecorationLine: 'underline',
            cursor: 'pointer'
        },
        newCard: {
            fontFamily: fontFamily,
            color: colour.med,
            fontWeight: 'bold',
            fontSize: currentWidth < 1500 ? '1.125em' : '1.375em',
            textAlign: 'center'
        }
    }

    return (
        <div className="internalSiteGiftCardRow">
            <Loading />
            <div className="internalSiteGiftCardOuterContainer">
                <div className="internalSiteGiftCardGrid">
                    <h1 style={styles.subheaders}>
                        Data
                    </h1>
                    <h1 style={styles.subheaders}>
                        Nome
                    </h1>
                    <h1 style={styles.subheaders}>
                        Mail
                    </h1>
                    <h1 style={styles.subheaders}>
                        Codice
                    </h1>
                    <h1 style={styles.subheaders}>
                        Valore
                    </h1>
                    <h1 style={styles.subheaders}>
                        Aggiorna Valore
                    </h1>
                </div>
                {
                    currentGiftCardHolders.map((item, index) =>
                        <div className="internalSiteGiftCardElement">
                            <div className="internalSiteGiftCardElementGrid">
                                <div className="internalSiteGiftCardElementContainer">
                                    <p style={styles.data}>
                                        {moment.unix(item.date.seconds).format("DD/MM/YYYY")}
                                    </p>
                                </div>
                                <div className="internalSiteGiftCardElementContainer">
                                    <p style={styles.data}>
                                        {item.recipientName}
                                    </p>
                                </div>
                                <div className="internalSiteGiftCardElementContainer">
                                    <a
                                        href={`mailto:${item.recipientEmail}`} 
                                        style={styles.email}
                                    >
                                        {item.recipientEmail}
                                    </a>
                                </div>
                                <div className="internalSiteGiftCardElementContainer">
                                    <p style={styles.data}>
                                        {item.code}
                                    </p>
                                </div>
                                <div className="internalSiteGiftCardElementContainer">
                                    <p style={styles.data}>
                                        € {
                                            typeof item.price === 'number' ?
                                            item.price.toFixed(2)
                                            :
                                            item.price
                                            }
                                        {
                                            typeof item.price !== 'number' ?
                                            null
                                            :
                                            Number.isInteger(item.price.toFixed(2)) === true ? ".00"
                                            :
                                            Math.floor(item.price.toFixed(2)) !== item.price ? 
                                                (item.price.toFixed(2).toString().split(".")[1].length || 0) === 2 ?
                                                null
                                                :
                                                Number.isInteger(item.price.toFixed(2)) === true ? ".00" : '0'
                                            :
                                            null
                                        }
                                    </p>
                                </div>
                                <div className="internalSiteGiftCardElementContainerRow">
                                    <DesktopFeeInput 
                                        value={item.changedPrice === undefined && typeof item.price === 'number'  ? item.price.toFixed(2) : item.changedPrice === undefined && typeof item.price !== 'number' ? item.price :  typeof item.changedPrice === 'string' ? item.changedPrice : item.changedPrice.toFixed(2)}
                                        id="outlined"
                                        label=""
                                        inputProps={{
                                            autoCapitalize: 'none',
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">€</InputAdornment>
                                        }}
                                        variant="outlined"
                                        onKeyDown={(e) => e.key === 'Enter' && item.change === true ? submitPrice(item, index) : null}
                                        onFocus={() => dispatch(focus(true))}
                                        onBlur={() => dispatch(focus(false))}
                                        onChange={(text) => isNaN(Number(text.target.value)) ? null : setCustomPrice(text, item, index)}
                                    />
                                    <CheckCircleIcon
                                        style={{
                                                fontSize: 30,
                                                color: item.price === 0 ? colour.primary : item.change === true ? "#F8DB02" : colour.green, 
                                                cursor: item.change === true ? 'pointer' : 'auto'
                                            }}
                                        className={item.change === true ? "internalSiteTickHover" : "" }
                                        onClick={() => item.change !== true ? null : submitPrice(item, index)}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            <div style={{ width: '3vw' }} />
            <div className="internalSiteNewGiftCard">
                <h1 style={styles.newCard}>
                    Nuova Gift Card
                </h1>
                <div style={{ height: '2vh' }} />
                <DesktopShortInput
                    value={currentRecipientName}
                    id="outlined"
                    label="Nome"
                    inputProps={{
                        autoCapitalize: 'none',
                    }}
                    variant="outlined"
                    onFocus={() => dispatch(focus(true))}
                    onBlur={() => dispatch(focus(false))}
                    onChange={(text) => dispatch(recipientName(text.target.value))}
                />
                <div style={{ height: '1vh' }} />
                <DesktopShortInput
                    value={currentRecipientEmail}
                    id="outlined"
                    label="Email"
                    inputProps={{
                        autoCapitalize: 'none',
                    }}
                    variant="outlined"
                    onFocus={() => dispatch(focus(true))}
                    onBlur={() => dispatch(focus(false))}
                    onChange={(text) => dispatch(recipientEmail(text.target.value))}
                />
                <div style={{ height: '1vh' }} />
                <DesktopLongFeeInput
                    value={currentPrice}
                    id="outlined"
                    label=""
                    inputProps={{
                        autoCapitalize: 'none'
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>
                    }}
                    variant="outlined"
                    onFocus={() => dispatch(focus(true))}
                    onBlur={() => dispatch(focus(false))}
                    onChange={(text) => isNaN(Number(text.target.value)) ? null : dispatch(price(text.target.value))}
                />
                <div style={{ height: '1vh' }} />
                <HeaderButton
                    disabled={
                                currentRecipientEmail === "" ? 
                                true
                                :
                                currentRecipientName === "" ?
                                true
                                :
                                currentPrice === 0 || currentPrice === "" ?
                                true
                                :
                                currentButtonDisabled === true ?
                                true
                                :
                                false
                            }
                    onClick={() => makeId(8)}
                >
                    + Nuova Gift Card
                </HeaderButton>
            </div>
        </div>
    )
}

export default InternalSiteGiftCard;