import React, { useEffect, useCallback } from 'react';
import './Header.css';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { shopName, bookShopAddress } from './actions';
import { HeaderButton, fontFamily, colour } from './GlobalStyles';
import { useSelector } from 'react-redux';
import { navActive, bookShopPhoneNumber, bookShopEmail } from './actions';

function Header() {

    const currentWidth = useSelector(state => state.width);
    const currentNavActive = useSelector(state => state.navActive);
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, []);
  
    useEffect(() => {

        function checkShop() {
            const url = window.location.href;
            url.includes("local") === true ? 
            stableDispatch(shopName("local")) &&
            stableDispatch(bookShopAddress("Via Mazzini, 48\n26013 Crema CR")) &&
            stableDispatch(bookShopPhoneNumber("+39 0373 80379")) &&
            stableDispatch(bookShopEmail("mondadoricremaonline@gmail.com"))
            :
            url.includes("crema") === true ? 
            stableDispatch(shopName("crema")) &&
            stableDispatch(bookShopAddress("Via Mazzini, 48\n26013 Crema CR")) &&
            stableDispatch(bookShopPhoneNumber("+39 0373 80379")) &&
            stableDispatch(bookShopEmail("mondadoricremaonline@gmail.com"))
            :
            url.includes("varazze") === true ? 
            stableDispatch(shopName("varazze")) &&
            stableDispatch(bookShopAddress("Piazza Nello Bovani, 10\n17019 Varazze SV")) &&
            stableDispatch(bookShopPhoneNumber("+39 019 98051")) &&
            stableDispatch(bookShopEmail("mondadorivarazzeonline@gmail.com"))
            :
            url.includes("trento") === true ? 
            stableDispatch(shopName("trento")) &&
            stableDispatch(bookShopAddress("Via S. Pietro, 19\n38122 Trento TN")) &&
            stableDispatch(bookShopPhoneNumber("+39 0461 263828")) &&
            stableDispatch(bookShopEmail("mondadoritrentoonline@gmail.com"))
            :
            url.includes("sestriponente") === true ? 
            stableDispatch(shopName("sestriponente")) &&
            stableDispatch(bookShopAddress("Via Sestri, 46\n16154 Genova GE")) &&
            stableDispatch(bookShopPhoneNumber("+39 010 653 3367")) &&
            stableDispatch(bookShopEmail("mondadorisestriponenteonline@gmail.com"))
            :
            url.includes("sestri") === true ? 
            stableDispatch(shopName("sestrilevante")) &&
            stableDispatch(bookShopAddress("Via XXV Aprile, 71/73\n16039 Sestri Levante GE")) &&
            stableDispatch(bookShopPhoneNumber("+39 0185 44855")) &&
            stableDispatch(bookShopEmail("mondadorisestrionline@gmail.com"))
            :
            url.includes("acqui") === true ? 
            stableDispatch(shopName("acqui")) &&
            stableDispatch(bookShopAddress("Corso Italia, 47/49\n15011 Acqui Terme AL")) &&
            stableDispatch(bookShopPhoneNumber("+39 0144 356961")) &&
            stableDispatch(bookShopEmail("mondadoriacquionline@gmail.com"))
            :
            url.includes("loano") === true ? 
            stableDispatch(shopName("loano")) &&
            stableDispatch(bookShopAddress("Via Garibaldi\n150, 17025 Loano SV")) &&
            stableDispatch(bookShopPhoneNumber("+39 019 675848")) &&
            stableDispatch(bookShopEmail("mondadoriloanoonline@gmail.com"))
            :
            stableDispatch(shopName("local")) &&
            stableDispatch(bookShopAddress("local")) &&
            stableDispatch(bookShopEmail("mondadoricremaonline@gmail.com"))
        }

        checkShop();
  
    }, [stableDispatch]);

    let history = useHistory();

    function navBarNavigation(path) {
        dispatch(navActive(false));
        history.push(path);
    }

    const styles = {
        text: {
            fontFamily: fontFamily,
            fontSize: '1.125em',
            fontWeight: 'normal',
            color: colour.dark,
            cursor: 'pointer'
        },
        navText: {
            cursor: 'pointer',
            fontFamily: fontFamily,
            fontSize: '1.125rem',
            fontWeight: 'bold',
            color: colour.dark,
            display: currentWidth < 900 && currentNavActive === false ? 'none' : 'flex'
        }
    }

    return (
        currentWidth > 900 ?
            <div className="header">
                <div className="headerRow">
                    <div>
                        <img
                            src={require('./Mondadori-logo.svg')}
                            className="logoMondadori"
                            alt="Libreria Mondadori"
                            onClick={() => history.push('../')}
                        />
                    </div>
                    <p 
                        onClick={() => history.push('../')}
                        style={styles.text}
                    >
                        Home
                    </p>
                    <p 
                        onClick={() => history.push('../18App')}
                        style={styles.text}
                    >
                        18App | Carta del Docente
                    </p>
                    <HeaderButton
                        onClick={() => history.push('../giftCard')}
                    >
                        Regala Gift Card
                    </HeaderButton>
                </div>
            </div>
            :
            <div className="headerMobile">
                <div className="headerMobile">
                    <img
                        src={require('./Mondadori-logo.svg')}
                        className="logoMondadoriMobile"
                        alt="Libreria Mondadori"
                        onClick={() => history.push('./')}
                    />
                     <div 
                        onClick={() => currentNavActive === false ? dispatch(navActive(true)) : dispatch(navActive(false))}
                        className={currentNavActive === false ? "menu-btn" : "menu-btn open"}
                    >
                        <div 
                            className="menu-btn__burger" 
                            onClick={() => currentNavActive === false ? dispatch(navActive(true)) : dispatch(navActive(false))}
                        />
                    </div>
                    <div className={currentNavActive === true ? "navModal navModalActive" : "navModal"}>
                        <div className={currentNavActive === false ? "menu-btn" : ""} />
                        <p
                            onClick={() => navBarNavigation('../')}
                            style={styles.navText}
                        >
                            Home
                        </p>
                        <p
                            onClick={() => navBarNavigation('../18App')}
                            style={styles.navText}
                        >
                            18App | Carta del Docente
                        </p>
                        <p
                            onClick={() => navBarNavigation('../giftCard')}
                            style={styles.navText}
                        >
                            Regala Gift Card
                        </p>
                    </div>
                </div>
            </div>
    )
}

export default Header;