import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { colour, fontFamily, DesktopDelayInput } from './GlobalStyles';
import { useSelector, useDispatch } from 'react-redux';
import { internalSiteData, focus } from './actions';
import InputBase from '@material-ui/core/InputBase';
import * as firebase from 'firebase/app';
import '@firebase/firestore';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: '1vh',
      color: colour.med,
      fontWeight: 'bold',
      '&:focus': {
        color: colour.primary
      }
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: colour.lightestGray,
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: fontFamily,
    '&:focus': {
      borderRadius: 4,
      borderColor: colour.primary,
      backgroundColor: colour.lightestGray
      //boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

function SelectStock({ customerItem, customerIndex, item, index }) {

    const currentShopName = useSelector(state => state.shopName);
    const currentInternalSiteData = useSelector(state => state.internalSiteData);
    const dispatch = useDispatch();

    const useStyles = makeStyles((theme) => ({
        formControl: {
          backgroundColor: colour.lightestGray,
          width: '10vw',
          fontFamily: fontFamily,
        }
      }));

    const classes = useStyles();
  
    const handleChange = (event) => {

        const stockClone = currentInternalSiteData.slice();
        stockClone[customerIndex].books[index].inStock = event.target.value === "delay" ? true : event.target.value;
        event.target.value === "delay" ?
        stockClone[customerIndex].books[index].delay = true
        :
        stockClone[customerIndex].books[index].delay = false;

        if ( event.target.value === false ) {
          stockClone[customerIndex].price = stockClone[customerIndex].price - stockClone[customerIndex].books[index].price;
          stockClone[customerIndex].books[index].price = 0;
          dispatch(internalSiteData(stockClone));
        }
        else {
          dispatch(internalSiteData(stockClone));
        }

        const db = firebase.firestore();
        const customerDataRef = db.collection("Test Customer Data").doc(currentShopName);
    
        db.runTransaction((transaction) => {
            return transaction.get(customerDataRef).then((doc) => {
                if (!doc.exists) {
                    return
                }
                else {
                    const data = doc.data();
                    const customerData = data.customerData;
                    const dataIndex = customerData.findIndex(e => e.code === customerItem.code);
                    const bookIndex = customerData[dataIndex].books.findIndex(e => e.title === item.title);
                    customerData[dataIndex].books[bookIndex].inStock = event.target.value === "delay" ? true : event.target.value;
                    event.target.value === "delay" ?
                    customerData[dataIndex].books[bookIndex].delay = true
                    :
                    customerData[dataIndex].books[bookIndex].delay = false;

                    if ( event.target.value === false ) {
                      customerData[dataIndex].price = customerData[customerIndex].price - customerData[dataIndex].books[bookIndex].price;
                      customerData[dataIndex].books[bookIndex].price = 0;

                      transaction.update(customerDataRef, 
                        { 
                            customerData
                        });
                        return customerData;
                    }
                    else {
                      transaction.update(customerDataRef, 
                        { 
                            customerData
                        });
                        return customerData;
                    }
                }
            })
        }).then(() => {
            console.log("Promise compiled with success");
            submitCustomerOrder(event);
              
        })
        .catch((err) => {
            console.log("Transaction Error =", err);
        })
    };

    function submitCustomerOrder(event) {
      const db = firebase.firestore();
      const customerOrderRef = db.collection("Customer Orders").doc(customerItem.code);
  
      db.runTransaction((transaction) => {
        return transaction.get(customerOrderRef).then((doc) => {
            if (!doc.exists) {
                return
            }
            else {
                const data = doc.data();
               
                const bookIndex = data.books.findIndex(e => e.title === item.title);
                data.books[bookIndex].inStock = event.target.value === "delay" ? true : event.target.value;
                event.target.value === "delay" ?
                    data.books[bookIndex].delay = true
                    :
                    data.books[bookIndex].delay = false;

                if ( event.target.value === false ) {
                  data.price = data.price - data.books[bookIndex].price;
                  data.books[bookIndex].price = 0;
  
                  transaction.update(customerOrderRef, 
                    { 
                        price: data.price,
                        books: data.books
                    });
                    return data;
                }
                else {
                  transaction.update(customerOrderRef, 
                    { 
                        price: data.price,
                        books: data.books
                    });
                    return data;
                }
            }
        })
    }).then(() => {
          console.log("Promise compiled with success");
      })
      .catch((err) => {
          console.log("Transaction Error =", err);
      })
  }

  function setDelayDays(text) {
        const stockClone = currentInternalSiteData.slice();
        stockClone[customerIndex].books[index].delayDays = Number(text.target.value);
        stockClone[customerIndex].books[index].change = true;
        dispatch(internalSiteData(stockClone));
  }
  
    return (
        <div>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-helper-label" />
            <Select
              value={item.delay === true ? "delay" : item.inStock === true ? true : item.inStock === false ? false : null}
              displayEmpty
              onChange={handleChange}
              input={<BootstrapInput />}
            >
              <MenuItem 
                value={null}
              >
                Seleziona
              </MenuItem>
              <MenuItem 
                value={false}
              >
                Non Procurabile
              </MenuItem>
              <MenuItem 
                value="delay"
              >
                In Arrivo
              </MenuItem>
              <MenuItem 
                value={true}>
                Disponibile
              </MenuItem>
            </Select>
          </FormControl>
          <div style={{ height: '2vh' }} />
          {
            item.delay === true ?
            <DesktopDelayInput 
              value={item.delayDays !== undefined ? item.delayDays : 0}
              id="outlined"
              label="Giorni di Arrivo"
              inputProps={{
                  autoCapitalize: 'none',
              }}
              variant="outlined"
              onFocus={() => dispatch(focus(true))}
              onBlur={() => dispatch(focus(false))}
              onChange={(text) => isNaN(Number(text.target.value)) ? null : setDelayDays(text)}
            />
            :
            null
          }
          <div style={{ height: '2vh' }} />
        </div>
    )
}

export default SelectStock;