import React, { useEffect } from 'react';
import './GiftCard.css';
import Header from './Header';
import Footer from './Footer';
import { fontFamily, colour } from './GlobalStyles';
import GiftCardImage from './GiftCardImage';
import GiftCardInfo from './GiftCardInfo';
import GiftCardDate from './GiftCardDate';
import GiftCardPrice from './GiftCardPrice';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import GiftCardEmail from './GiftCardEmail';
import { isMobile } from 'react-device-detect';
import Loading from './Loading';
import { height, width } from './actions';
import { useDispatch } from 'react-redux';
import { shopName } from './ShopData';

function GiftCard() {

    const dispatch = useDispatch();

    useEffect(() => {
        function resizeWindow () {
            console.log("RESIZE GIFT")
            const newWidth = window.innerWidth;
            const newHeight = window.innerHeight;
            dispatch(width(newWidth));
            dispatch(height(newHeight));
      };
      window.addEventListener("resize", resizeWindow);
      return () => window.removeEventListener("resize", resizeWindow);
    })

    function slideForward() {
        const mySwiper = document.querySelector('.swiper-container').swiper;
        window.scrollTo(0, 0);
        mySwiper.slideNext();
    }

    function slideBack() {
        const mySwiper = document.querySelector('.swiper-container').swiper;
        window.scrollTo(0, 0);
        mySwiper.slidePrev();
    }

    const styles = {
        header: {
            position: 'fixed',
            zIndex: 10
        },
        headerText: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontSize: isMobile === true ? '1.375em' : 30,
            fontWeight: 'bold',
            textAlign: 'center'
        },
        titleContainer: {
            position: 'fixed',
            zIndex: 2,
            top: '5vh',
            backgroundColor: '#FFFFFF',
            width: '100vw',
            paddingTop: '5vh',
            paddingBottom: '1vh'
        },
        warning: {
            fontFamily: fontFamily,
            color: colour.primary,
            fontSize: '1.125em',
            fontWeight: '500',
            textAlign: 'center'
        }
    }

    return (
        <div className="giftCardOuterContainer">
            <div style={styles.header}>
                <Header />
            </div>
            <Loading />
            <div style={styles.titleContainer}>
                <h1 style={styles.headerText}>
                    Regala una Gift Card
                </h1>
                <p style={styles.warning}>
                    NB: Questa gift card è unicamente usufruibile
                    presso il Mondadori Bookstore di {shopName}
                </p>
                <GiftCardImage />
            </div>
            <div>
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    allowTouchMove={false}
                    pagination={{ clickable: false, draggable: false }}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log('slide change')}
                >
                    <SwiperSlide>
                        <GiftCardInfo 
                            onClick={slideForward}
                            onBack={slideBack}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <GiftCardDate 
                            onClick={slideForward}
                            onBack={slideBack}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <GiftCardPrice 
                            onClick={slideForward}
                            onBack={slideBack}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <GiftCardEmail 
                            onClick={slideForward}
                            onBack={slideBack}
                        />
                    </SwiperSlide>
                </Swiper>
            </div>
            <Footer />
        </div>
    )
}

export default GiftCard;