import React from 'react';
import PaypalExpressBtn from 'react-paypal-express-checkout';
import { useSelector, useDispatch } from 'react-redux';
import { buttonDisabled } from './actions';
import { companyName } from './ShopData';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { useHistory } from 'react-router-dom';

const brevaPayPal = "AZ-_0wPPp9neDsH_Ywap5dSzhoMlxSUPMu_LqHltuDeFJYTDZBHoctGY29Dc5tc206OsokUQKipiRH6o";
const grisoPayPal = "AbIkvLF3jkitkrJfr3ndYdoLdDMxueMa4Lf1InSivZ6NnxA5AcrZE3VMnSDHJJY7KJFM3-UPg5j5xrJO";

const client = {
    sandbox:    "access_token$sandbox$33g843bqr5b4xjqn$d50f979fe69f32c007cf35d0cdf2789b",
    production: companyName === "Breva srl" ? brevaPayPal : grisoPayPal,
  }
  
function PayPalPayment({ page, customer }) {

    const currentPaymentOrder = useSelector(state => state.paymentOrder);
    const currentPrice = useSelector(state => state.price);
    const currentEmail = useSelector(state => state.emailAction);
    const currentBookShopEmail = useSelector(state => state.bookShopEmail);
    const currentBookShopAddress = useSelector(state => state.bookShopAddress);
    const currentBookShopPhoneNumber = useSelector(state => state.bookShopPhoneNumber);
    const currenntGiftCardDate = useSelector(state => state.giftCardDate);
    const currentGiftMessage = useSelector(state => state.giftMessage);
    const currentRecipientEmail = useSelector(state => state.recipientEmail);
    const currentRecipientName = useSelector(state => state.recipientName);
    const currentShopName = useSelector(state => state.shopName);
    const currentDateChanged = useSelector(state => state.dateChanged);
    const currentCashModal = useSelector(state => state.cashModal);
    const dispatch = useDispatch();
    let history = useHistory();

    const onSuccess = (payment) => {
        makeId(page === 'gift' ? 8 : 15);
    }

    const onCancel = (data) => {
        console.log('data =', data);
    }

    const onError = (err) => {
        console.log("Error =", err);
    }

    function makeId(length) {
        dispatch(buttonDisabled(true));
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return confirmOrder(result);
    }

    function confirmOrder(result) {

        const db = firebase.firestore()

        if ( page === 'gift' ) {

            const giftCardHolderRef = db.collection("Gift Card Holders").doc(currentShopName);

            db.runTransaction((transaction) => {
              return transaction.get(giftCardHolderRef).then((doc) => {
                  if (!doc.exists) {
                      
                    const holders = [];
                    const newHolder = {
                        recipientEmail: currentRecipientEmail,
                        recipientName: currentRecipientName,
                        giftMessage: currentGiftMessage,
                        giftCardDate: currenntGiftCardDate,
                        senderEmail: currentEmail,
                        price: currentPrice,
                        shopName: currentShopName,
                        shopAddress: currentBookShopAddress,
                        bookShopPhoneNumber: currentBookShopPhoneNumber,
                        companyName: companyName,
                        bookShopEmail: currentBookShopEmail,
                        date: new Date(),
                        code: result
                    }

                    holders.push(newHolder);

                    db.collection("Gift Card Holders").doc(currentShopName).set({
                        holders
                    })
                  }
                  else {
                    const data = doc.data();
                    const holders = data.holders;

                    const newHolder = {
                        recipientEmail: currentRecipientEmail,
                        recipientName: currentRecipientName,
                        giftMessage: currentGiftMessage,
                        giftCardDate: currenntGiftCardDate,
                        senderEmail: currentEmail,
                        price: currentPrice,
                        shopName: currentShopName,
                        shopAddress: currentBookShopAddress,
                        bookShopPhoneNumber: currentBookShopPhoneNumber,
                        companyName: companyName,
                        bookShopEmail: currentBookShopEmail,
                        date: new Date(),
                        code: result
                    }

                    holders.push(newHolder);

                    transaction.update(giftCardHolderRef, 
                      { 
                        holders
                      });
                      return holders;
                  }
                })
              })


            const giftEmailCollection = currentDateChanged === true ? "Gift Card Email Delay" : "Gift Card Email";
            db.collection(giftEmailCollection).doc(result).set({
                recipientEmail: currentRecipientEmail,
                recipientName: currentRecipientName,
                giftMessage: currentGiftMessage,
                giftCardDate: currenntGiftCardDate,
                senderEmail: currentEmail,
                price: currentPrice,
                shopName: currentShopName,
                shopAddress: currentBookShopAddress,
                bookShopPhoneNumber: currentBookShopPhoneNumber,
                companyName: companyName,
                bookShopEmail: currentBookShopEmail,
                code: result
              })
              .then(() => {
                history.push('../pagamentoCompleto');
                dispatch(buttonDisabled(false));
              })
        }
        else {
            const customerDataRef = db.collection("Test Customer Data").doc(currentShopName);

            db.collection("Customer Orders").doc(currentPaymentOrder.code).update({
                payment: true
            })

            db.runTransaction((transaction) => {
             return transaction.get(customerDataRef).then((doc) => {
                 if (!doc.exists) {
                     return
                 }
                 else {
                   const data = doc.data();
                   const customerData = data.customerData;
                   const dataIndex = customerData.findIndex(e => e.code === currentPaymentOrder.code);
                   customerData[dataIndex].payment = true;
                   customerData[dataIndex].awaitingPayment = false;
                   customerData[dataIndex].awaitingCompletion = true;
 
                   transaction.update(customerDataRef, 
                     { 
                         customerData
                     });
                     return customerData;
               }
             })
           })
           .then(() => {
                history.push('../pagamentoCompleto');
                dispatch(buttonDisabled(false));
           })
        }
    }

    const styles = {
        button: {
            display: 'none'
        },
        standard: {
            
        }
    }

    return (
        <div style={
                        currentCashModal === true ? 
                        styles.button
                        :
                        page === 'gift' && currentEmail === "" ?
                        styles.button
                        :
                        styles.standard
                    }>
            <PaypalExpressBtn 
                env={'production'}
                client={client} 
                currency={'EUR'} 
                total={
                        page === 'gift' ? currentPrice
                        :
                        currentPaymentOrder.length === 0 ? 
                        0 
                        :  
                        currentPaymentOrder.delivery === false ?
                        currentPaymentOrder.price
                        : 
                        currentPaymentOrder.deliveryFee !== undefined ?
                        currentPaymentOrder.price + currentPaymentOrder.deliveryFee
                        :
                        currentPaymentOrder.price < 19 ? 
                        currentPaymentOrder.price + 1.5 
                        : 
                        currentPaymentOrder.price
                    } 
                onSuccess={onSuccess}
                onCancel={onCancel}
                onError={onError}
                style={{ size: 'responsive', tagline: false }}
            />
        </div>
    )
}

export default PayPalPayment;