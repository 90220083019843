import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { fontFamily, colour, ActionButton, DesktopActionButton } from './GlobalStyles';
import './Success.css';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Success() {

    const currentCashPayment = useSelector(state => state.cashPayment);
    const currentEighteenAppPayment = useSelector(state => state.eighteenAppPayment);
    const currentCartaDocentePayment = useSelector(state => state.cartaDocentePayment);
    let history = useHistory();

    const styles = {
        headerText: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontSize: 30,
            fontWeight: '500',
            textAlign: 'center'
        }
    }

    return (
        <div>
            <Header />
            <div className="successContainer">
                <h1 style={styles.headerText}>
                    {
                        currentCashPayment === true ? 
                        "Richiesta confermata. Il pagamento sarà effettuato in contanti"
                        :
                        currentEighteenAppPayment === true ?
                        "Richiesta confermata. Il tuo codice 18App è stato inviato alla libreria"
                        :
                        currentCartaDocentePayment === true ?
                        "Richiesta confermata. Il tuo codice Carta del Docente è stato inviato alla libreria"
                        :
                        "Il Pagamento è andato a buon fine!"
                    }
                </h1>
                <div style={{ height: '10vh' }} />
                {
                    isMobile === true ?
                    <ActionButton
                        onClick={() => history.push('../')}
                    >
                        Torna alla Home
                    </ActionButton>
                    :
                    <DesktopActionButton
                        onClick={() => history.push('../')}
                    >
                        Torna alla Home
                    </DesktopActionButton>
                }
            </div>
            <Footer />
        </div>
    )
}

export default Success;