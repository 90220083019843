import React from 'react';

function FurtherInfo() {

    return (
        <div>

        </div>
    )
}

export default FurtherInfo;