import React from 'react';
import './IllustrationPage.css';
import { useSelector } from 'react-redux';

function IllustrationPage({ source }) {

    const currentBookPage = useSelector(state => state.bookPage);

    return (
        <div className="bookIllustrationOuterMostContainer">
            <div className="bookIllustrationContainer">
                <img
                    src={source}
                    className={currentBookPage === 10 ? "smallBookIllustration" : "bookIllustration"}
                    alt="Illustrazione"
                />
            </div>
        </div>
    )
}

export default IllustrationPage;