import React from 'react';
import { fontFamily, colour, DesktopInput, DesktopActionButton, SmallDesktopInput, NumberInput } from './GlobalStyles';
import { useSelector, useDispatch } from 'react-redux';
import { bookTitle, author, editor, notes, books, quantity } from './actions';

const ChooseBook = React.forwardRef((props, ref) => {

    const currentBookTitle = useSelector(state => state.bookTitle);
    const currentAuthor = useSelector(state => state.author);
    const currentEditor = useSelector(state => state.editor);
    const currentNotes = useSelector(state => state.notes);
    const currentBooks = useSelector(state => state.books);
    const currentQuantity = useSelector(state => state.quantity);
    const dispatch = useDispatch();

    function selectBook() {

        const dataIndex = currentBooks.findIndex(e => e.title === currentBookTitle);

        if ( dataIndex === -1 ) {
            const newBook = {
                title: currentBookTitle,
                author: currentAuthor,
                editor: currentEditor,
                notes: currentNotes,
                inStock: null,
                delay: false,
                delayDays: 0,
                price: 0,
                change: false,
                quantity: currentQuantity
            };
            currentBooks.push(newBook);
            dispatch(books(currentBooks));
            props.onClick();
            dispatch(bookTitle(""));
            dispatch(author(""));
            dispatch(editor(""));
            dispatch(notes(""));
        }
        else {
            alert("Ooppss...Non puoi avere due volumi con lo stesso titolo!");
        }
    }

    const styles = {
        headerText: {
            fontFamily: fontFamily, 
            color: colour.dark,
            fontWeight: 'normal',
            fontSize: '1.375em',
            textAlign: 'center'
        }
    }

    return (
        <div className="bookPageOuterMostContainer">
            <div className="bookPageContainer">
                <h1 style={styles.headerText}>
                    Scegli il tuo libro
                </h1>
                <div style={{ height: '5vh' }} />
                <form>
                    <div className="chooseBookInputRow">
                        <SmallDesktopInput
                            value={currentBookTitle}
                            id="outlined"
                            label="Titolo"
                            inputProps={{
                                autoCapitalize: 'none',
                            }}
                            variant="outlined"
                            onChange={(text) => dispatch(bookTitle(text.target.value))}
                        />
                         <NumberInput
                            value={currentQuantity}
                            id="outlined"
                            label="Quantità"
                            inputProps={{
                                autoCapitalize: 'none',
                            }}
                            variant="outlined"
                            onChange={(text) => text.target.value === "" ? dispatch(quantity(text.target.value)) : Number(text.target.value) === 0 ? null : isNaN(Number(text.target.value)) ? null : dispatch(quantity(text.target.value))}
                        />
                    </div>
                    <div style={{ height: '2vh' }} />
                    <DesktopInput
                        value={currentAuthor}
                        id="outlined"
                        label="Autore"
                        inputProps={{
                            autoCapitalize: 'none',
                        }}
                        variant="outlined"
                        onChange={(text) => dispatch(author(text.target.value))}
                    />
                    <div style={{ height: '2vh' }} />
                    <DesktopInput
                        value={currentEditor}
                        id="outlined"
                        label="Editore"
                        inputProps={{
                            autoCapitalize: 'none',
                        }}
                        variant="outlined"
                        onChange={(text) => dispatch(editor(text.target.value))}
                    />
                    <div style={{ height: '2vh' }} />
                    <DesktopInput
                        value={currentNotes}
                        id="outlined"
                        label="Note"
                        inputProps={{
                            autoCapitalize: 'none',
                        }}
                        variant="outlined"
                        onChange={(text) => dispatch(notes(text.target.value))}
                    />
                    <div style={{ height: '2vh' }} />
                    <DesktopActionButton
                        onClick={() => selectBook()}
                        disabled={
                                    currentBookTitle === "" ?
                                    true
                                    :
                                    currentAuthor === "" ?
                                    true
                                    :
                                    false
                                }
                    >
                        Invia
                    </DesktopActionButton>
                </form>
            </div>
        </div>
    )
})

export default ChooseBook;