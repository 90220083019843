import React, { useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useSelector, useDispatch } from 'react-redux';
import { fontFamily, colour, ActionButton, DesktopActionButton } from './GlobalStyles';
import { giftCardDate, dateChanged } from './actions';
import { isMobile } from 'react-device-detect';
import moment from 'moment';

const GiftCardDate = React.forwardRef((props, ref) => {

    const currentGiftCardDate = useSelector(state => state.giftCardDate);
    const currentWidth = useSelector(state => state.width);
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    function changeDate(date) {

        if ( moment(date).format("DD/MM/YYYY") !== moment(new Date()).format("DD/MM/YYYY") ) {
            dispatch(dateChanged(true));
            dispatch(giftCardDate(date));
        }
        else {
            dispatch(dateChanged(false));
            dispatch(giftCardDate(date))
        }
    }

    const styles = {
        subheader: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontSize: '1.125em',
            fontWeight: 'bold'
        },
        back: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontSize: '0.875em',
            fontWeight: 'normal',
            textAlign: 'center',
            cursor: 'pointer'
        }
    }

    return (
        <div className="giftCardInfoOuterContainer">
            <div className="giftCardInfoInnerContainer">
                <div>
                    <div className={isMobile === true ? "mobileDateSubheader" : "dateSubheader"}>
                        <h2 style={styles.subheader}>
                            Data di Spedizione
                        </h2>
                    </div>
                    <div style={{ height: '2vh' }} />
                    <div className="giftCardCentre">
                        <Calendar
                            onChange={(date) => changeDate(date)}
                            value={currentGiftCardDate}
                            locale="it-IT"
                            className={
                                        isMobile === true ?
                                        "mobileReactCalendarStyle"
                                        :
                                        currentWidth < 900 ?
                                        "smallReactCalendarStyle"
                                        :
                                        "reactCalendarStyle"
                                    }
                            minDate={new Date()}
                        />
                    </div>
                    <div style={{ height: '2vh' }} />
                    <div className="giftCardCentre">
                        {
                            isMobile === true ?
                            <ActionButton
                                onClick={() => props.onClick()}
                            >
                                Continua
                            </ActionButton>
                            :
                            currentWidth < 900 ?
                            <ActionButton
                                onClick={() => props.onClick()}
                            >
                                Continua
                            </ActionButton>
                            :
                            <DesktopActionButton
                                onClick={() => props.onClick()}
                            >
                                Continua
                            </DesktopActionButton>
                        }
                        <div style={{ height: '2vh' }} />
                        <p
                            style={styles.back}
                            onClick={() => props.onBack()}
                        >
                            Indietro
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default GiftCardDate;