import React from 'react';
import { BlackButton, DesktopBlackButton } from './GlobalStyles';
import CashModal from './CashModal';
import { useDispatch, useSelector } from 'react-redux';
import { cashModal } from './actions';

function CashPayment({ customer }) {

    const currentWidth = useSelector(state => state.width);
    const dispatch = useDispatch();

    function openCashModal() {
        dispatch(cashModal(true));
    }

    return (
        <div>
            <CashModal
                customer={customer}
            />
            {
                currentWidth < 900 ?
                <BlackButton
                    onClick={() => openCashModal()}
                >
                    Paga in Contanti
                </BlackButton>
                :
                <DesktopBlackButton
                    onClick={() => openCashModal()}
                >
                    Paga in Contanti
                </DesktopBlackButton>
            }
    </div>
    )
}

export default CashPayment;