import React from 'react';
import { fontFamily, colour, ActionButton } from './GlobalStyles';
import './DeliveryMobile.css';
import { delivery } from './actions';
import { useSelector, useDispatch } from 'react-redux';

const DeliveryMobile = React.forwardRef((props, ref) => {

    const currentDelivery = useSelector(state => state.delivery);
    const dispatch = useDispatch();

    const deliveryOptions = [
        {
            title: "Consegna a Domicilio",
            type: "deliver"
        },
        {
            title: "Ritiro in Libreria",
            type: "pickup"
        }
    ]

    function setDeliveryOption(item) {
        item.type === 'deliver' ? dispatch(delivery(true)) : dispatch(delivery(false));
    }

    const styles = {
        headerText: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontWeight: 'nnormal',
            fontSize: '1.125em',
            textAlign: 'center'
        },
        back: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontSize: '0.875em',
            fontWeight: 'normal',
            textAlign: 'center',
            cursor: 'pointer'
        }
    }

    return (
        <div className="deliveryMobileOuterContainer">
            <h1 style={styles.headerText}>
                Consegna a Domicilio o Ritiro
                in Libreria?
            </h1>
            <div style={{ height: '5vh' }} />
            {
                deliveryOptions.map((item, index) =>
                    <div 
                        onClick={() => setDeliveryOption(item)}
                        className={currentDelivery === true && item.type === 'deliver' ? "deliveryMobileSelectedRadioContainer" : currentDelivery === false && item.type === 'pickup' ? "deliveryMobileSelectedRadioContainer" : "deliveryMobileRadioContainer"}
                    >
                        <div className="radioTextContainer">
                            <p style={{ fontFamily: fontFamily, color: currentDelivery === true && item.type === 'deliver' ? colour.primary : currentDelivery === false && item.type === 'pickup' ? colour.primary : colour.med, fontSize: '1em', fontWeight: currentDelivery === true && item.type === 'deliver' ? 'bold' : currentDelivery === false && item.type === 'pickup' ? 'bold' : 'normal', textAlign: 'center' }}>
                                {item.title}
                            </p>
                        </div>
                    </div>
                )
            }
            <div style={{ height: '5vh' }} />
            <ActionButton
                disabled={currentDelivery === null ? true : false}
                ref={ref} {...props}
            >
                Continua
            </ActionButton>
            <div style={{ height: '2vh' }} />
            <p
                onClick={() => props.onBack()} 
                style={styles.back}
            >
                Indietro
            </p>
        </div>
    )
})

export default DeliveryMobile;