import React, { useEffect, useCallback } from 'react';
import InternalSiteHeader from './InternalSiteHeader';
import InternalSiteSubHeader from './InternalSiteSubHeader';
import InternalSiteSkeleton from './InternalSiteSkeleton';
import Footer from './Footer';
import { useSelector, useDispatch } from 'react-redux';
import { internalSiteDelivery, internalSiteGiftCard, internalSiteIndex } from './actions';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { useHistory } from 'react-router-dom';

//Stripe = stefanoadami69@gmail.com Carallisto69
//Email = mondadoricremaonline@gmail.com
//mondadorisestrionline
//password is the same as the email WITHOUT online
//Sendgrid = mondadoricrema69

function InternalSite() {

    const currentModalOpen = useSelector(state => state.modalOpen);
    const currentInternalSiteIndex = useSelector(state => state.internalSiteIndex);
    const currentFocus = useSelector(state => state.focus);
    const dispatch = useDispatch();
    let history = useHistory();

    const handleUserKeyPress = useCallback(event => {
        const key = event.key;

        if (currentFocus === true) return;
        else if ( key === "1" ) {
            dispatch(internalSiteDelivery(true));
            return dispatch(internalSiteGiftCard(false));
        }
        else if ( key === "2" ) {
            dispatch(internalSiteDelivery(false))
            return dispatch(internalSiteGiftCard(false));
        }
        else if ( key === "3" ) {
            dispatch(internalSiteDelivery(false))
            return dispatch(internalSiteGiftCard(true));
        }
        else if ( key === "ArrowRight" ) {
            const cloneKey = currentInternalSiteIndex;

            if ( cloneKey === 4 ) {
                return dispatch(internalSiteIndex(0));
            }
            else {
                return dispatch(internalSiteIndex(cloneKey + 1));
            }
        }
        else if ( key === "ArrowLeft" ) {
            const cloneKey = currentInternalSiteIndex;

            if ( cloneKey === 0 ) {
                return dispatch(internalSiteIndex(4));
            }
            else {
                return dispatch(internalSiteIndex(cloneKey - 1));
            }
        }
        else return;
        
      }, [currentInternalSiteIndex, dispatch, currentFocus]);

    useEffect(() => {

        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                return true;
            }
            else {
                return history.push('../');
            }
        })

        window.addEventListener('keydown', handleUserKeyPress);

        return () => {
          window.removeEventListener('keydown', handleUserKeyPress);
        };
    })
 
    return (
        <div className={currentModalOpen === true ? "disableScroll" : ""}>
            <InternalSiteHeader />
            <InternalSiteSubHeader />
            <InternalSiteSkeleton />
            <Footer />
        </div>
    )
}

export default InternalSite;