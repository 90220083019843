import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import { fontFamily, colour } from './GlobalStyles';
import './OrderConfirmed.css';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { 
    bookTitle, 
    author, 
    editor, 
    notes, 
    phoneAction, 
    emailAction, 
    doorbellAction, 
    books, 
    surnameAction, 
    addressAction,
    nameAction
} from './actions';
import { isMobile } from 'react-device-detect';

function OrderConfirmed() {

    const currentName = useSelector(state => state.nameAction);
    const currentSurname = useSelector(state => state.surnameAction);
    const currentBooks = useSelector(state => state.books);
    const dispatch = useDispatch();
    let history = useHistory();

    useEffect(() => {
        if ( currentName === "" || currentSurname === "" ) {
            history.push('./');
        }
        else {
            return;
        }
    }, [currentSurname, currentName, history])

    function goHome() {
        dispatch(nameAction(""));
        dispatch(surnameAction(""));
        dispatch(emailAction(""));
        dispatch(phoneAction(""));
        dispatch(addressAction(""));
        dispatch(doorbellAction(""));
        dispatch(bookTitle(""));
        dispatch(author(""));
        dispatch(editor(""));
        dispatch(notes(""));
        dispatch(books([]));
        history.push('./');
    }

    const styles = {
        headerText: {
            fontFamily: fontFamily,
            color: colour.med,
            fontWeight: 'bold',
            fontSize: 30,
            textAlign: 'center'
        },
        emptyDiv: {
            width: '5vw',
            height: '5vw',
            borderRadius: '2.5vw',
            backgroundColor: '#A3A3A3'
        },
        subheading: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontWeight: 'bold',
            fontSize: '1.375em',
            textAlign: 'center'
        },
        description: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontWeight: 'normal',
            fontSize: '1.125em',
            textAlign: 'center'
        },
        bookHeader: {
            fontFamily: fontFamily,
            color: colour.med,
            fontWeight: 'normal',
            fontSize: '1em'
        },
        bookTitle: {
            fontFamily: fontFamily, 
            color: colour.dark,
            fontWeight: 'bold',
            fontSize: '0.875em',
            margin: '0.5em'
        },
        author: {
            fontFamily: fontFamily, 
            color: colour.dark,
            fontWeight: 'normal',
            fontSize: '0.875em',
            margin: '0.5em'
        },
        details: {
            fontFamily: fontFamily, 
            color: colour.med,
            fontWeight: 'normal',
            fontSize: '0.875em',
            margin: '0.5em'
        },
        goHome: {
            fontFamily: fontFamily, 
            color: colour.primary,
            fontWeight: 'bold',
            fontSize: '1em',
            textAlign: 'center',
            cursor: 'pointer'
        }
    }

    return (
        <div>
            <Header />
            <div>
                <div className="orderConfirmedCentre">
                    <div className={isMobile === true ? "orderConfirmedMobileWidth" : "orderConfirmedWidth"}>
                        <h1 style={styles.headerText}>
                            Richiesta Consegnata
                        </h1>
                        <img 
                            src="https://firebasestorage.googleapis.com/v0/b/mondadori-c11d4.appspot.com/o/Animals%2Fbear.png?alt=media&token=ba36bcff-d2d7-4177-9800-0f4de0441a5f"
                            className={isMobile === true ? "animalImageMobile" : "animalImage"}
                            alt="Animale"
                        />
                        <h2 style={styles.subheading}>
                            {currentName} {currentSurname},
                        </h2>
                        <p style={styles.description}>
                            Grazie per il tuo ordine. La tua richiesta è stata 
                            inviata alla libreria. Ti invieremo una mail entro 
                            24 ore con la disponibilità dei volumi da te ordinati 
                            e un link per procedere al pagamento online.
                            <br />
                            <br />
                            Ti chiediamo cortesemente di controllare la casella spam
                            della tua mail in caso non la trovassi.
                        </p>
                        <div style={{ height: '2vh' }} />
                        <p style={styles.bookHeader}>
                            I tuoi libri richiesti
                        </p>
                        {
                            currentBooks.map((item, index) =>
                                <div className={isMobile === true ? "bookListMobile" : "bookListItem"}>
                                    <p style={styles.bookTitle}>
                                        {item.title}
                                    </p>
                                    <p style={styles.author}>
                                        {item.author}
                                    </p>
                                    <p style={styles.details}>
                                        {item.editor}
                                    </p>
                                    <p style={styles.details}>
                                        {item.notes}
                                    </p>
                                </div>
                            )
                        }
                        <p 
                            onClick={() => goHome()}
                            style={styles.goHome}
                        >
                            Torna alla Home
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default OrderConfirmed; 