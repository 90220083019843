export const height = (height) => {
    return {
        type: 'height',
        payload: height
    }
}

export const width = (width) => {
    return {
        type: 'width',
        payload: width
    }
}

export const bookPage = (bookPage) => {
    return {
        type: 'bookPage',
        payload: bookPage
    }
}

export const nameAction = (nameAction) => {
    return {
        type: 'nameAction',
        payload: nameAction
    }
}

export const surnameAction = (surnameAction) => {
    return {
        type: 'surnameAction',
        payload: surnameAction
    }
}

export const emailAction = (emailAction) => {
    return {
        type: 'emailAction',
        payload: emailAction
    }
}

export const phoneAction = (phoneAction) => {
    return {
        type: 'phoneAction',
        payload: phoneAction
    }
}

export const addressAction = (addressAction) => {
    return {
        type: 'addressAction',
        payload: addressAction
    }
}

export const doorbellAction = (doorbellAction) => {
    return {
        type: 'doorbellAction',
        payload: doorbellAction
    }
}

export const giftCardIndex = (giftCardIndex) => {
    return {
        type: 'giftCardIndex',
        payload: giftCardIndex
    }
}

export const price = (price) => {
    return {
        type: 'price',
        payload: price
    }
}

export const bookTitle = (bookTitle) => {
    return {
        type: 'bookTitle',
        payload: bookTitle
    }
}

export const author = (author) => {
    return {
        type: 'author',
        payload: author
    }
}

export const editor = (editor) => {
    return {
        type: 'editor',
        payload: editor
    }
}

export const notes = (notes) => {
    return {
        type: 'notes',
        payload: notes
    }
}

export const books = (books) => {
    return {
        type: 'books',
        payload: books
    }
}

export const shopName = (shopName) => {
    return {
        type: 'shopName',
        payload: shopName
    }
}

export const buttonDisabled = (buttonDisabled) => {
    return {
        type: 'buttonDisabled',
        payload: buttonDisabled
    }
}

export const delivery = (delivery) => {
    return {
        type: 'delivery',
        payload: delivery
    }
}

export const recipientEmail = (recipientEmail) => {
    return {
        type: 'recipientEmail',
        payload: recipientEmail
    }
}

export const customGift = (customGift) => {
    return {
        type: 'customGift',
        payload: customGift
    }
}

export const bookShopAddress = (bookShopAddress) => {
    return {
        type: 'bookShopAddress',
        payload: bookShopAddress
    }
}

export const recipientName = (recipientName) => {
    return {
        type: 'recipientName',
        payload: recipientName
    }
}

export const giftMessage = (giftMessage) => {
    return {
        type: 'giftMessage',
        payload: giftMessage
    }
}

export const giftCardDate = (giftCardDate) => {
    return {
        type: 'giftCardDate',
        payload: giftCardDate
    }
}

export const paymentOrder = (paymentOrder) => {
    return {
        type: 'paymentOrder',
        payload: paymentOrder
    }
}

export const navActive = (navActive) => {
    return {
        type: 'navActive',
        payload: navActive
    }
}

export const shopTitle = (shopTitle) => {
    return {
        type: 'shopTitle',
        payload: shopTitle
    }
}

export const internalSiteDelivery = (internalSiteDelivery) => {
    return {
        type: 'internalSiteDelivery',
        payload: internalSiteDelivery
    }
}

export const internalSiteIndex = (internalSiteIndex) => {
    return {
        type: 'internalSiteIndex',
        payload: internalSiteIndex
    }
}

export const internalSiteData = (internalSiteData) => {
    return {
        type: 'internalSiteData',
        payload: internalSiteData
    }
}

export const internalSitePrice = (internalSitePrice) => {
    return {
        type: 'internalSitePrice',
        payload: internalSitePrice
    }
}

export const internalSiteExpanded = (internalSiteExpanded) => {
    return {
        type: 'internalSiteExpanded',
        payload: internalSiteExpanded
    }
}

export const internalSiteStock = (internalSiteStock) => {
    return {
        type: 'internalSiteStock',
        payload: internalSiteStock
    }
}

export const modalOpen = (modalOpen) => {
    return {
        type: 'modalOpen',
        payload: modalOpen
    }
}

export const modalIndex = (modalIndex) => {
    return {
        type: 'modalIndex',
        payload: modalIndex
    }
}

export const deleteModalItem = (deleteModalItem) => {
    return {
        type: 'deleteModalItem',
        payload: deleteModalItem
    }
}

export const focus = (focus) => {
    return {
        type: 'focus',
        payload: focus
    }
}

export const bookShopPhoneNumber = (bookShopPhoneNumber) => {
    return {
        type: 'bookShopPhoneNumber',
        payload: bookShopPhoneNumber
    }
}

export const bookShopEmail = (bookShopEmail) => {
    return {
        type: 'bookShopEmail',
        payload: bookShopEmail
    }
}

export const userName = (userName) => {
    return {
        type: 'userName',
        payload: userName
    }
}

export const password = (password) => {
    return {
        type: 'password',
        payload: password
    }
}

export const internalSiteGiftCard = (internalSiteGiftCard) => {
    return {
        type: 'internalSiteGiftCard',
        payload: internalSiteGiftCard
    }
}

export const giftCardHolders = (giftCardHolders) => {
    return {
        type: 'giftCardHolders',
        payload: giftCardHolders
    }
}

export const voucherCode = (voucherCode) => {
    return {
        type: 'voucherCode',
        payload: voucherCode
    }
}

export const voucherCodeCorrect = (voucherCodeCorrect) => {
    return {
        type: 'voucherCodeCorrect',
        payload: voucherCodeCorrect
    }
}

export const deliveryFee = (deliveryFee) => {
    return {
        type: 'deliveryFee',
        payload: deliveryFee
    }
}

export const balanceRemaining = (balanceRemaining) => {
    return {
        type: 'balanceRemaining',
        payload: balanceRemaining
    }
}

export const dateChanged = (dateChanged) => {
    return {
        type: 'dateChanged',
        payload: dateChanged
    }
}

export const cashModal = (cashModal) => {
    return {
        type: 'cashModal',
        payload: cashModal
    }
}

export const cashPayment = (cashPayment) => {
    return {
        type: 'cashPayment',
        payload: cashPayment
    }
}

export const eighteenAppPayment = (eighteenAppPayment) => {
    return {
        type: 'eighteenAppPayment',
        payload: eighteenAppPayment
    }
}

export const cartaDocentePayment = (cartaDocentePayment) => {
    return {
        type: 'cartaDocentePayment',
        payload: cartaDocentePayment
    }
}

export const publicSectorCode = (publicSectorCode) => {
    return {
        type: 'publicSectorCode',
        payload: publicSectorCode
    }
}

export const quantity = (quantity) => {
    return {
        type: 'quantity',
        payload: quantity
    }
}