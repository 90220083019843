import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './PublicSectorButton.css';
import { eighteenAppPayment, cartaDocentePayment, publicSectorCode, buttonDisabled } from './actions';
import { Input, VoucherInput, colour, fontFamily } from './GlobalStyles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import './PublicSectorPayment.css';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { useHistory } from 'react-router-dom';

function PublicSectorPayment({ customer }) {

    const currentWidth = useSelector(state => state.width);
    const currentEighteenAppPayment = useSelector(state => state.eighteenAppPayment);
    const currentCartaDocentePayment = useSelector(state => state.cartaDocentePayment);
    const currentPublicSectorCode = useSelector(state => state.publicSectorCode);
    const currentButtonDisabled = useSelector(state => state.buttonDisabled);
    const currentShopName = useSelector(state => state.shopName);
    const dispatch = useDispatch();
    let history = useHistory();

    function selectPayment(value) {
        value === "18App" ?
        dispatch(eighteenAppPayment(true)) &&
        dispatch(cartaDocentePayment(false))
        :
        dispatch(eighteenAppPayment(false)) &&
        dispatch(cartaDocentePayment(true))
    }

    function submit() {
        dispatch(buttonDisabled(true));

        const db = firebase.firestore()

        const customerDataRef = db.collection("Test Customer Data").doc(currentShopName);

        db.collection("Customer Orders").doc(customer.code).update({
            payment: true,
            eighteenAppPayment: currentEighteenAppPayment,
            cartaDocentePayment: currentCartaDocentePayment,
            publicSectorCode: currentPublicSectorCode
        })

        db.runTransaction((transaction) => {
            return transaction.get(customerDataRef).then((doc) => {
                if (!doc.exists) {
                    return
                }
                else {
                const data = doc.data();
                const customerData = data.customerData;
                const dataIndex = customerData.findIndex(e => e.code === customer.code);
                customerData[dataIndex].payment = true;
                customerData[dataIndex].awaitingPayment = false;
                customerData[dataIndex].awaitingCompletion = true;
                customerData[dataIndex].eighteenAppPayment = currentEighteenAppPayment;
                customerData[dataIndex].cartaDocentePayment = currentCartaDocentePayment;
                customerData[dataIndex].publicSectorCode = currentPublicSectorCode;

                transaction.update(customerDataRef, 
                    { 
                        customerData
                    });
                    return customerData;
            }
            })
        })
        .then(() => {
            history.push('../pagamentoCompleto');
            dispatch(buttonDisabled(false));
        })
    }

    function back() {
        dispatch(eighteenAppPayment(false));
        dispatch(cartaDocentePayment(false));
    }

    const styles = {
        nada: {

        },
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        back: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontSize: '1em',
            fontWeight: 'normal',
            cursor: 'pointer',
            textAlign: 'center'
        }
    }

    return (
        <div 
            style={currentEighteenAppPayment === false && currentCartaDocentePayment === false ? styles.nada : styles.centre} 
            className={currentEighteenAppPayment === true || currentCartaDocentePayment === true ? "" : currentWidth < 900 ? "mobilePublicSectorPayment": "publicSectorPayment"}
        >
            {
                currentCartaDocentePayment === true ?
                null
                :
                <img 
                    src="https://firebasestorage.googleapis.com/v0/b/mondadori-c11d4.appspot.com/o/18AppButton.png?alt=media&token=4fc1df2d-6495-4f0c-a718-c63829f3e3ce"
                    alt="18 App"
                    className={currentWidth < 900 ? "mobilePublicSectorButton" : "publicSectorButton"}
                    onClick={() => selectPayment("18App")}
                />
            }
            {
                currentEighteenAppPayment === true ?
                null
                :
                <img 
                    src="https://firebasestorage.googleapis.com/v0/b/mondadori-c11d4.appspot.com/o/CartaDocenteButton.png?alt=media&token=a3871efa-914f-4e39-aac6-1f63550422a7"
                    alt="Carta Del Docente"
                    className={currentWidth < 900 ? "mobilePublicSectorButton" : "publicSectorButton"}
                    onClick={() => selectPayment("cartaDocente")}
                />
            }
            {
                currentCartaDocentePayment === true || currentEighteenAppPayment === true ?
                    currentWidth < 900 ?
                        <div>
                            <div className="publicSectorPaymentRow">
                                <Input 
                                    value={currentPublicSectorCode}
                                    id="outlined"
                                    label={currentEighteenAppPayment === true ? "Codice 18App" : "Codice Carta Del Docente"}
                                    inputProps={{
                                        autoCapitalize: 'none',
                                    }}
                                    variant="outlined"
                                    onChange={(text) => dispatch(publicSectorCode(text.target.value))}
                                />
                                <CheckCircleIcon
                                    style={{
                                            fontSize: 50,
                                            color: currentButtonDisabled === true ? colour.med : currentPublicSectorCode === "" ? colour.med : colour.primary,
                                            cursor: currentButtonDisabled === true ? 'auto' : currentPublicSectorCode === "" ? 'auto' : 'pointer'
                                        }}
                                    className={currentButtonDisabled === true ? "" : currentPublicSectorCode === "" ? "" : "voucherCodeCircle" }
                                    onClick={() => currentButtonDisabled === true ? null : currentPublicSectorCode === "" ? null : submit()}
                                />
                            </div>
                            <div style={{ height: '2vh' }} />
                            <p
                                onClick={() => back()} 
                                style={styles.back}
                            >
                                Indietro
                            </p>
                        </div>
                        :
                        <div>
                            <div className="publicSectorPaymentRow">
                                <VoucherInput
                                    value={currentPublicSectorCode}
                                    id="outlined"
                                    label={currentEighteenAppPayment === true ? "Codice 18App" : "Codice Carta Del Docente"}
                                    inputProps={{
                                        autoCapitalize: 'none',
                                    }}
                                    variant="outlined"
                                    onChange={(text) => dispatch(publicSectorCode(text.target.value))}
                                />
                                <CheckCircleIcon
                                    style={{
                                            fontSize: 50,
                                            color: currentButtonDisabled === true ? colour.med : currentPublicSectorCode === "" ? colour.med : colour.primary,
                                            cursor: currentButtonDisabled === true ? 'auto' : currentPublicSectorCode === "" ? 'auto' : 'pointer'
                                        }}
                                    className={currentButtonDisabled === true ? "" : currentPublicSectorCode === "" ? "" : "voucherCodeCircle" }
                                    onClick={() => currentButtonDisabled === true ? null : currentPublicSectorCode === "" ? null : submit()}
                                />
                            </div>
                            <div style={{ height: '2vh' }} />
                            <p
                                onClick={() => back()} 
                                style={styles.back}
                            >
                                Indietro
                            </p>
                        </div>
                :
                null
            }
        </div>
    )
}

export default PublicSectorPayment;