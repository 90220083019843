const url = window.location.href;

export const shopName = url.includes("crema") ? "Crema" 
                        : 
                        url.includes("trento") ? "Trento" 
                        : 
                        url.includes("varazze") ? "Varazze" 
                        :
                        url.includes("acqui") ? "Acqui Terme"
                        :
                        url.includes("sestriponente") ? "Sestri Ponente"
                        :
                        url.includes("sestri") ? "Sestri Levante"
                        :
                        url.includes('loano') ? "Loano"
                        :
                        url.includes("local") ? "Local" 
                        : 
                        "Local";

export const shopWebsite = url.includes("crema") ? "https://www.mondadoricrema.it" 
                            : 
                            url.includes("trento") ? "https://www.mondadoritrento.it" 
                            : 
                            url.includes("varazze") ? "https://www.mondadorivarazze.it" 
                            :
                            url.includes("acqui") ? "https://www.mondadoriacquiterme.it"
                            :
                            url.includes("sestriponente") ? "https://www.mondadorisestriponente.it"
                            :
                            url.includes("sestri") ? "https://www.mondadorisestrilevante.it"
                            :
                            url.includes("local") ? "http://localhost:3000" 
                            : 
                            url.includes('loano') ? "https://www.mondadoriloano.it"
                            :
                            "http://localhost:3000";

export const companyName = url.includes("local") ? "Griso srl" : url.includes("crema") ? "Griso srl" : url.includes("trento") ? "Griso srl" : "Breva srl";