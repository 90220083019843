import React, { useRef } from 'react';
import HTMLFlipBook from "react-pageflip";
import { colour, fontFamily } from './GlobalStyles';
import './LandingComponent.css';
import { useSelector, useDispatch } from 'react-redux';
import { bookPage } from './actions';
import BookPage from './BookPage';
import IllustrationPage from './IllustrationPage';
import ChooseBook from './ChooseBook';
import Delivery from './Delivery';
import OrderReview from './OrderReview';
import BookList from './BookList';

function LandingComponent() {
    
    const flipper = useRef(null);
    const currentWidth = useSelector(state => state.width);
    const currentHeight = useSelector(state => state.height);
    const currentBookPage = useSelector(state => state.bookPage);
    const dispatch = useDispatch();

    const styles = {
        headerText: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontWeight: '500',
            fontSize: 30,
            textAlign: 'center'
        },
        pageButton: {
            fontFamily: fontFamily,
            color: colour.primary,
            fontWeight: 'normal',
            fontSize: '1em',
            textAlign: 'center',
            fontStyle: 'italic',
            cursor: 'pointer'
        }
    }

    function onFlip(data) {
        dispatch(bookPage(data));
    }   

    function flipForward() {
        flipper.current.getPageFlip().flipNext();
    }

    function flipBack() {
        flipper.current.getPageFlip().flipPrev();
    }

    function flip() {
        flipper.current.getPageFlip().flip(18);
    }

    return (
        <div className="landingComponentOuterContainer">
            <h1 style={styles.headerText}>
                Ordina il tuo libro preferito a domicilio!
                <br />
                o
                <br />
                Prenota il tuo libro preferito e ritiralo in libreria
            </h1>
            <div>
                <HTMLFlipBook 
                    onFlip={(e) => onFlip(e.data)}
                    ref={flipper}
                    useMouseEvents={false}
                    width={currentWidth * 0.5}
                    height={currentHeight * 0.68}
                    size="stretch"
                    // style={{ backgroundColor: 'blue' }}
                >
                    <div>
                        <ChooseBook
                            ref={flipper}
                            onClick={flipForward}
                            goBack={flipBack}
                            flip={flip}
                        />
                    </div>
                    <div>
                        <IllustrationPage
                            source="https://firebasestorage.googleapis.com/v0/b/mondadori-c11d4.appspot.com/o/Interactive%20Book%20Photos%2Fworld-of-books.jpg?alt=media&token=58758a55-1911-481f-a2cd-c1b3f2844fcb"
                        />
                    </div>
                    <div>
                        <BookList
                            ref={flipper}
                            onClick={flipForward}
                            goBack={flipBack}
                            flip={flip}
                        />
                    </div>
                    <div>
                        <IllustrationPage
                            source="https://firebasestorage.googleapis.com/v0/b/mondadori-c11d4.appspot.com/o/Interactive%20Book%20Photos%2Fi-love-reading-tqs-1.jpg?alt=media&token=e607ad0e-30f0-4d50-845c-01ece223826e"
                        />
                    </div>
                    <div>
                        <Delivery 
                            ref={flipper}
                            onClick={flipForward}
                            goBack={flipBack}
                            flip={flip}
                        />
                    </div>
                    <div>
                        <IllustrationPage 
                            source="https://firebasestorage.googleapis.com/v0/b/mondadori-c11d4.appspot.com/o/Interactive%20Book%20Photos%2Fbooks.jpg?alt=media&token=469c8473-b5ab-4a2e-8fa0-fd377b7c5572"
                        />
                    </div>
                    <div>
                        <BookPage
                            title="Inserisci il tuo nome"
                            ref={flipper}
                            onClick={flipForward}
                            goBack={flipBack}
                            flip={flip}
                        />
                    </div>
                    <div>
                        <IllustrationPage
                            source="https://firebasestorage.googleapis.com/v0/b/mondadori-c11d4.appspot.com/o/Interactive%20Book%20Photos%2Fchildren-reading.jpg?alt=media&token=d2d9e6f0-5c6b-492b-9b06-e661266edea3"
                        />
                    </div>
                    <div>
                        <BookPage
                            title="Inserisci il tuo cognome"
                            ref={flipper}
                            onClick={flipForward}
                            goBack={flipBack}
                            flip={flip}
                        />
                    </div>
                    <div>
                        <IllustrationPage
                            source="https://firebasestorage.googleapis.com/v0/b/mondadori-c11d4.appspot.com/o/Interactive%20Book%20Photos%2Fi%20%3C3%20books.jpg?alt=media&token=bdf99113-a956-4b8a-a780-f16ed036db51"
                        />
                    </div>
                    <div>
                        <BookPage
                            title="Inserisci la tua mail"
                            ref={flipper}
                            onClick={flipForward}
                            goBack={flipBack}
                            flip={flip}
                        />
                    </div>
                    <div>
                        <IllustrationPage
                            source="https://firebasestorage.googleapis.com/v0/b/mondadori-c11d4.appspot.com/o/Interactive%20Book%20Photos%2Fstacked-books.jpg?alt=media&token=6d29632c-7ad1-443b-aab1-45c7910a6a08"
                        />
                    </div>
                    <div>
                        <BookPage
                            title="Inserisci il tuo numero di telefono"
                            ref={flipper}
                            onClick={flipForward}
                            goBack={flipBack}
                            flip={flip}
                        />
                    </div>
                    <div>
                        <IllustrationPage
                            source="https://firebasestorage.googleapis.com/v0/b/mondadori-c11d4.appspot.com/o/Interactive%20Book%20Photos%2Fanimals-reading.jpg?alt=media&token=479bd33d-7a7f-439b-9faf-45a313624d74"
                        />
                    </div>
                    <div>
                        <BookPage
                            title="Inserisci il tuo indirizzo"
                            ref={flipper}
                            onClick={flipForward}
                            goBack={flipBack}
                            flip={flip}
                        />
                    </div>
                    <div>
                        <IllustrationPage
                            source="https://firebasestorage.googleapis.com/v0/b/mondadori-c11d4.appspot.com/o/Interactive%20Book%20Photos%2Fbook-shelf.jpg?alt=media&token=2aaade55-13c4-488c-9460-06454e453179"
                        />
                    </div>
                    <div>
                        <BookPage
                            title="Inserisci il tuo citofono"
                            ref={flipper}
                            onClick={flipForward}
                            goBack={flipBack}
                            flip={flip}
                        />
                    </div>
                    <div>
                        <IllustrationPage
                            source="https://firebasestorage.googleapis.com/v0/b/mondadori-c11d4.appspot.com/o/Interactive%20Book%20Photos%2Fother-i-love-books.jpg?alt=media&token=14f0976c-4c43-4e96-86d4-1a56fd68d25a"
                        />
                    </div>
                    <div>
                        <OrderReview
                        
                        />
                    </div>
                    <div>
                        <IllustrationPage
                            source="https://firebasestorage.googleapis.com/v0/b/mondadori-c11d4.appspot.com/o/Interactive%20Book%20Photos%2Fpeople-reading.jpg?alt=media&token=90354e78-cdf8-4c8f-8f1b-700272cf47e5"
                        />
                    </div>
                </HTMLFlipBook>
            </div>
            <div className="bookButtonsCentre">
                <div className="bookButtons">
                    {
                        currentBookPage === 0 || currentBookPage === 2 ?
                        <div/>
                        :
                        <div>
                            <p 
                                onClick={() => flipBack()}
                                style={styles.pageButton}
                            >
                                Pagina Precedente
                            </p>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default LandingComponent;