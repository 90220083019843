import React, { useEffect } from 'react';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import Header from './Header';
import Footer from './Footer';
import { paymentOrder, width, height, buttonDisabled } from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { fontFamily, colour, ActionButton, HeaderButton } from './GlobalStyles';
import './Pay.css';
import StripeCheckout from './StripeCheckout';
import PayPalPayment from './PayPalPayment';
import Loading from './Loading';
import VoucherPayment from './VoucherPayment';
import { useHistory } from 'react-router-dom';
import CashPayment from './CashPayment';
import PublicSectorPayment from './PublicSectorPayment';

function Pay({ match }) {

    const id = match.params.id;
    const currentPaymentOrder = useSelector(state => state.paymentOrder);
    const currentBookShopAddress = useSelector(state => state.bookShopAddress);
    const currentWidth = useSelector(state => state.width);
    const currentDeliveryFee = useSelector(state => state.deliveryFee);
    const currentVoucherCodeCorrect = useSelector(state => state.voucherCodeCorrect);
    const currentBalanceRemaining = useSelector(state => state.balanceRemaining);
    const currentShopName = useSelector(state => state.shopName);
    const currentEighteenAppPayment = useSelector(state => state.eighteenAppPayment);
    const currentCartaDocentePayment = useSelector(state => state.cartaDocentePayment);
    const dispatch = useDispatch();
    let history = useHistory();

    useEffect(() => {

        firebase.firestore().collection("Customer Orders").doc(id)
        .onSnapshot((snapshot) => {
            if (!snapshot.exists) {
                console.log('No such document!');
            }
            else {
                const data = snapshot.data();
                dispatch(paymentOrder(data));
            }
        })

        function resizeWindow () {
            console.log("RESIZE PAY")
            const newWidth = window.innerWidth;
            const newHeight = window.innerHeight;
            dispatch(width(newWidth));
            dispatch(height(newHeight));
      };
      window.addEventListener("resize", resizeWindow);
      return () => window.removeEventListener("resize", resizeWindow);

    }, [id, dispatch])

    function checkInteger(item) {
        if ( Number.isInteger(item) === true ) {
            return ".00";
        } 
        else {
            if ( Math.floor(item) !== item ) {
                if ( (item.toString().split(".")[1].length || 0) === 2 ) {
                    return null;
                }
                else if ( Number.isInteger(item) === true ) {
                    return ".00";
                }
                else {
                    return '0';
                }
            }
            else {
                return null;
            }

        }
    }

    function makeId(length) {
        dispatch(buttonDisabled(true));
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return confirmOrder(result);
    }

    function confirmOrder(result) {

        const db = firebase.firestore()

        const customerDataRef = db.collection("Test Customer Data").doc(currentShopName);

        db.collection("Customer Orders").doc(currentPaymentOrder.code).update({
            payment: true
        })

        db.runTransaction((transaction) => {
            return transaction.get(customerDataRef).then((doc) => {
                if (!doc.exists) {
                    return
                }
                else {
                const data = doc.data();
                const customerData = data.customerData;
                const dataIndex = customerData.findIndex(e => e.code === currentPaymentOrder.code);
                customerData[dataIndex].payment = true;
                customerData[dataIndex].awaitingPayment = false;
                customerData[dataIndex].awaitingCompletion = true;

                transaction.update(customerDataRef, 
                    { 
                        customerData
                    });
                    return customerData;
            }
            })
        })
        .then(() => {
            history.push('../pagamentoCompleto');
            dispatch(buttonDisabled(false));
        })
    }

    const styles = {
        headerText: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontWeight: 'bold',
            fontSize: 30,
            textAlign: 'center'
        },
        emptyDiv: {
            width: '10vw',
            height: '10vw',
            borderRadius: '5vw',
            backgroundColor: colour.lightestGray
        },
        subheader: {
            fontFamily: fontFamily,
            color: colour.med,
            fontWeight: 'normal',
            fontSize: '1em',
            marginBottom: 0
        },
        deliveryType: {
            fontFamily: fontFamily,
            color: colour.primary,
            fontWeight: 'bold',
            fontSize: '1em'
        },
        shopName: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontWeight: 'bold',
            fontSize: '1em',
            marginBottom: 0
        },
        address: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontWeight: 'normal',
            fontSize: '1em',
            whiteSpace: 'pre-line',
            marginTop: 0
        },
        mainTitle: {
            fontFamily: fontFamily,
            color: colour.med,
            fontWeight: 'normal',
            fontSize: '1.125em',
            paddingLeft: '1vw'
        },
        bookTitle: {
            fontFamily: fontFamily, 
            color: colour.dark,
            fontWeight: 'bold',
            fontSize: '0.875em',
            margin: '0.5em'
        },
        strikeThroughBookTitle: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontWeight: 'bold',
            fontSize: '1.125em',
            textDecorationLine: 'line-through'
        },
        author: {
            fontFamily: fontFamily, 
            color: colour.dark,
            fontWeight: 'normal',
            fontSize: '0.875em',
            margin: '0.5em'
        },
        strikeThroughAuthor: {
            fontFamily: fontFamily, 
            color: colour.dark,
            fontWeight: 'normal',
            fontSize: '0.875em',
            margin: '0.5em',
            textDecorationLine: 'line-through'
        },
        details: {
            fontFamily: fontFamily, 
            color: colour.med,
            fontWeight: 'normal',
            fontSize: '0.875em',
            margin: '0.5em'
        },
        strikeThroughDetails: {
            fontFamily: fontFamily, 
            color: colour.med,
            fontWeight: 'normal',
            fontSize: '0.875em',
            margin: '0.5em',
            textDecorationLine: 'line-through'
        },
        feeType: {
            fontFamily: fontFamily, 
            color: colour.med,
            fontWeight: 'normal',
            fontSize: '1em',
            marginBottom: 0,
            marginTop: '1em'
        },
        fee: {
            fontFamily: fontFamily, 
            color: colour.med,
            fontWeight: 'normal',
            fontSize: '1em',
            textAlign: 'right',
            marginBottom: 0,
            marginTop: '1em'
        },
        total: {
            fontFamily: fontFamily, 
            color: colour.dark,
            fontWeight: 'bold',
            fontSize: '1.375em'
        },
        totalFee: {
            fontFamily: fontFamily, 
            color: colour.dark,
            fontWeight: 'bold',
            fontSize: '1.375em',
            textAlign: 'right'
        },
        paymentComplete: {
            fontFamily: fontFamily, 
            color: colour.dark,
            fontWeight: 'bold',
            fontSize: '1.375em',
            textAlign: 'center'
        },
        voucherMessage: {
            fontFamily: fontFamily,
            color: currentVoucherCodeCorrect === true ? colour.green : colour.primary,
            fontSize: '1em',
            fontWeight: 'normal',
            textAlign: 'left'
        },
        notAvailable: {
            fontFamily: fontFamily, 
            color: colour.primary,
            fontWeight: 'normal',
            fontSize: '0.875em',
            margin: '0.5em'
        },
        available: {
            fontFamily: fontFamily, 
            color: colour.green,
            fontWeight: 'normal',
            fontSize: '0.875em',
            margin: '0.5em'
        },
        coming: {
            fontFamily: fontFamily, 
            color: "#F8DB02",
            fontWeight: 'normal',
            fontSize: '0.875em',
            margin: '0.5em'
        }
    }

    return (
        <div>
            <Header />
            <div className="payOuterContainer">
                <h1 style={styles.headerText}>
                    Il tuo Ordine
                </h1>
                <Loading />
                <div className={currentWidth < 900 ? "payColumn" : "payRow"}>
                    <div className={currentWidth < 900 ? "mobilePayWidth" : "payOrderType"}>
                        <div className={currentWidth < 900 ? "payMobileCentre" : ""}>
                            <img
                                src="https://firebasestorage.googleapis.com/v0/b/mondadori-c11d4.appspot.com/o/Animals%2Flion.png?alt=media&token=c4b57e25-806d-404d-957f-ff6e503bf10c"
                                className={currentWidth < 900 ? "mobileAnimalPay" : "animalPay"}
                                alt="Leone"
                            />
                            <div style={{ height: '2vh' }} />
                            <p style={styles.subheader}>
                                Metodo di Arrivo
                            </p>
                            <p style={styles.deliveryType}>
                                {
                                    currentPaymentOrder.length === 0 ?
                                    ""
                                    :
                                    currentPaymentOrder.delivery === true ?
                                    "Consegna a Domicilio"
                                    :
                                    "Ritiro in Libreria"
                                }
                            </p>
                            <p style={styles.subheader}>
                                {
                                    currentPaymentOrder.length === 0 ?
                                    ""
                                    :
                                    currentPaymentOrder.delivery === true ?
                                    "Indirizzo:"
                                    :
                                    "Luogo di Ritiro:"
                                }
                            </p>
                            {
                                currentPaymentOrder.delivery === true ?
                                null
                                :
                                <p style={styles.shopName}>
                                    Mondadori Bookstore
                                </p>
                            }
                            <p style={styles.address}>
                                {
                                    currentPaymentOrder.length === 0 ?
                                    ""
                                    :
                                    currentPaymentOrder.delivery === true ?
                                    currentPaymentOrder.address
                                    :
                                    currentBookShopAddress
                                }
                            </p>
                        </div>
                    </div>
                    <div className={currentWidth < 900 ? "mobilePayWidth" : "payMain"}>
                        <div className={currentWidth < 900 ? "" : "payMainWidth"}>
                            <p style={styles.mainTitle}>
                                <span style={{ fontWeight: 'bold' }}>{currentPaymentOrder.name}</span>, grazie per il tuo ordine.
                                In basso troverai la lista dei libri che hai ordinato:
                            </p>
                            <div style={{ height: '2vh' }} />
                            {
                                currentPaymentOrder.length === 0 ?
                                null
                                :
                                currentPaymentOrder.books.map((item, index) =>
                                    <div className={currentWidth < 900 ? "mobilePayBookListItem" : "payBookListItem"}>
                                        <p style={item.inStock === true ? styles.bookTitle : styles.strikeThroughBookTitle}>
                                            {item.title}
                                        </p>
                                        <p style={item.inStock === true ? styles.author : styles.strikeThroughAuthor}>
                                            {item.author}
                                        </p>
                                        <p style={item.inStock === true ? styles.details : styles.strikeThroughDetails}>
                                            {item.editor}
                                        </p>
                                        {
                                            item.delay === true ?
                                            <p style={styles.coming}>
                                                Volume in arrivo entro {item.delayDays} giorni lavorativi
                                                al punto vendita
                                            </p>
                                            :
                                            item.inStock === false ?
                                            <p style={styles.notAvailable}>
                                                Volume non procurabile
                                            </p>
                                            :
                                            item.inStock === true ?
                                            <p style={styles.available}>
                                                Volume già presente in libreria
                                            </p>
                                            :
                                            null
                                        }
                                    </div>
                                )
                            }
                            <div style={{ height: '5vh' }} />
                            <div className={currentWidth < 900 ? "payMobileCentre" : "payCentre"}>
                                <div className={currentWidth < 900 ? "mobilePayPricing" : "payPricing"}>
                                    <p style={styles.feeType}>
                                        Subtotale
                                    </p>
                                    <p style={styles.fee}>
                                        €{
                                            currentPaymentOrder.length === 0 ?
                                            "0,00"
                                            :
                                            currentPaymentOrder.price === undefined ?
                                            "0,00"
                                            :
                                            currentPaymentOrder.price
                                        }
                                        {
                                            currentPaymentOrder.length === 0 ?
                                            null
                                            :
                                            currentPaymentOrder.price === undefined ?
                                            null
                                            :
                                            Number.isInteger(currentPaymentOrder.price) === true ? ".00"
                                            :
                                            Math.floor(currentPaymentOrder.price) !== currentPaymentOrder.price ? 
                                                (currentPaymentOrder.price.toString().split(".")[1].length || 0) === 2 ?
                                                null
                                                :
                                                Number.isInteger(currentPaymentOrder.price) === true ? ".00" : '0'
                                            :
                                            null
                                        }
                                    </p>
                                </div>
                                <div className={currentWidth < 900 ? "mobilePayPricing" : "payPricing"}>
                                    <p style={styles.feeType}>
                                        Consegna
                                    </p>
                                    <p style={styles.fee}>
                                        €{
                                            currentPaymentOrder.length === 0 ?
                                            null
                                            :
                                            currentPaymentOrder.delivery === false ?
                                            "0,00"
                                            :
                                            currentPaymentOrder.deliveryFee !== undefined ?
                                            currentPaymentOrder.deliveryFee
                                            :
                                            currentVoucherCodeCorrect === true ?
                                            currentDeliveryFee
                                            :
                                            currentPaymentOrder.price < 19 ?
                                            "1,50"
                                            :
                                            "0,00"
                                        }
                                    </p>
                                </div>
                                {
                                    currentPaymentOrder.payment === true ?
                                    <div className={currentWidth < 900 ? "mobilePayPricingCentre" : "payPricingCentre"}>
                                        <p style={styles.paymentComplete}>
                                            Pagamento Completo :)
                                        </p>
                                    </div>
                                    :
                                    <div className={currentWidth < 900 ? "mobilePayPricing" : "payPricing"}>
                                        <p style={styles.total}>
                                            Totale
                                        </p>
                                        <p style={styles.totalFee}>
                                            €{
                                                currentPaymentOrder.length === 0 ?
                                                "0,00"
                                                :
                                                currentPaymentOrder.price === undefined ?
                                                "0,00"
                                                :
                                                currentPaymentOrder.delivery === false ?
                                                currentPaymentOrder.price
                                                :
                                                currentVoucherCodeCorrect === true ?
                                                currentPaymentOrder.price + currentDeliveryFee
                                                :
                                                currentVoucherCodeCorrect === true ?
                                                currentPaymentOrder.price + currentDeliveryFee
                                                :
                                                currentPaymentOrder.deliveryFee !== undefined ?
                                                currentPaymentOrder.price + currentPaymentOrder.deliveryFee
                                                :
                                                currentPaymentOrder.price < 19 ?
                                                currentPaymentOrder.price + 1.5
                                                :
                                                currentPaymentOrder.price
                                            }
                                            {
                                                currentPaymentOrder.length === 0 ?
                                                null
                                                :
                                                currentPaymentOrder.price === undefined ?
                                                null
                                                :
                                                checkInteger(
                                                                currentPaymentOrder.delivery === false ?
                                                                currentPaymentOrder.price
                                                                :
                                                                currentVoucherCodeCorrect === true ?
                                                                currentPaymentOrder.price + currentDeliveryFee
                                                                :
                                                                currentPaymentOrder.deliveryFee !== undefined ?
                                                                currentPaymentOrder.price + currentPaymentOrder.deliveryFee
                                                                :
                                                                currentPaymentOrder.price < 19 ?
                                                                currentPaymentOrder.price + 1.5
                                                                :
                                                                currentPaymentOrder.price
                                                            )
                                            }
                                        </p>
                                    </div>
                                }
                                <div style={{ height: '2vh' }} />
                                {
                                    currentPaymentOrder.payment === true ?
                                    null
                                    :
                                    currentPaymentOrder.giftCard === true && currentPaymentOrder.price === 0 && currentPaymentOrder.deliveryFee === 0 ?
                                    <div className={currentWidth < 900 ? "paymentContainerMobile" : "paymentContainer"}>
                                        {
                                            currentVoucherCodeCorrect === true ?
                                            <p style={styles.voucherMessage}>
                                                Codice Gift Card applicato con successo :) 
                                                Rimangono € {currentBalanceRemaining.toFixed(2)} sulla tua gift card.
                                            </p>
                                            :
                                            null
                                        }
                                        {
                                            currentWidth < 900 ?
                                            <ActionButton
                                                onClick={() => makeId(15)}
                                            >
                                                Completa Ordine
                                            </ActionButton>
                                            :
                                            <HeaderButton
                                                onClick={() => makeId(15)}
                                            >
                                                Completa Ordine
                                            </HeaderButton>
                                        }
                                    </div>
                                    :
                                    <div className={currentWidth < 900 ? "paymentContainerMobile" : "paymentContainer"}>
                                        {
                                            currentEighteenAppPayment === true || currentCartaDocentePayment === true ?
                                            null
                                            :
                                            <div>
                                                {
                                                    currentPaymentOrder.price === undefined ?
                                                    null
                                                    :
                                                    currentPaymentOrder.price > 0 && currentPaymentOrder.price < 0.5 ?
                                                    null
                                                    :
                                                    <StripeCheckout 
                                                        page="Book Purchase"
                                                        customer={currentPaymentOrder}
                                                    />
                                                }
                                                <div style={{ height: '2vh' }} />
                                                <PayPalPayment 
                                                    page="Book Purchase"
                                                    customer={currentPaymentOrder}
                                                />
                                                <div style={{ height: '2vh' }} />
                                                <CashPayment
                                                    page="Book Purchase"
                                                    customer={currentPaymentOrder}
                                                />
                                                <div style={{ height: '2vh' }} />
                                                {
                                                    currentVoucherCodeCorrect === true ?
                                                    <VoucherPayment 
                                                        id={id}
                                                    />
                                                    :
                                                    currentPaymentOrder.giftCard === true ? 
                                                    null
                                                    :
                                                    <VoucherPayment 
                                                        id={id}
                                                    />
                                                }
                                                <div style={{ height: '5vh' }} />
                                            </div>
                                        }
                                        <PublicSectorPayment 
                                            customer={currentPaymentOrder}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Pay;