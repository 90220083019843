import React from 'react';
import { fontFamily, colour, Input, ActionButton } from './GlobalStyles';
import { useSelector, useDispatch } from 'react-redux';
import { author, editor, notes, bookTitle, books, quantity } from './actions';
import './ChooseBookMobile.css';

const ChooseBookMobile = React.forwardRef((props, ref) => {

    const currentBookTitle = useSelector(state => state.bookTitle);
    const currentAuthor = useSelector(state => state.author);
    const currentEditor = useSelector(state => state.editor);
    const currentNotes = useSelector(state => state.notes);
    const currentBooks = useSelector(state => state.books);
    const currentQuantity = useSelector(state => state.quantity);
    const dispatch = useDispatch();

    const selectBook = () => {

        const dataIndex = currentBooks.findIndex(e => e.title === currentBookTitle);

        if ( dataIndex === -1 ) {

            const addingArray = [
                                {
                                    title: currentBookTitle,
                                    author: currentAuthor,
                                    editor: currentEditor,
                                    notes: currentNotes,
                                    inStock: null,
                                    delay: false,
                                    delayDays: 0,
                                    price: 0,
                                    change: false,
                                    quantity: currentQuantity
                                }
                            ];
            const newArray = currentBooks.concat(addingArray);
            dispatch(books(newArray));
            props.onClick();
            dispatch(bookTitle(""));
            dispatch(author(""));
            dispatch(editor(""));
            dispatch(notes(""));
        }
        else {
            alert("Ooppss...Non puoi avere due volumi con lo stesso titolo!");
        }
    }

    const styles = {
        headerText: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontWeight: 'normal',
            fontSize: '1.125em',
            textAlign: 'center'
        }
    }

    return (
        <div className="chooseBookMobileCentre">
            <h1 style={styles.headerText}>
                Scegli il tuo Libro
            </h1>
            <form>
                <Input
                    value={currentBookTitle}
                    id="outlined"
                    label="Titolo"
                    inputProps={{
                        autoCapitalize: 'none',
                    }}
                    variant="outlined"
                    onChange={(text) => dispatch(bookTitle(text.target.value))}
                />
                <div style={{ height: '2vh' }} />
                <Input
                    value={currentQuantity}
                    id="outlined"
                    label="Quantità"
                    inputProps={{
                        autoCapitalize: 'none',
                    }}
                    variant="outlined"
                    onChange={(text) => text.target.value === "" ? dispatch(quantity(text.target.value)) : Number(text.target.value) === 0 ? null : isNaN(Number(text.target.value)) ? null : dispatch(quantity(text.target.value))}
                />
                <div style={{ height: '2vh' }} />
                <Input
                    value={currentAuthor}
                    id="outlined"
                    label="Autore"
                    inputProps={{
                        autoCapitalize: 'none',
                    }}
                    variant="outlined"
                    onChange={(text) => dispatch(author(text.target.value))}
                />
                <div style={{ height: '2vh' }} />
                <Input
                    value={currentEditor}
                    id="outlined"
                    label="Editore"
                    inputProps={{
                        autoCapitalize: 'none',
                    }}
                    variant="outlined"
                    onChange={(text) => dispatch(editor(text.target.value))}
                />
                <div style={{ height: '2vh' }} />
                <Input
                    value={currentNotes}
                    id="outlined"
                    label="Note"
                    inputProps={{
                        autoCapitalize: 'none',
                    }}
                    variant="outlined"
                    onChange={(text) => dispatch(notes(text.target.value))}
                />
                <div style={{ height: '2vh' }} />
                <ActionButton
                    onClick={() => selectBook()}
                    disabled={
                                currentBookTitle === "" ?
                                true
                                :
                                currentAuthor === "" ?
                                true
                                :
                                false
                            }
                >
                    Invia
                </ActionButton>
            </form>
        </div>
    )
})

export default ChooseBookMobile;