import React from 'react';
import './InternalSiteBooks.css';
import { fontFamily, colour, DesktopFeeInput, PrimarySwitch } from './GlobalStyles';
import SelectStock from './SelectStock';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useSelector, useDispatch } from 'react-redux';
import { internalSiteData, focus } from './actions';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function InternalSiteBooks({ customerItem, customerIndex, currentInternalSiteExpanded }) {

    const currentInternalSiteIndex = useSelector(state => state.internalSiteIndex);
    const currentShopName = useSelector(state => state.shopName);
    const currentInternalSiteData = useSelector(state => state.internalSiteData);
    const dispatch = useDispatch();

    const bookHeaders = [
        {
            title: "Titolo"
        },
        {
            title: "Note dal Cliente"
        },
        {
            title: "Disponibilità"
        },
        {
            title: "Importo"
        },
        {
            title: "Conferma"
        }
    ]

    function setCustomPrice(text, item, index) {
        const cloneArray = currentInternalSiteData.slice();
        cloneArray[customerIndex].books[index].price = text.target.value;
        cloneArray[customerIndex].books[index].change = true;
        dispatch(internalSiteData(cloneArray));
    }

    function submitPrice(item, index) {
        const db = firebase.firestore();
        const customerDataRef = db.collection("Test Customer Data").doc(currentShopName);
        const currentText = item.price;
    
        db.runTransaction((transaction) => {
            return transaction.get(customerDataRef).then((doc) => {
                if (!doc.exists) {
                    return
                }
                else {
                    const data = doc.data();
                    const customerData = data.customerData;
                    const dataIndex = customerData.findIndex(e => e.code === customerItem.code);
                    const bookIndex = customerData[dataIndex].books.findIndex(e => e.title === item.title);
                    customerData[dataIndex].books[bookIndex].price = Number(currentText);

                    const totalPrice = [];
                    for (let i = 0; i < customerData[dataIndex].books.length; i++) {
                        totalPrice.push(Number(customerData[dataIndex].books[i].price));
                    }

                    customerData[dataIndex].price = Number(totalPrice.reduce((a, b) => a + b, 0));
                    customerData[dataIndex].books[bookIndex].change = false;

                    const anotherClone = currentInternalSiteData.slice();
                    anotherClone[dataIndex].price = Number(totalPrice.reduce((a, b) => a + b, 0));
                    anotherClone[dataIndex].books[bookIndex].change = false;
                    dispatch(internalSiteData(anotherClone));

                    transaction.update(customerDataRef, 
                        { 
                            customerData
                        });
                        return customerData;
                }
            })
        }).then(() => {
            console.log("Promise compiled with success");
            submitCustomerOrder(item);
            submitCustomerDelayDays(item, index)
        })
        .catch((err) => {
            console.log("Transaction Error =", err);
        })
    }

    function submitCustomerOrder(item) {
        const db = firebase.firestore();
        const customerOrderRef = db.collection("Customer Orders").doc(customerItem.code);
        const currentText = item.price;
    
        db.runTransaction((transaction) => {
            return transaction.get(customerOrderRef).then((doc) => {
                if (!doc.exists) {
                    return
                }
                else {
                    const data = doc.data();
                    const books = data.books;
                    const bookIndex = books.findIndex(e => e.title === item.title);
                    data.books[bookIndex].price = Number(currentText);

                    const totalPrice = [];
                    for (let i = 0; i < data.books.length; i++) {
                        totalPrice.push(Number(data.books[i].price));
                    }

                    data.price = Number(totalPrice.reduce((a, b) => a + b, 0));
                    data.books[bookIndex].change = false;

                    transaction.update(customerOrderRef, 
                        { 
                            books: data.books,
                            price: data.price
                        });
                        return data;
                }
            })
        }).then(() => {
            console.log("Promise compiled with success");
        })
        .catch((err) => {
            console.log("Transaction Error =", err);
        })
    }

    function submitCustomerDelayDays(item, index) {
        const db = firebase.firestore();
        const customerDataRef = db.collection("Test Customer Data").doc(currentShopName);
    
        db.runTransaction((transaction) => {
            return transaction.get(customerDataRef).then((doc) => {
                if (!doc.exists) {
                    return
                }
                else {
                    const data = doc.data();
                    const customerData = data.customerData;
                    const dataIndex = customerData.findIndex(e => e.code === customerItem.code);
                    const bookIndex = customerData[dataIndex].books.findIndex(e => e.title === item.title);
                    customerData[dataIndex].books[bookIndex].delayDays = currentInternalSiteData[customerIndex].books[index].delayDays;
    
                    transaction.update(customerDataRef, 
                      { 
                          customerData
                      });
                      return customerData;
                }
              })
            })
            .then(() => {
              console.log("Promise compiled with success");
              submitDelayDay(item, index);
            })
            .catch((err) => {
                console.log("Transaction Error =", err);
            })
      }
    
      function submitDelayDay(item, index) {
        const db = firebase.firestore();
        const customerOrderRef = db.collection("Customer Orders").doc(customerItem.code);
    
        db.runTransaction((transaction) => {
          return transaction.get(customerOrderRef).then((doc) => {
              if (!doc.exists) {
                  return
              }
              else {
                  const data = doc.data();
                 
                  const bookIndex = data.books.findIndex(e => e.title === item.title);
                  data.books[bookIndex].delayDays = currentInternalSiteData[customerIndex].books[index].delayDays;
    
                  transaction.update(customerOrderRef, 
                    { 
                        books: data.books
                    });
                    return data;
              }
            })
          })
      }

      function changeSwitch(event, item, index) {

        const cloneArray = currentInternalSiteData.slice();
        cloneArray[customerIndex].books[index].delay = !cloneArray[customerIndex].books[index].delay;
        dispatch(internalSiteData(cloneArray));

        const db = firebase.firestore();
        const customerDataRef = db.collection("Test Customer Data").doc(currentShopName);

        db.runTransaction((transaction) => {
            return transaction.get(customerDataRef).then((doc) => {
                if (!doc.exists) {
                    return;
                }
                else {
                    const data = doc.data();
                    const customerData = data.customerData;
                    const dataIndex = customerData.findIndex(e => e.code === customerItem.code);
                    const bookIndex = customerData[dataIndex].books.findIndex(e => e.title === item.title);
                    customerData[dataIndex].books[bookIndex].delay = !customerData[dataIndex].books[bookIndex].delay;

                    transaction.update(customerDataRef,
                        {
                            customerData
                        })
                        return customerData;
                }
            })
        })
        .then(() => {
            console.log('Promise compiled with success!');
            customerOrderSwitch(item, index);
        })
        .catch((err) => {
            console.log('Transaction error =', err);
        })
      }

      function customerOrderSwitch(item, index) {

        const db = firebase.firestore();
        const customerOrderRef = db.collection("Customer Orders").doc(customerItem.code);
    
        db.runTransaction((transaction) => {
          return transaction.get(customerOrderRef).then((doc) => {
              if (!doc.exists) {
                  return
              }
              else {
                  const data = doc.data();
                  const bookIndex = data.books.findIndex(e => e.title === item.title);
                  data.books[bookIndex].delay = !data.books[index].delay;
    
                  transaction.update(customerOrderRef, 
                    { 
                        books: data.books
                    });
                    return data;
              }
            })
          })
          .then(() =>{
              console.log('Promis compiled with success!');
          })
          .catch((err) => {
              console.log('Error =', err);
          })
      }


    const styles = {
        headerText: {
            fontFamily: fontFamily,
            color: colour.med,
            fontWeight: 'normal',
            fontSize: '1.125em'
        },
        internalSiteListContainer: {
            marginTop: '2vh'
        },
        bookTitle: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontWeight: 'bold',
            fontSize: '1.125em'
        },
        strikeThroughBookTitle: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontWeight: 'bold',
            fontSize: '1.125em',
            textDecorationLine: 'line-through'
        },
        bookDescription: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontWeight: 'normal',
            fontSize: '1.125em'
        },
        strikeThroughBookDescription: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontWeight: 'normal',
            fontSize: '1.125em',
            textDecorationLine: 'line-through'
        },
        notes: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontWeight: 'normal',
            fontSize: '1.125em',
            fontStyle: 'italic'
        },
        strikeThroughNotes: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontWeight: 'normal',
            fontSize: '1.125em',
            fontStyle: 'italic',
            textDecorationLine: 'line-through'
        },
        feeInput: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
        },
        price: {
            fontFamily: fontFamily,
            color: colour.med,
            fontWeight: 'normal',
            fontSize: '1.125em',
            paddingLeft: '3%'
        },
        available: {
            fontFamily: fontFamily,
            color: colour.med,
            fontWeight: 'bold',
            fontSize: '1.125em',
            paddingLeft: '3%'
        }
    }

    return (
        currentInternalSiteExpanded.indexOf(customerIndex) === -1 ?
        null
        :
        <div className="internalSiteBooksContainer">
            <div className="internalSiteBooksHeader">
                {
                    bookHeaders.map((item, index) =>
                        <div>
                            <p style={styles.headerText}>
                                {item.title}
                            </p>
                        </div>
                    )
                }
            </div>
            <div style={styles.internalSiteListContainer}>
                {
                    customerItem.books.map((item, index) =>
                        <div className="internalSiteBookGrid">
                            <div className="internalSiteBookTitleRow">
                                <div className="internalSiteBookNumber">
                                    <p style={styles.bookTitle}>
                                        {index + 1}.
                                    </p>
                                </div>
                                <div>
                                    <p style={currentInternalSiteIndex === 0 ? styles.bookTitle : item.inStock === true ? styles.bookTitle : styles.strikeThroughBookTitle}>
                                        {item.title}
                                    </p>
                                    <p style={currentInternalSiteIndex === 0 ? styles.bookDescription : item.inStock === true ? styles.bookDescription : styles.strikeThroughBookDescription}>
                                        {item.author}
                                    </p>
                                    <p style={currentInternalSiteIndex === 0 ? styles.bookDescription : item.inStock === true ? styles.bookDescription : styles.strikeThroughBookDescription}>
                                        {item.editor}
                                    </p>
                                    <p style={currentInternalSiteIndex === 0 ? styles.bookDescription : item.inStock === true ? styles.bookDescription : styles.strikeThroughBookDescription}>
                                        Quantità: {item.quantity !== undefined ? item.quantity : 1}
                                    </p>
                                </div>
                            </div>
                            <div>
                                <p style={item.inStock === true ? styles.notes : currentInternalSiteIndex === 0 ? styles.notes : styles.strikeThroughNotes}>
                                    {item.notes}
                                </p>
                            </div>
                            <div>
                                {
                                    currentInternalSiteIndex !== 0 && item.delayDays !== 0 ?
                                    <FormControlLabel
                                        control={
                                                    <PrimarySwitch 
                                                        checked={!item.delay} 
                                                        onChange={(event) => changeSwitch(event, item, index)} 
                                                        name="delay" 
                                                    />
                                                }
                                        label="Libro Arrivato"
                                        style={{ color: colour.med, fontFamily: fontFamily, fontSize: '1.1125em', fontWeight: 'normal' }}
                                    />
                                    :
                                    currentInternalSiteIndex !== 0 ?
                                    <p style={styles.available}>
                                        {
                                            item.inStock === true ?
                                            "Disponibile"
                                            :
                                            "Non Ordinabile"
                                        }
                                    </p>
                                    :
                                    <SelectStock 
                                        customerItem={customerItem}
                                        customerIndex={customerIndex}
                                        item={item}
                                        index={index}
                                    />
                                }
                            </div>
                            {
                            currentInternalSiteIndex !== 0 ?
                            <p style={styles.price}>
                                {
                                    item.inStock === true || currentInternalSiteIndex === 0 ?
                                    "€ " + item.price
                                    :
                                    null
                                }
                            </p>
                            :
                            <div style={styles.feeInput}>
                                <DesktopFeeInput 
                                    value={item.price !== undefined ? item.price : "0,00"}
                                    id="outlined"
                                    label=""
                                    inputProps={{
                                        autoCapitalize: 'none',
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">€</InputAdornment>
                                    }}
                                    variant="outlined"
                                    onFocus={() => dispatch(focus(true))}
                                    onBlur={() => dispatch(focus(false))}
                                    onChange={(text) => isNaN(Number(text.target.value)) ? null : setCustomPrice(text, item, index)}
                                    disabled={item.inStock === true ? false : true}
                                />
                            </div>
                            }
                             <div className="circleIconContainer">
                                {
                                    item.inStock === true ?
                                    <CheckCircleIcon
                                        style={{
                                                fontSize: 50,
                                                color: item.price === 0 ? colour.border : item.price === "0.00" ? item.price === "0" ? colour.border : colour.border : item.price === "" ? colour.border : item.change === true ? "#F8DB02" :  item.price !== undefined ? colour.green : colour.border, 
                                                cursor: item.change === false ? 'auto' : item.price === 0 ? 'auto' : item.price === "" ? colour.border : item.price !== undefined ? 'pointer' : 'auto'
                                            }}
                                        className={item.change === false ? "" : item.price === 0 ? "" : item.price === "" ? "" : item.price !== undefined ? "internalSiteTickHover" : ""}
                                        onClick={() => item.change === false ? null : item.price === 0 ? null : item.price === "" ? null : item.price === "0.00" ? null : item.price === "0" ? null : item.price !== undefined ? submitPrice(item, index) : null}
                                    />
                                    :
                                    null
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default InternalSiteBooks;