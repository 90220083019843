import Button from '@material-ui/core/Button';
import { styled, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';

export const fontFamily = '"Roboto", Helvetica, Sans-Serif'
export const georgiaFontFamily = '"Georgia", Helvetica, Sans-Serif'

export const colour = {
    primary: '#D51325',
    primaryAccent: "#E84A5F",
    primarySubtle: "#FFAAA6",
    primaryMuted: '#CECE00',
    gradient: 'linear-gradient(180deg, #D4FC30 30%, #CECE00 90%)',
    dark: '#1F1F1F',
    lightestGray: "#F4F6F8",
    med: '#A5A5A5',
    light: '#FDFDFD',
    accentMuted: "#87C90C",
    darkGradient: 'linear-gradient(180deg, #282929 30%, #000000 90%)',
    secondary: "#A8E6CE",
    border: "#707070",
    blue: "#2D8EB9",
    green: "#2DB968"
}

export const ActionButton = styled(Button)({
    background: colour.primary,
    border: 0,
    color: colour.light,
    fontWeight: 'bold',
    fontFamily: fontFamily,
    borderRadius: '30px',
    fontSize: '1.125em',
    width: '90vw',
    textTransform: 'none',
    '&:hover': {
      background: colour.primarySubtle,
      animation: 'ease 2s'
   },
  });

  export const DesktopActionButton = styled(Button)({
    background: colour.primary,
    border: 0,
    color: colour.light,
    fontWeight: 'bold',
    fontFamily: fontFamily,
    borderRadius: '30px',
    fontSize: '1.125em',
    width: '40vw',
    textTransform: 'none',
    '&:hover': {
      background: colour.primarySubtle,
      animation: 'ease 2s'
   },
  });

  export const DesktopShortActionButton = styled(Button)({
    background: colour.primary,
    border: 0,
    color: colour.light,
    fontWeight: 'bold',
    fontFamily: fontFamily,
    borderRadius: '30px',
    fontSize: '1.125em',
    width: '30vw',
    textTransform: 'none',
    '&:hover': {
      background: colour.primarySubtle,
      animation: 'ease 2s'
   },
  });

  export const HeaderButton = styled(Button)({
    background: colour.primary,
    border: 0,
    color: colour.light,
    fontWeight: 'bold',
    fontFamily: fontFamily,
    borderRadius: '30px',
    fontSize: '1.125em',
    width: '20vw',
    textTransform: 'none',
    '&:hover': {
      background: colour.primarySubtle,
      animation: 'ease 2s'
   },
  });

  export const ModalButton = styled(Button)({
    background: colour.green,
    border: 0,
    color: colour.light,
    fontWeight: 'bold',
    fontFamily: fontFamily,
    borderRadius: '5px',
    fontSize: '1.125em',
    width: '10vw',
    textTransform: 'none',
    '&:hover': {
      background: colour.green,
      opacity: 0.7,
      animation: 'ease 2s'
   },
  });

  export const ModalButtonMobile = styled(Button)({
    background: colour.green,
    border: 0,
    color: colour.light,
    fontWeight: 'bold',
    fontFamily: fontFamily,
    borderRadius: '5px',
    fontSize: '1.125em',
    width: '60vw',
    textTransform: 'none',
    '&:hover': {
      background: colour.green,
      opacity: 0.7,
      animation: 'ease 2s'
   },
  });

  export const BlackButton = styled(Button)({
    background: colour.dark,
    border: 0,
    color: colour.light,
    fontWeight: 'bold',
    fontFamily: fontFamily,
    borderRadius: '30px',
    fontSize: '1.125em',
    width: '90vw',
    textTransform: 'none',
    '&:hover': {
      background: colour.dark,
      opacity: '0.7',
      animation: 'ease 2s'
   },
  });

  export const DesktopBlackButton = styled(Button)({
    background: colour.dark,
    border: 0,
    color: colour.light,
    fontWeight: 'bold',
    fontFamily: fontFamily,
    borderRadius: '30px',
    fontSize: '1.125em',
    width: '20vw',
    textTransform: 'none',
    '&:hover': {
      background: colour.dark,
      opacity: '0.7',
      animation: 'ease 2s'
   },
  });

  export const ModalDeleteButton = styled(Button)({
    background: colour.primary,
    border: 0,
    color: colour.light,
    fontWeight: 'bold',
    fontFamily: fontFamily,
    borderRadius: '5px',
    fontSize: '1.125em',
    width: '10vw',
    textTransform: 'none',
    '&:hover': {
      background: colour.primarySubtle,
      animation: 'ease 2s'
   },
  });

  export const InternalSiteSaveButton = styled(Button)({
    background: colour.lightestGray,
    border: 0,
    color: colour.blue,
    fontWeight: 'bold',
    fontFamily: fontFamily,
    borderRadius: '30px',
    fontSize: '1em',
    width: '12vw',
    textTransform: 'none',
    '&:hover': {
      background: colour.blue,
      animation: 'ease 2s',
      color: colour.lightestGray
   },
  });

  export const Input = withStyles({
    root: {
      '& label': {
        color: colour.med,
        fontFamily: fontFamily,
        paddingLeft: '1.5em'
      },
      '& label.Mui-focused': {
        color: colour.med,
        paddingLeft: 0
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: colour.med,
      },
      '& .MuiOutlinedInput-root': {
        fontFamily: fontFamily,
        color: colour.dark,
        backgroundColor: '#F8F8F8',
        borderRadius: '30px',
        '& fieldset': {
          borderRadius: '30px',
          borderWidth: 0
        },
        '&:hover fieldset': {
          borderColor: colour.med,
        },
        '&.Mui-focused fieldset': {
          borderColor: colour.primary
        },
      },
      width: '90vw',
    },
  })(TextField);

  export const SmallDesktopInput = withStyles({
    root: {
      '& label': {
        color: colour.med,
        fontFamily: fontFamily,
        paddingLeft: '1.5em'
      },
      '& label.Mui-focused': {
        color: colour.med,
        paddingLeft: 0
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: colour.med,
      },
      '& .MuiOutlinedInput-root': {
        fontFamily: fontFamily,
        color: colour.dark,
        backgroundColor: '#F8F8F8',
        borderRadius: '30px',
        '& fieldset': {
          borderRadius: '30px',
          borderWidth: 0
        },
        '&:hover fieldset': {
          borderColor: colour.med,
        },
        '&.Mui-focused fieldset': {
          borderColor: colour.primary
        },
      },
      width: '25vw',
    },
  })(TextField);

  export const NumberInput = withStyles({
    root: {
      '& label': {
        color: colour.med,
        fontFamily: fontFamily,
        paddingLeft: '1.5em'
      },
      '& label.Mui-focused': {
        color: colour.med,
        paddingLeft: 0
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: colour.med,
      },
      '& .MuiOutlinedInput-root': {
        fontFamily: fontFamily,
        color: colour.dark,
        backgroundColor: '#F8F8F8',
        borderRadius: '30px',
        '& fieldset': {
          borderRadius: '30px',
          borderWidth: 0
        },
        '&:hover fieldset': {
          borderColor: colour.med,
        },
        '&.Mui-focused fieldset': {
          borderColor: colour.primary
        },
      },
      width: '15vw',
    },
  })(TextField);

  export const DesktopInput = withStyles({
    root: {
      '& label': {
        color: colour.med,
        fontFamily: fontFamily,
        paddingLeft: '1.5em'
      },
      '& label.Mui-focused': {
        color: colour.med,
        paddingLeft: 0
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: colour.med,
      },
      '& .MuiOutlinedInput-root': {
        fontFamily: fontFamily,
        color: colour.dark,
        backgroundColor: '#F8F8F8',
        borderRadius: '30px',
        '& fieldset': {
          borderRadius: '30px',
          borderWidth: 0
        },
        '&:hover fieldset': {
          borderColor: colour.med,
        },
        '&.Mui-focused fieldset': {
          borderColor: colour.primary
        },
      },
      width: '40vw',
    },
  })(TextField);

  export const VoucherInput = withStyles({
    root: {
      '& label': {
        color: colour.med,
        fontFamily: fontFamily,
        paddingLeft: '1.5em'
      },
      '& label.Mui-focused': {
        color: colour.med,
        paddingLeft: 0
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: colour.med,
      },
      '& .MuiOutlinedInput-root': {
        fontFamily: fontFamily,
        color: colour.dark,
        backgroundColor: '#F8F8F8',
        borderRadius: '30px',
        '& fieldset': {
          borderRadius: '30px',
          borderWidth: 0
        },
        '&:hover fieldset': {
          borderColor: colour.med,
        },
        '&.Mui-focused fieldset': {
          borderColor: colour.primary
        },
      },
      width: '20vw',
    },
  })(TextField);

  export const DesktopFeeInput = withStyles({
    root: {
      '& label': {
        color: colour.med,
        fontFamily: fontFamily,
        paddingLeft: '0.5em'
      },
      '& label.Mui-focused': {
        color: colour.med,
        paddingLeft: 0
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: colour.med,
      },
      '& .MuiOutlinedInput-root': {
        fontFamily: fontFamily,
        color: colour.med,
        fontWeight: 'bold',
        backgroundColor: colour.lightestGray,
        '& fieldset': {
          borderWidth: 0
        },
        '&:hover fieldset': {
          borderColor: colour.med,
        },
        '&.Mui-focused fieldset': {
          borderColor: colour.primary,
        },
      },
      width: '7vw',
    },
  })(TextField);

  export const DesktopDelayInput = withStyles({
    root: {
      '& label': {
        color: colour.med,
        fontFamily: fontFamily,
        paddingLeft: '0.5em'
      },
      '& label.Mui-focused': {
        color: colour.med,
        paddingLeft: 0
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: colour.med,
      },
      '& .MuiOutlinedInput-root': {
        fontFamily: fontFamily,
        color: colour.med,
        fontWeight: 'bold',
        backgroundColor: colour.lightestGray,
        '& fieldset': {
          borderWidth: 0
        },
        '&:hover fieldset': {
          borderColor: colour.med,
        },
        '&.Mui-focused fieldset': {
          borderColor: colour.primary,
        },
      },
      width: '10vw',
    },
  })(TextField);

  export const PrimarySwitch = withStyles({
    switchBase: {
      color: colour.primary,
      '&$checked': {
        color: colour.primary,
      },
      '&$checked + $track': {
        backgroundColor: colour.primary,
      },
    },
    checked: {},
    track: {},
  })(Switch);
  

  export const DesktopShortInput = withStyles({
    root: {
      '& label': {
        color: colour.med,
        fontFamily: fontFamily,
        paddingLeft: '1.5em'
      },
      '& label.Mui-focused': {
        color: colour.med,
        paddingLeft: 0
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: colour.med,
      },
      '& .MuiOutlinedInput-root': {
        fontFamily: fontFamily,
        color: colour.dark,
        backgroundColor: '#F8F8F8',
        borderRadius: '5px',
        '& fieldset': {
          borderRadius: '5px',
          borderWidth: 0
        },
        '&:hover fieldset': {
          borderColor: colour.med,
        },
        '&.Mui-focused fieldset': {
          borderColor: colour.primary
        },
      },
      width: '20vw',
    },
  })(TextField);

  export const DesktopLongFeeInput = withStyles({
    root: {
      '& label': {
        color: colour.med,
        fontFamily: fontFamily,
        paddingLeft: '0.5em'
      },
      '& label.Mui-focused': {
        color: colour.med,
        paddingLeft: 0
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: colour.med,
      },
      '& .MuiOutlinedInput-root': {
        fontFamily: fontFamily,
        color: colour.med,
        fontWeight: 'bold',
        backgroundColor: colour.lightestGray,
        '& fieldset': {
          borderWidth: 0
        },
        '&:hover fieldset': {
          borderColor: colour.med,
        },
        '&.Mui-focused fieldset': {
          borderColor: colour.primary,
        },
      },
      width: '20vw',
    },
  })(TextField);

  export const MultilineInput = withStyles({
    root: {
      '& label': {
        color: colour.med,
        fontFamily: fontFamily,
        paddingLeft: '1.5em'
      },
      '& label.Mui-focused': {
        color: colour.med,
        paddingLeft: 0
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: colour.med,
      },
      '& .MuiOutlinedInput-root': {
        fontFamily: fontFamily,
        color: colour.dark,
        backgroundColor: '#F8F8F8',
        borderRadius: '30px',
        height: '30vh',
        '& fieldset': {
          borderRadius: '30px',
          borderWidth: 0,
          height: '30vh',
        },
        '&:hover fieldset': {
          borderColor: colour.med,
        },
        '&.Mui-focused fieldset': {
          borderColor: colour.primary
        },
      },
      width: '90vw',
    },
  })(TextField);

  export const DesktopMultilineInput = withStyles({
    root: {
      '& label': {
        color: colour.med,
        fontFamily: fontFamily,
        paddingLeft: '1.5em'
      },
      '& label.Mui-focused': {
        color: colour.med,
        paddingLeft: 0
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: colour.med,
      },
      '& .MuiOutlinedInput-root': {
        fontFamily: fontFamily,
        color: colour.dark,
        backgroundColor: '#F8F8F8',
        borderRadius: '30px',
        height: '20vh',
        '& fieldset': {
          borderRadius: '30px',
          borderWidth: 0,
          height: '20vh',
        },
        '&:hover fieldset': {
          borderColor: colour.med,
        },
        '&.Mui-focused fieldset': {
          borderColor: colour.primary
        },
      },
      width: '40vw',
    },
  })(TextField);