import React from 'react';
import { fontFamily, colour, ActionButton } from './GlobalStyles';
import { useSelector } from 'react-redux';
import './BookListMobile.css';

const BookListMobile = React.forwardRef((props, ref) => {

    const currentBooks = useSelector(state => state.books);

    const styles = {
        headerText: {
            fontFamily: fontFamily, 
            color: colour.dark,
            fontWeight: 'normal',
            fontSize: '1.375em',
            textAlign: 'center'
        },
        title: {
            fontFamily: fontFamily, 
            color: colour.dark,
            fontWeight: 'bold',
            fontSize: '0.875em',
            margin: '0.5em'
        },
        author: {
            fontFamily: fontFamily, 
            color: colour.dark,
            fontWeight: 'normal',
            fontSize: '0.875em',
            margin: '0.5em'
        },
        details: {
            fontFamily: fontFamily, 
            color: colour.med,
            fontWeight: 'normal',
            fontSize: '0.875em',
            margin: '0.5em'
        },
        anotherBook: {
            fontFamily: fontFamily, 
            color: colour.primary,
            fontWeight: 'bold',
            fontSize: '1.125em',
            textAlign: 'center',
            cursor: 'pointer',
            margin: 0
        },
        back: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontSize: '0.875em',
            fontWeight: 'normal',
            textAlign: 'center',
            cursor: 'pointer'
        }
    }

    return (
        <div>
            <h1 style={styles.headerText}>
                Libri Richiesti
            </h1>
            <div className="bookListCentre">
                {
                    currentBooks.map((item, index) =>
                        <div className="bookListMobile">
                            <p style={styles.title}>
                                {item.title}
                            </p>
                             <p style={styles.author}>
                                {item.author}
                            </p>
                             <p style={styles.details}>
                                {item.editor}
                            </p>
                             <p style={styles.details}>
                                {item.notes}
                            </p>
                        </div>
                    )
                }
                <div style={{ height: '2vh' }} />
                <h2 
                    style={styles.anotherBook}
                    onClick={() => props.onBack()}
                >
                    + Aggiungi un altro libro
                </h2>
                <div style={{ height: '2vh' }} />
                <ActionButton
                    onClick={() => props.onClick()}
                >
                    Continua
                </ActionButton>
            </div>
        </div>
    )
})

export default BookListMobile;