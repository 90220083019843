import React from 'react';
import { shopName, companyName } from './ShopData';
import './Footer.css';
import { fontFamily, colour } from './GlobalStyles';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import * as firebase from 'firebase/app';
import '@firebase/firestore';

function Footer() {

    const facebookLink = shopName === "Crema" ?
                            "https://www.facebook.com/mondadori.crema/"
                            :
                            shopName === "Trento" ?
                            "https://www.facebook.com/mondadobookstoretrento/"
                            :
                            shopName === "Varazze" ?
                            "https://it-it.facebook.com/mondadorivarazze/"
                            :
                            shopName === "Acqui Terme" ?
                            "https://it-it.facebook.com/mondadori.acquiterme/"
                            :
                            shopName === "Sestri Ponente" ?
                            "https://en-gb.facebook.com/mondadorisestriponente"
                            :
                            shopName === "Sestro Levante" ?
                            "https://it-it.facebook.com/mondadorisestrilevante/"
                            :
                            shopName === "Loano" ?
                            "https://www.facebook.com/mondadoriloano/?rf=740350219442932"
                            :
                            "https://www.facebook.com/mondadori.crema/";

    const instagramLink = shopName === "Crema" ?
                            "https://www.instagram.com/mondadoricrema/?hl=en"
                            :
                            shopName === "Trento" ?
                            "https://www.instagram.com/mondadoritrento/?hl=en"
                            :
                            shopName === "Varazze" ?
                            "https://www.instagram.com/mondadorivarazze/?hl=en"
                            :
                            shopName === "Acqui Terme" ?
                            "https://www.instagram.com/mondadoriacquiterme/?hl=en"
                            :
                            shopName === "Sestri Ponente" ?
                            "https://www.instagram.com/mondadorisestrip.bookstore/?hl=bn"
                            :
                            shopName === "Sestri Levante" ?
                            "https://www.instagram.com/mondadorisestri/?hl=en"
                            :
                            shopName === "Loano" ?
                            "https://www.instagram.com/mondadoribookstoreloano/?hl=en"
                            :
                            "https://www.instagram.com/mondadoricrema/?hl=en";

    let history = useHistory();

    function isLoggedIn() {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                history.push('../sitoInterno');
            }
            else {
                history.push('../accedi');
            }
        })
    }

    function openFacebook() {
        window.open(facebookLink, '_blank');
    }

    function openInstagram() {
        window.open(instagramLink, '_blank');
    }

    const styles = {
        footerElements: {
            fontFamily: fontFamily, 
            color: colour.light,
            fontSize: '1em',
            fontWeight: 'bold'
        },
        footerElementText: {
            fontFamily: fontFamily, 
            color: colour.light,
            fontSize: '1em',
            fontWeight: 'normal',
            cursor: 'pointer'
        },
        footerText: {
            fontFamily: fontFamily, 
            color: colour.light,
            fontSize: '1em',
            fontWeight: 'normal'
        }
    }

    const footerElements = [
        {
            title: "Menu",
            subheaders: [
                {
                    title: "Ordina un Libro"
                },
                {
                    title: "Regala Gift Card"
                }
            ]
        },
        {
            title: "Informazioni",
            subheaders: [
                {
                    title: "18App | Carta del Docente"
                },
                {
                    title: "Staff"
                }
            ]
        },
        {
            title: "Legal",
            subheaders: [
                {
                    title: "Informativa Privacy"
                },
                {
                    title: "Termini e Condizioni"
                }
            ]
        },
        {
            title: "Social",
            subheaders: [
                {
                    title: "Facebook",
                    path: "./facebook-logo.svg"
                },
                {
                    title: "Instagram",
                    path: "./instagram-logo.svg"
                }
            ]
        }
    ]

    return (  
        isMobile === true ?
        <div className="footerMobileOuterContainer">
            <div className="footerMobileWidth">
                <div className="footerLogoMobileContainer">
                    <img
                        src={require('./Mondadori-logomark.svg')}
                        className="footerLogoMobile"
                        alt="Logo Mondadori"
                    />
                </div>
                <div style={{ height: '5vh' }} />
                {
                    footerElements.map((item, index) =>
                        <div className={index === 3 ? "socialMediaMobileContainer" : "" }>
                            {
                                index === 3 ?
                                ""
                                :
                                <p style={styles.footerElements}>
                                    {item.title}
                                </p>
                            }
                            {
                                index === 3 ?
                                    item.subheaders.map((item, index) =>
                                        <img
                                            src={require(index === 0 ? './iconmonstr-facebook-1.svg' : './instagram-logo.svg')}
                                            className="socialMediaMobile"
                                            alt={item.title}
                                        />
                                    )
                                :
                                item.subheaders.map((item, index) =>
                                    <p 
                                        onClick={() => 
                                            item.title === 'Staff' && isLoggedIn() === true ? 
                                            history.push('../sitoInterno') 
                                            : 
                                            item.title === 'Staff' === true ? 
                                            history.push('../accedi') 
                                            : 
                                            item.title === 'Ordina un Libro' === true ?
                                            history.push('../')
                                            :
                                            item.title === '18App | Carta del Docente' === true ?
                                            history.push('../18App')
                                            :
                                            item.title === 'Regala Gift Card' === true ?
                                            history.push('../giftCard')
                                            :
                                            null
                                        }
                                        style={styles.footerElementText}
                                    >
                                        {item.title}
                                    </p>
                                )
                            }
                        </div>
                    )
                }
            </div>
            <div className="mobileFooterCompanyTag">
                <p style={styles.footerText}>
                    {shopName}
                </p>
                <p style={styles.footerText}>
                    {companyName}
                </p>
            </div>
        </div>
        :
        <div className="footerOuterContainer">
            <div className="footerLogoContainer">
                <img
                    src={require('./Mondadori-logomark.svg')}
                    className="footerLogo"
                    alt="Logo Mondadori"
                />
            </div>
            <div className="footerGrid">
                {
                    footerElements.map((item, index) =>
                        <div>
                            <p style={styles.footerElements}>
                                {item.title}
                            </p>
                            {
                                index === 3 ?
                                    item.subheaders.map((item, index) =>
                                        <img
                                            src={require(index === 0 ? './iconmonstr-facebook-1.svg' : './instagram-logo.svg')}
                                            className="socialMedia"
                                            alt={item.title}
                                            onClick={() => index === 0 ? openFacebook() : openInstagram()}
                                        />
                                    )
                                :
                                item.subheaders.map((item, index) =>
                                    <p 
                                        onClick={() => 
                                                        item.title === 'Staff' ? 
                                                        isLoggedIn()
                                                        :
                                                        item.title === 'Ordina un Libro' === true ?
                                                        history.push('../')
                                                        :
                                                        item.title === '18App | Carta del Docente' === true ?
                                                        history.push('../18App')
                                                        :
                                                        item.title === 'Regala Gift Card' === true ?
                                                        history.push('../giftCard')
                                                        :
                                                        null
                                        }
                                        style={styles.footerElementText}
                                    >
                                        {item.title}
                                    </p>
                                )
                            }
                        </div>
                    )
                }
            </div>
            <div className="footerFlex">
                <div className="footerBottom">
                    <p style={styles.footerText}>
                        {shopName}
                    </p>
                    <p style={styles.footerText}>
                        {companyName}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Footer;