import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { fontFamily, colour, ActionButton, DesktopActionButton } from './GlobalStyles';
import './BookList.css';
import { isMobile } from 'react-device-detect';

const BookList = React.forwardRef((props, ref) => {

    const currentBooks = useSelector(state => state.books);

    const bookEndRef = useRef(null)

    const scrollToBottom = () => {
        bookEndRef.current.scrollIntoView({ behavior: "smooth" })
    }
  
    useEffect(scrollToBottom, []);

    const styles = {
        headerText: {
            fontFamily: fontFamily, 
            color: colour.dark,
            fontWeight: 'normal',
            fontSize: '1.375em',
            textAlign: 'center'
        },
        title: {
            fontFamily: fontFamily, 
            color: colour.dark,
            fontWeight: 'bold',
            fontSize: '0.875em',
            margin: '0.5em'
        },
        author: {
            fontFamily: fontFamily, 
            color: colour.dark,
            fontWeight: 'normal',
            fontSize: '0.875em',
            margin: '0.5em'
        },
        details: {
            fontFamily: fontFamily, 
            color: colour.med,
            fontWeight: 'normal',
            fontSize: '0.875em',
            margin: '0.5em'
        },
        anotherBook: {
            fontFamily: fontFamily, 
            color: colour.primary,
            fontWeight: 'bold',
            fontSize: '1.125em',
            textAlign: 'center',
            cursor: 'pointer',
            margin: 0
        },
        or: {
            fontFamily: fontFamily, 
            color: colour.med,
            fontWeight: 'normal',
            fontSize: '1em',
            textAlign: 'center'
        }
    }

    return (
        <div className="bookPageOuterMostContainer">
            <div className="bookListContainer">
                <div>
                    <h1 style={styles.headerText}>
                        Libri Richiesti
                    </h1>
                </div>
                <div ref={bookEndRef} className="bookListElementsCentre">
                    {
                        currentBooks.map((item, index) =>
                            <div className="bookListItem">
                                <p style={styles.title}>
                                    {item.title}
                                </p>
                                <p style={styles.author}>
                                    {item.author}
                                </p>
                                <p style={styles.details}>
                                    {item.editor}
                                </p>
                                <p style={styles.details}>
                                    {item.notes}
                                </p>
                            </div>
                        )
                    }
                </div>
                <div className="bookListCentre">
                    <h2 
                        style={styles.anotherBook}
                        onClick={() => props.goBack()}
                    >
                        + Aggiungi un altro libro
                    </h2>
                    <div style={{ height: '1vh' }} />
                    <p style={styles.or}>
                        o
                    </p>
                    <div style={{ height: '1vh' }} />
                    {
                        isMobile === true ?
                        <ActionButton
                            ref={ref} {...props}
                        >
                            Continua
                        </ActionButton>
                        :
                        <DesktopActionButton
                            ref={ref} {...props}
                        >
                            Continua
                        </DesktopActionButton>
                    }
                </div>
            </div>
        </div>
    )
})

export default BookList;