import React from 'react';
import './GiftCardImage.css';
import { colour, fontFamily } from './GlobalStyles';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

function GiftCardImage() {

    const currentWidth = useSelector(state => state.width);
    const currentRecipientName = useSelector(state => state.recipientName);
    const currentPrice = useSelector(state => state.price);
    const currentGiftMessage = useSelector(state => state.giftMessage);

    const styles = {
        giftCardText: {
            fontFamily: fontFamily,
            color: colour.light,
            fontSize: '1.375em',
            fontWeight: 'normal',
            marginTop: 0
        },
        giftCardPrice: {
            fontFamily: fontFamily,
            color: colour.light,
            fontSize: '1.375em',
            fontWeight: 'bold',
            marginTop: 0
        },
        price: {
            fontFamily: fontFamily,
            color: colour.light,
            fontSize: '1.125em',
            fontWeight: 'normal',
        },
        giftCardMessage: {
            fontFamily: fontFamily,
            color: colour.lightestGray,
            fontSize: '0.875em',
            fontWeight: 'normal',
            whiteSpace: 'pre-line'
        }
    }

    return (
        <div className="giftCardImageOuterContainer">
            <div className={
                            isMobile === true ? "mobileGiftCardImageContainer" 
                            :
                            currentWidth < 900 ?
                            "smallGiftCardImageContainer"
                            : 
                            "giftCardImageContainer"
                            }>
                <div className={
                                isMobile === true ? 
                                "mobileGiftCardImageTextTitle"
                                :
                                currentWidth < 900 ?
                                "smallGiftCardImageTextTitle"
                                :
                                "giftCardImageTextTitle"
                            }
                >
                    <p style={styles.giftCardText}>
                        Giftcard per <span style={{ fontWeight: 'bold' }}>{currentRecipientName}</span>
                    </p>
                    <p style={styles.giftCardPrice}>
                        {
                            currentPrice === 0 ?
                            ""
                            :
                            currentPrice === "0.00" ?
                            ""
                            :
                            Number.isInteger(currentPrice) ?
                            '€' + currentPrice + ",00"
                            :
                            '€' + currentPrice
                        }
                    </p>
                </div>
                <div className={
                                isMobile === true ?
                                "mobileGiftCardImageText"
                                :
                                currentWidth < 900 ?
                                "smallGiftCardImageText"
                                :
                                "giftCardImageText"
                            }
                >
                    <p style={styles.giftCardMessage}>
                        {currentGiftMessage}
                    </p>
                </div>
                <div className="giftCardImageLogoContainerCentre">
                    <div className={
                                    isMobile === true ?
                                    "mobileGiftCardImageLogoContainer"
                                    :
                                    currentWidth < 900 ?
                                    "smallGiftCardImageLogoContainer"
                                    :
                                    "giftCardImageLogoContainer"
                                }
                    >
                        <div />
                        <img 
                            src={require('./Mondadori-logomark-dark.svg')}
                            className={
                                        isMobile === true ?
                                        "mobileGiftCardImageLogo"
                                        :
                                        currentWidth < 900 ?
                                        "smallGiftCardImageLogo" 
                                        :
                                        "giftCardImageLogo"
                                    }
                            alt="Mondadori Logo"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GiftCardImage;