import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { shopTitle, shopName, bookShopAddress, width, height, bookShopPhoneNumber, bookShopEmail } from './actions';
import { fontFamily, colour } from './GlobalStyles';
import { useHistory } from 'react-router-dom';
import * as firebase from 'firebase/app';
import '@firebase/firestore';

function InternalSiteHeader() {

    const currentShopTitle = useSelector(state => state.shopTitle);
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, []);
  
    useEffect(() => {

        function checkShop() {
            const url = window.location.href;
            url.includes("local") === true ? 
            stableDispatch(shopName("local")) &&
            stableDispatch(bookShopAddress("local")) &&
            stableDispatch(shopTitle("Local")) &&
            stableDispatch(bookShopPhoneNumber("+39 0373 80379")) &&
            stableDispatch(bookShopEmail("mondadoricremaonline@gmail.com"))
            :
            url.includes("crema") === true ? 
            stableDispatch(shopName("crema")) &&
            stableDispatch(bookShopAddress("Via Mazzini, 48\n26013 Crema CR")) &&
            stableDispatch(shopTitle("Crema")) && 
            stableDispatch(bookShopPhoneNumber("+39 0373 80379")) &&
            stableDispatch(bookShopEmail("mondadoricremaonline@gmail.com"))
            :
            url.includes("varazze") === true ? 
            stableDispatch(shopName("varazze")) &&
            stableDispatch(bookShopAddress("Piazza Nello Bovani, 10\n17019 Varazze SV")) &&
            stableDispatch(shopTitle("Varazze")) &&
            stableDispatch(bookShopPhoneNumber("+39 019 98051")) &&
            stableDispatch(bookShopEmail("mondadorivarazzeonline@gmail.com"))
            :
            url.includes("trento") === true ? 
            stableDispatch(shopName("trento")) &&
            stableDispatch(bookShopAddress("Via S. Pietro, 19\n38122 Trento TN")) &&
            stableDispatch(shopTitle("Trento")) &&
            stableDispatch(bookShopPhoneNumber("+39 0461 263828")) &&
            stableDispatch(bookShopEmail("mondadoritrentoonline@gmail.com"))
            :
            url.includes("sestriponente") === true ? 
            stableDispatch(shopName("sestriponente")) &&
            stableDispatch(bookShopAddress("Via Sestri, 46\n16154 Genova GE")) &&
            stableDispatch(shopTitle("Sestri Ponente")) &&
            stableDispatch(bookShopPhoneNumber("+39 010 653 3367")) &&
            stableDispatch(bookShopEmail("mondadorisestriponenteonline@gmail.com"))
            :
            url.includes("sestri") === true ? 
            stableDispatch(shopName("sestrilevante")) &&
            stableDispatch(bookShopAddress("Via XXV Aprile, 71/73\n16039 Sestri Levante GE")) &&
            stableDispatch(shopTitle("Sestri Levante")) &&
            stableDispatch(bookShopPhoneNumber("+39 0185 44855")) &&
            stableDispatch(bookShopEmail("mondadorisestrionline@gmail.com"))
            :
            url.includes("acqui") === true ? 
            stableDispatch(shopName("acqui")) &&
            stableDispatch(bookShopAddress("Corso Italia, 47/49\n15011 Acqui Terme AL")) &&
            stableDispatch(shopTitle("Acqui Terme")) &&
            stableDispatch(bookShopPhoneNumber("+39 0144 356961")) &&
            stableDispatch(bookShopEmail("mondadoriacquionline@gmail.com"))
            :
            url.includes("loano") === true ? 
            stableDispatch(shopName("loano")) &&
            stableDispatch(bookShopAddress("Via Garibaldi\n150, 17025 Loano SV")) &&
            stableDispatch(shopTitle("Loano")) &&
            stableDispatch(bookShopPhoneNumber("+39 019 675848")) &&
            stableDispatch(bookShopEmail("mondadoriloanoonline@gmail.com"))
            :
            stableDispatch(shopName("local")) &&
            stableDispatch(bookShopAddress("local")) &&
            stableDispatch(shopTitle("Local")) &&
            stableDispatch(bookShopPhoneNumber("+39 0373 80379")) &&
            stableDispatch(bookShopEmail("mondadoricremaonline@gmail.com"))
        }

        checkShop();
  
       function resizeWindow () {
        console.log("RESIZE STAFF HEADER")
        const newWidth = window.innerWidth;
        const newHeight = window.innerHeight;
        stableDispatch(width(newWidth));
        stableDispatch(height(newHeight));
      };
      window.addEventListener("resize", resizeWindow);
      return () => window.removeEventListener("resize", resizeWindow);
    }, [stableDispatch]);

    let history = useHistory();

    function logout() {
        firebase.auth().signOut()
        .then(() => {
            history.push('../');
        })
        .catch((error) => {
            alert("Oopps..c'è stato un errore..", error);
        });
    }

    const styles = {
        shopTitle: {
            fontFamily: fontFamily,
            color: colour.dark,
            fontSize: 30,
            fontWeight: '500'
        },
        logout: {
            fontFamily: fontFamily,
            color: colour.med,
            fontSize: '1.125em',
            fontWeight: 'bold',
            marginBottom: 0,
            cursor: 'pointer'
        }
    }

    return (
        <div className="internalHeaderMobile">
            <div className="headerRow">
                <div />
                <p
                    onClick={() => logout()}
                    style={styles.logout}
                >
                    Esci
                </p>
            </div>
            <div className="headerRow">
                <img
                    src={require('./Mondadori-logo.svg')}
                    className="logoMondadori"
                    alt="Libreria Mondadori"
                    onClick={() => history.push('../')}
                />
                <p style={styles.shopTitle}>
                    Mondadori{' '}
                    <span style={{ color: colour.primary }}>
                        {currentShopTitle}
                    </span>
                </p>
            </div>
        </div>
    )
}

export default InternalSiteHeader;